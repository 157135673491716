import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { REPORT_PRESENCE_INTERVAL_SEC } from '@t5s/client/readonly-constant/active-user-presence';
import { catchErrorDispatch, ofErrorType, ofGqlClientErrorTypeWithCode } from '@t5s/client/util/store';
import { leaveZone } from '@t5s/client/util/zonejs';
import { ApplicationI18nActions } from '@t5s/mobile-client/business-logic/application-i18n';
import {
  LoggedInPollingEndTriggerObservable,
  LoggedInPollingStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-polling-trigger';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/platform';
import {
  ROUTE_FRAGMENT_ORGANIZATION_SIGNIN_SLUG,
  ROUTE_FRAGMENT_PUBLIC,
} from '@t5s/mobile-client/readonly-constant/public';
import { ApplicationRestartService } from '@t5s/mobile-client/service/application-restart';
import { SplashScreenService } from '@t5s/mobile-client/service/splash-screen';
import { StorageService } from '@t5s/mobile-client/service/storage';
import {
  GqlActiveUserPresenceService,
  GqlActiveUserService,
  GqlSessionService,
  GqlUserClientAppService,
  isGqlClientErrorWithCode,
} from '@t5s/shared/gql-services';
import { INSECURE_COOKIE_HEADER_NAME } from '@t5s/shared/readonly-constant/authentication';
import { filterOnlyPresent, filterOnlyUndefined } from '@t5s/shared/util/rxjs';
import { MsFromTime } from '@t5s/shared/util/time-ms';
import { asyncScheduler, combineLatest, EMPTY, of, timer } from 'rxjs';
import { catchError, filter, first, map, mapTo, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ActiveUserSessionActions } from './active-user-session.actions';
import {
  selectActiveUser,
  selectActiveUserContextEstablished,
  selectActiveUserId,
  selectActiveUserSessionCookie,
} from './active-user-session.selectors';

@Injectable()
export class ActiveUserSessionEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly sessionService: GqlSessionService,
    private readonly activeUserService: GqlActiveUserService,
    private readonly activeUserPresenceService: GqlActiveUserPresenceService,
    private readonly userClientAppService: GqlUserClientAppService,
    private readonly storageService: StorageService,
    private readonly applicationRestartService: ApplicationRestartService,
    private readonly splashScreenService: SplashScreenService,
    private readonly pollingStartTrigger$: LoggedInPollingStartTriggerObservable,
    private readonly pollingEndTrigger$: LoggedInPollingEndTriggerObservable,
    private readonly platform$: PlatformObservable,
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {}

  readonly loadCookie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionActions.loadActiveUserSessionCookie),
      switchMap(() =>
        this.storageService.get({
          key: INSECURE_COOKIE_HEADER_NAME,
        }),
      ),
      map(({ value }) => ActiveUserSessionActions.setActiveUserSessionCookie({ cookie: value ?? '' })),
    ),
  );

  // TODO: rework after web-client has converged, reuse new ws logic
  // readonly reportActiveUserPresence = ...

  readonly initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionActions.initialize),
      withLatestFrom(this.store$.select(selectActiveUserContextEstablished)),
      filter(([_, ctxEstablished]) => ctxEstablished === undefined),
      switchMap(() =>
        this.sessionService.getUserSessions().pipe(
          map((sessions) => ActiveUserSessionActions.initializeUserSessionsSuccess({ sessions })),
          catchErrorDispatch(ActiveUserSessionActions.initializeUserSessionsError),
        ),
      ),
    ),
  );

  readonly writeCookieToStorage$ = createEffect(
    () =>
      this.store$.select(selectActiveUserSessionCookie).pipe(
        filterOnlyPresent(),
        filter((cookie) => cookie !== ''),
        switchMap((cookie) =>
          this.storageService.set({
            key: INSECURE_COOKIE_HEADER_NAME,
            value: cookie,
          }),
        ),
      ),
    { dispatch: false },
  );

  readonly loadActiveUserOnActiveUserIdChange$ = createEffect(() =>
    this.store$.select(selectActiveUserId).pipe(
      filterOnlyPresent(),
      map(() => ActiveUserSessionActions.loadActiveUser()),
    ),
  );

  readonly loadActiveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionActions.loadActiveUser),
      switchMap(() =>
        this.activeUserService.getActiveUser().pipe(
          map((activeUser) => ActiveUserSessionActions.loadActiveUserSuccess({ activeUser })),
          catchError((error) => {
            if (isGqlClientErrorWithCode('AuthenticationException')(error)) {
              return of(ActiveUserSessionActions.loadActiveUserUnauthenticatedException({ error }));
            }

            return of(ActiveUserSessionActions.loadActiveUserException({ error }));
          }),
        ),
      ),
    ),
  );

  readonly authenticationExceptionNavigateToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveUserSessionActions.loadActiveUserUnauthenticatedException),
        switchMap(() => this.router.navigate([ROUTE_FRAGMENT_PUBLIC, ROUTE_FRAGMENT_ORGANIZATION_SIGNIN_SLUG])),
      ),
    { dispatch: false },
  );

  readonly authenticationExceptionForceLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofErrorType(),
      ofGqlClientErrorTypeWithCode('AuthenticationException'),
      map(() => ActiveUserSessionActions.forceLogout()),
    ),
  );

  readonly forceLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionActions.forceLogout),
      switchMap(() => this.storageService.clear()),
      switchMap(() => this.router.navigate([ROUTE_FRAGMENT_PUBLIC, ROUTE_FRAGMENT_ORGANIZATION_SIGNIN_SLUG])),
      map(() => ActiveUserSessionActions.forceLogoutSuccess()),
    ),
  );

  readonly forceLogoutSuccessReload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveUserSessionActions.forceLogoutSuccess),
        tap(() => this.applicationRestartService.restart()),
      ),
    {
      dispatch: false,
    },
  );

  readonly setActiveUserLanguage$ = createEffect(() =>
    this.store$.select(selectActiveUser).pipe(
      filterOnlyPresent(),
      map(({ language, timeFormat, dateFormat, decimalFormat, firstWeekday }) =>
        ApplicationI18nActions.setApplicationI18n({ language, timeFormat, dateFormat, decimalFormat, firstWeekday }),
      ),
    ),
  );

  readonly activeUserIdClearedClearStorage$ = createEffect(
    () =>
      this.store$.select(selectActiveUserId).pipe(
        filterOnlyPresent(),
        switchMap(() =>
          this.store$.select(selectActiveUserId).pipe(
            filterOnlyUndefined(),
            first(),
            switchMap(() => this.storageService.clear()),
          ),
        ),
      ),
    { dispatch: false },
  );

  readonly logoutOnRemoteAndClearStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionActions.logout),
      tap(() => this.splashScreenService.show()), // immediately show splash screen for better UX
      concatLatestFrom(() => this.store$.select(selectActiveUserId)),
      switchMap(([_, activeUserId]) =>
        this.sessionService.removeUserSession(activeUserId).pipe(
          switchMap(() => this.storageService.clear()),
          map(ActiveUserSessionActions.logoutSuccess),
          catchErrorDispatch(ActiveUserSessionActions.logoutError),
        ),
      ),
    ),
  );

  readonly logoutSuccessReloadApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveUserSessionActions.logoutSuccess),
        tap(() => this.applicationRestartService.restartWithUrl(`/${ROUTE_FRAGMENT_PUBLIC}`)),
      ),
    {
      dispatch: false,
    },
  );

  /** Reports token to the server once active user context is available, and registration was successful. */
  readonly registerSuccessReportTokenToServer$ = createEffect(
    () =>
      this.store$.select(selectActiveUserId).pipe(
        filterOnlyPresent(),
        switchMap(() =>
          this.platform$.pipe(
            switchMap((platform) =>
              this.userClientAppService.setUserClientAppMetaData({ platform }).pipe(catchError(() => EMPTY)),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects(): Action {
    return ActiveUserSessionActions.loadActiveUserSessionCookie();
  }
}
