import { Injectable } from '@angular/core';
import { Language } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { ClientI18nCommonKey, ClientI18nCommonString } from './i18n-types';

/** @deprecated */
@Injectable()
export abstract class LanguageService {
  abstract translateAsync(
    i18nInput: ClientI18nCommonString | ClientI18nCommonKey | undefined | null,
    language?: Language,
  ): Observable<string>;

  abstract translateSync(i18nInput: ClientI18nCommonString | ClientI18nCommonKey | undefined | null): string;
}
