import gql from 'graphql-tag';
import { BlabDefNotificationDtoFragment } from '../blab-def-notification/blab-def-notification-dto.fragment';
import { BlabItemNotificationDtoFragmentV2 } from '../blab-item-notification-v2/blab-item-notification-dto-v2.fragment';
import { UserGroupNotificationDtoFragment } from '../user-group-notification';
import { UserNotificationDtoFragment } from '../user-notification';
import { WorkflowDefNotificationDtoFragment } from '../workflow-def-notification/workflow-def-notification-dto.fragment';
import { WorkspaceNotificationDtoFragment } from '../workspace-notification/workspace-notification-dto.fragment';

export const NotificationDtoFragmentV2 = gql`
  fragment NotificationDtoFragmentV2 on NotificationDto {
    type

    blabItemNotification {
      ...BlabItemNotificationDtoFragmentV2
    }

    userNotification {
      ...UserNotificationDtoFragment
    }

    workspaceNotification {
      ...WorkspaceNotificationDtoFragment
    }

    workflowDefNotification {
      ...WorkflowDefNotificationDtoFragment
    }

    blabDefNotification {
      ...BlabDefNotificationDtoFragment
    }

    userGroupNotification {
      ...UserGroupNotificationDtoFragment
    }
  }

  ${BlabItemNotificationDtoFragmentV2}
  ${UserNotificationDtoFragment}
  ${WorkspaceNotificationDtoFragment}
  ${WorkflowDefNotificationDtoFragment}
  ${BlabDefNotificationDtoFragment}
  ${UserGroupNotificationDtoFragment}
`;
