import { ApiVersion } from '@t5s/shared/readonly-constant/api';
import { Observable } from 'rxjs';

/**
 * Shared provider token for a websocket client that can be used to make calls to the Tape internal websocket PAI. This websocket API is only used by Tape client applications.
 * This provider token is used by the shared API services and has to be provided by each client application (web, mobile, e2e, etc.) so that the shared services can be used.
 * This way, all consumers have a consistent experience using the API via the shared services instead of having to provide type-safe HTTP calls themselves.
 */
export abstract class ApiWebsocketClient {
  protected readonly baseUrl: string;
  protected readonly version: ApiVersion;
  protected activeUserId?: number;

  constructor({ baseUrl, version }: { baseUrl: string; version: ApiVersion }) {
    this.baseUrl = baseUrl;
    this.version = version;
  }

  abstract reconnect$: Observable<void>;

  abstract messages$: Observable<unknown>;

  /**
   * Dispatch a message to the Tape API.
   *
   * @param path The path to dispatch the message to.
   * @param message The message to dispatch.
   *
   * @returns An observable that emits the response from the API.
   */
  abstract dispatch<TInput extends {}>({ message }: { message: TInput }): Observable<{ message: TInput }>;

  /**
   * Set the active user ID for the current session, which is included in all dispatched messages by this client.
   * @param userId The ID of the user that should be set as the active user.
   */
  setActiveUserId(activeUserId: number): void {
    const previousActiveUserId = this.activeUserId;
    if (previousActiveUserId === activeUserId) {
      return;
    }

    // set and reconnect
    this.activeUserId = activeUserId;
    this.forceReconnection();
  }

  abstract forceReconnection(): void;
}
