import { clientLanguagesDict } from '@t5s/client/i18n/legacy';
import { Language } from '@t5s/shared/gql';
import { deLang } from '@t5s/shared/i18n/common';
import { activeUserDeLangClient } from './active-user/active-user.de';
import { activeUserEntityInfoDeLangClient } from './active-user/entity-info/active-user-entity-info.de';
import { activityDeLangClient } from './activity/activity.de';
import { billingLangDeClient } from './billing/billing.de';
import { blabDefActivityDeLangClient } from './blab-def-activity/blab-def-activity.de';
import { blabDefCreateDeLangClient } from './blab-def-create/blab-def-create.de';
import { blabDefFlimDefEditDeLangClient } from './blab-def-flim-def-edit';
import { blabDefDeLangClient } from './blab-def/blab-def.de';
import { blabItemCommentDeLangClient } from './blab-item-comment/blab-item-comment.de';
import { blabItemCreationDeLangClient } from './blab-item-creation/blab-item-creation.de';
import { blabItemIncomingRelationDeLangClient } from './blab-item-incoming-relation/blab-item-incoming-relation.de';
import { blabItemReminderDeLangClient } from './blab-item-reminder/blab-item-reminder.de';
import { blabItemTableDeLangClient } from './blab-item-table/blab-item-table.de';
import { blabItemDeLangClient } from './blab-item/blab-item.de';
import { blabViewDeLangClient } from './blab-view/blab-view.de';
import { categoryInputDeLangClient } from './category-input/category-input.de';
import { chatDeLangClient } from './chat/chat.de';
import { editProfileDeLangClient } from './edit-profile/edit-profile.de';
import { findMyOrganizationsDeLangClient } from './find-my-organizations/find-my-organizations.de';
import { flimDefSettingsDeLangClient } from './flim-def-settings/flim-def-settings.de';
import { forgotPasswordDeLangClient } from './forgot-password/forgot-password.de';
import { getStartedDeLangClient } from './get-started/get-started.de';
import { globalSearchDeLangClient } from './global-search/global-search.de';
import { homeDeLangClient } from './home/home.de';
import { invalidLinkDeLangClient } from './invalid-link/invalid-link.de';
import { WebClientLangObjType } from './languages';
import { loginDeLangClient } from './login/login.de';
import { notificationsDeLangClient } from './notifications/notifications.de';
import { organizationInvitationDeLangClient } from './organization-invitation/organization-invitation.de';
import { organizationPlanLangDeClient } from './organization-plan/organization-plan.de';
import { organizationProfileDeLangClient } from './organization-profile/organization-profile.de';
import { organizationSettingsGeneralDeLangClient } from './organization-settings-general/organization-settings-general.de';
import { organizationSettingsDeLangClient } from './organization-settings/organization-settings.de';
import { organizationSetupDeLangClient } from './organization-setup/organization-setup.de';
import { organizationSidebarDeLangClient } from './organization-sidebar/organization-sidebar.de';
import { organizationSignupDeLangClient } from './organization-signup/organization-signup.de';
import { organizationUserDeLangClient } from './organization-user/organization-user.de';
import { relationFlimDefBlabDefSearchDeLangClient } from './relation-flim-def-blab-def-search/relation-flim-def-blab-def-search.de';
import { relationInputDeLangClient } from './relation-input/relation-input.de';
import { reminderDeLangClient } from './reminder/reminder.de';
import { signupDeLangClient } from './signup/signup.de';
import { userActivityDeLangClient } from './user-activity/user-activity.de';
import { userInputDeLangClient } from './user-input/user-input.de';
import { userProfileDeLangClient } from './user-profile/user-profile.de';
import { userSettingsDeLangClient } from './user-settings/user-settings.de';
import { userSetupDeLangClient } from './user-setup/user-setup.de';
import { userToolbarDeLangClient } from './user-toolbar/user-toolbar.de';
import { userTrashDeLangClient } from './user-trash/user-trash.de';
import { workspaceActivityDeLangClient } from './workspace-activity/workspace-activity.de';
import { workspaceDeLangClient } from './workspace/workspace.de';
import { yourContentLangDeClient } from './your-content/your-content.de';

const deLangClient = clientLanguagesDict[Language.DE];

export const deLangWebClient: WebClientLangObjType = {
  ...deLang,
  ...deLangClient,
  blabItemCrud: {
    access: `Zugriff`,
    activitySideOutlet: {
      activityHeadline: `Aktivitäten`,
      backBtnTooltip: 'Zurück',
      threadHeadline: `Thread`,
    },
    closeBtnTooltip: 'Schließen',
    confirmDeleteBlabItem: {
      confirmButtonLabel: `Löschen`,
      message: `Bist du sicher, dass du diesen Eintrag löschen möchtest?`,
      title: `Eintrag löschen?`,
    },
    follower: `Follower`,
    newItem: `{{itemName}} erstellen`,
    noTitle: `Unbenannt`,
    optionsBtnTooltip: 'Optionen',
    optionsMenu: {
      copyItemURL: `URL kopieren`,
      createBlabItemTemplate: 'Vorlage erstellen',
      customizeFields: `Felder anpassen`,
      bookmark: 'Zu Favoriten hinzufügen',
      unbookmark: 'Aus Favoriten entfernen',
      permissionSettings: `Berechtigungen`,
      delete: `Löschen`,
      duplicate: 'Duplizieren',
      newRecord: `Neu`,
      print: `Drucken`,
      developerInfo: 'Entwickler-Info',
    },
    overview: {
      blabViews: 'Ansichten',
      blabViewSelection: {
        allViews: `Alle {{blabName}}`,
      },
      tableRowHeight: `Zeilenhöhe`,
      tableRowHeights: {
        EXTRA_TALL: `Sehr hoch`,
        MEDIUM: `Mittel`,
        SHORT: `Klein`,
        TALL: `Hoch`,
      },
    },
    relatedActivityTab: {
      headline: 'Verbundene Aktivitäten kommen bald!',
      subline:
        'Sobald du ein paar Einträge mit diesem verbindest, werden dir hier die zugehörigen Aktivitäten angezeigt.',
    },
    savedRemindersBtnTooltip: 'Gespeicherte Erinnerungen',
    saveReminderBtnTooltip: 'Erinnerung speichern',
    share: 'Teilen',
  },
  core: {
    ...deLangClient.core,
    cancel: `Abbrechen`,
    confirmationDefaults: {
      confirmButtonLabel: `Löschen`,
      message: `Bist du sicher, dass du diese Ansicht löschen möchtest?`,
      rejectButtonLabel: `Abbrechen`,
      title: `Ansicht löschen?`,
    },

    timeStamps: {
      hourDelta: `{{delta}} Std.`,
      minuteDelta: `{{delta}} Min.`,
      noDate: `Kein Datum`,
      now: `Jetzt`,
      past: `Überfällig`,
      secondDelta: `{{delta}} Sek.`,
      today: `Heute`,
      tomorrow: `Morgen`,
      tomorrowTime: `Morgen {{time}}`,
      yesterday: `Gestern`,
      yesterdayTime: `Gestern {{time}}`,
    },
    userStatus: {
      offline: 'Abwesend',
      offline_snoozed: 'Benachrichtigungen pausiert',
      online: 'Aktiv',
      online_snoozed: 'Benachrichtigungen pausiert',
    },
  },
  fm: {
    ...deLangClient.fm,
    activeUser: activeUserDeLangClient,
    activeUserEntityInfo: activeUserEntityInfoDeLangClient,
    activity: activityDeLangClient,
    billing: {
      ...deLangClient.fm.billing,
      ...billingLangDeClient,
    },
    blabDef: blabDefDeLangClient,
    blabDefActivity: blabDefActivityDeLangClient,
    blabDefCreate: blabDefCreateDeLangClient,
    blabDefFlimDefEdit: blabDefFlimDefEditDeLangClient,
    blabItem: blabItemDeLangClient,
    blabItemComment: blabItemCommentDeLangClient,
    blabItemCreation: blabItemCreationDeLangClient,
    blabItemIncomingRelation: blabItemIncomingRelationDeLangClient,
    blabItemReminder: blabItemReminderDeLangClient,
    blabItemSelectionSearch: {
      searchForBlabItems: 'Nach Inhalten suchen',
    },
    blabItemTable: blabItemTableDeLangClient,
    blabView: blabViewDeLangClient,
    calendar: {
      share: {
        linkCopiedToast: 'In Zwischenablage kopiert',
        resetConfirmationModal: {
          confirmButtonLabel: 'Zurücksetzen',
          message: `Bist du sicher, dass du diesen Kalenderlink zurücksetzen möchtest? Der bestehende Kalenderlink wird dadurch ungültig.`,
          title: 'Kalenderlink zurücksetzen?',
        },
      },
    },
    categoryInput: categoryInputDeLangClient,
    chat: { ...chatDeLangClient, ...deLangClient.fm.chat },
    editProfile: editProfileDeLangClient,
    findMyOrganizations: findMyOrganizationsDeLangClient,
    flimDefSettings: flimDefSettingsDeLangClient,
    forgotPassword: forgotPasswordDeLangClient,
    getStarted: getStartedDeLangClient,
    globalSearch: globalSearchDeLangClient,
    home: homeDeLangClient,
    invalidLink: invalidLinkDeLangClient,
    login: loginDeLangClient,
    mainContentTitle: {
      creationBlabDefTitle: `{{itemName}} erstellen`,
    },
    notifications: notificationsDeLangClient,
    organization: {},
    organizationActivity: {},
    organizationInvitation: organizationInvitationDeLangClient,
    organizationPlan: organizationPlanLangDeClient,
    organizationProfile: organizationProfileDeLangClient,
    organizationSetup: organizationSetupDeLangClient,
    organizationSidebar: organizationSidebarDeLangClient,
    organizationSignup: organizationSignupDeLangClient,
    organizationUser: organizationUserDeLangClient,
    orgSettings: organizationSettingsDeLangClient,
    orgSettingsGeneral: organizationSettingsGeneralDeLangClient,
    reaction: {
      addReactionBtnTooltip: 'Reaktion hinzufügen',
      reactors: `{{reactorStr}} und {{finalReactorStr}}`,
      you: 'Du',
    },
    relationFlimDefBlabDefSearch: relationFlimDefBlabDefSearchDeLangClient,
    relationInput: relationInputDeLangClient,
    reminder: reminderDeLangClient,
    session: {
      notifications: {
        error: `Ungültige Sitzung, bitte anmelden.`,
      },
    },
    signup: signupDeLangClient,
    userActivity: userActivityDeLangClient,
    userActivityThread: {
      backBtnTooltip: 'Zurück',
      headerTitle: 'Beitrag',
    },
    userInput: userInputDeLangClient,
    userProfile: userProfileDeLangClient,
    userSettings: userSettingsDeLangClient,
    userSetup: userSetupDeLangClient,
    userStatusIndicator: {
      active: 'Aktiv',
      activeDnd: 'Aktiv, Benachritigungen pausiert',
      away: 'Abwesend',
      awayDnd: 'Abwesend, Benachritigungen pausiert',
    },
    userToolbar: userToolbarDeLangClient,
    userTrash: userTrashDeLangClient,
    workspace: workspaceDeLangClient,
    workspaceActivity: workspaceActivityDeLangClient,
    yourContent: yourContentLangDeClient,
  },
  public: {
    contactLink: 'https://get.tapeapp.com/de/imprint',
    contactLinkLabel: 'Kontaktiere uns',
    termsAndConditionsLink: 'https://get.tapeapp.com/de/privacy',
    termsAndConditionsLinkLabel: 'Datenschutz & Bedingungen',
  },
  redirectUserMessage: {
    blabItemCreation: {
      buttonText: `Speichern`,
      buttonTextSaveAndNew: `Speichern und Neu`,
      buttonTextSaveAndDuplicate: `Speichern und Duplizieren`,
      buttonTextNestedCreation: `Speichern und zurück`,
      customizeFieldsBtnText: 'Felder anpassen',
      closeBtnTooltip: 'Schließen',
      description: `Wenn du es tust, wird die gesamte Aktivität hier angezeigt.`,
      headline: `{{itemName}} wurde noch nicht von dir erstellt`,
      title: `Unbenannt`,
    },
  },
} as const;
