import {
  ActivateBlabItemNotificationsInput,
  ActiveUserBlabItemInfoDto,
  BlabItemFollowersConnection,
  BulkFollowBlabItemsInput,
  BulkFollowBlabItemsResponse,
  BulkUnfollowBlabItemsInput,
  DeactivateBlabItemNotificationsInput,
  FollowBlabItemInput,
  GetActiveUserBlabItemInfoInput,
  GetBlabItemFollowersInput,
  SubscribeToBulkFollowUnfollowBlabItemsInput,
  SubscribeToBulkFollowUnfollowBlabItemsResult,
  UnfollowBlabItemInput,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { ActiveUserBlabItemInfoDtoFragment } from '../../fragments/active-user-blab-item-info';
import {
  AllBlabItemFollowersFragment,
  SubscribeToBulkUnfollowBlabItemsEventUnionTypeFragment,
} from '../../fragments/blab-item-follow';
import {
  activateBlabItemNotifications,
  deactivateBlabItemNotifications,
  followBlabItem,
  unfollowBlabItem,
  bulkFollowBlabItems,
  bulkUnfollowBlabItems,
} from '../../generated/graphql-mutations';
import { getActiveUserBlabItemInfo, getBlabItemFollowers } from '../../generated/graphql-queries';
import {
  subscribeToActiveUserBlabItemInfo,
  subscribeToBulkFollowUnfollowBlabItemsProgress,
} from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlActiveUserBlabItemService extends GqlService {
  getActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultQuery(getActiveUserBlabItemInfo(input, [ActiveUserBlabItemInfoDtoFragment]));
  }

  watchActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultWatchQuery(getActiveUserBlabItemInfo(input, [ActiveUserBlabItemInfoDtoFragment]));
  }

  /** @deprecated Do not use this sh1t, it never emits. */
  subscribeToActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultSubscribe(subscribeToActiveUserBlabItemInfo(input));
  }

  getBlabItemFollowersConnection(
    first: number,
    input: GetBlabItemFollowersInput,
    after?: string,
  ): Observable<BlabItemFollowersConnection> {
    return this.apollo.defaultQuery(getBlabItemFollowers(first, input, after, [AllBlabItemFollowersFragment]));
  }

  followBlabItem(input: FollowBlabItemInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultMutate(followBlabItem(input));
  }

  unfollowBlabItem(input: UnfollowBlabItemInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultMutate(unfollowBlabItem(input));
  }

  bulkFollowBlabItems(input: BulkFollowBlabItemsInput): Observable<BulkFollowBlabItemsResponse> {
    return this.apollo.defaultMutate(bulkFollowBlabItems(input));
  }

  bulkUnfollowBlabItems(input: BulkUnfollowBlabItemsInput): Observable<BulkFollowBlabItemsResponse> {
    return this.apollo.defaultMutate(bulkUnfollowBlabItems(input));
  }

  activateBlabItemNotifications(input: ActivateBlabItemNotificationsInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultMutate(activateBlabItemNotifications(input));
  }

  deactivateBlabItemNotifications(input: DeactivateBlabItemNotificationsInput): Observable<ActiveUserBlabItemInfoDto> {
    return this.apollo.defaultMutate(deactivateBlabItemNotifications(input));
  }

  subscribeToBlabItemBulkFollowUnfollowProgress(
    input: SubscribeToBulkFollowUnfollowBlabItemsInput,
  ): Observable<SubscribeToBulkFollowUnfollowBlabItemsResult> {
    return this.apollo.defaultSubscribe(
      subscribeToBulkFollowUnfollowBlabItemsProgress(input, [SubscribeToBulkUnfollowBlabItemsEventUnionTypeFragment]),
    );
  }
}
