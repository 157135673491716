export const userToolbarEnLangClient = {
  activeUserMenu: {
    dnd: `Do not disturb`,
    dndInfinite: `Paused until reactivation`,
    dndUntil: `Paused until {{until}}`,
    editStatus: 'Edit status',
    logout: `Log out`,
    editProfile: `Edit profile`,
    set_incognito_off: `Set yourself to active`,
    set_incognito_on: `Set yourself to away`,
    settings: `Preferences`,
    status: `Set a status`,
    trash: `Trash`,
    mobileApp: 'Get mobile app',
  },
  buttonTooltips: {
    calendar: 'Calendar',
    chat: 'Chat',
    notifications: 'Notifications',
    reminders: 'Reminders',
  },
  help: {
    feedback: 'Feature request',
    helpCenter: 'Help & documentation',
    community: 'Ask the community',
    sendMessage: 'Billing support',
    whatsNew: `What's new?`,
    tour: 'Start tour',
    roadmap: 'Roadmap',
    bugReport: 'Bug report',
  },
  helpBtnTooltip: 'Help & support',
};
