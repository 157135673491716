import { clientLanguagesDict } from '@t5s/client/i18n/legacy';
import { Language } from '@t5s/shared/gql';
import { enLang } from '@t5s/shared/i18n/common';
import { activeUserEnLangClient } from './active-user/active-user.en';
import { activeUserEntityInfoEnLangClient } from './active-user/entity-info/active-user-entity-info.en';
import { activityEnLangClient } from './activity/activity.en';
import { billingLangEnClient } from './billing/billing.en';
import { blabDefActivityEnLangClient } from './blab-def-activity/blab-def-activity.en';
import { blabDefCreateEnLangClient } from './blab-def-create/blab-def-create.en';
import { blabDefFlimDefEditEnLangClient } from './blab-def-flim-def-edit';
import { blabDefEnLangClient } from './blab-def/blab-def.en';
import { blabItemCommentEnLangClient } from './blab-item-comment/blab-item-comment.en';
import { blabItemCreationEnLangClient } from './blab-item-creation/blab-item-creation.en';
import { blabItemIncomingRelationEnLangClient } from './blab-item-incoming-relation/blab-item-incoming-relation.en';
import { blabItemReminderEnLangClient } from './blab-item-reminder/blab-item-reminder.en';
import { blabItemTableEnLangClient } from './blab-item-table/blab-item-table.en';
import { blabItemEnLangClient } from './blab-item/blab-item.en';
import { blabViewEnLangClient } from './blab-view/blab-view.en';
import { categoryInputEnLangClient } from './category-input/category-input.en';
import { chatEnLangClient } from './chat/chat.en';
import { editProfileEnLangClient } from './edit-profile/edit-profile.en';
import { findMyOrganizationsEnLangClient } from './find-my-organizations/find-my-organizations.en';
import { flimDefSettingsEnLangClient } from './flim-def-settings/flim-def-settings.en';
import { forgotPasswordEnLangClient } from './forgot-password/forgot-password.en';
import { getStartedEnLangClient } from './get-started/get-started.en';
import { globalSearchEnLangClient } from './global-search/global-search.en';
import { homeEnLangClient } from './home/home.en';
import { invalidLinkEnLangClient } from './invalid-link/invalid-link.en';
import { loginEnLangClient } from './login/login.en';
import { notificationsEnLangClient } from './notifications/notifications.en';
import { organizationInvitationEnLangClient } from './organization-invitation/organization-invitation.en';
import { organizationPlanLangEnClient } from './organization-plan/organization-plan.en';
import { organizationProfileEnLangClient } from './organization-profile/organization-profile.en';
import { organizationSettingsGeneralEnLangClient } from './organization-settings-general/organization-settings-general.en';
import { organizationSettingsEnLangClient } from './organization-settings/organization-settings.en';
import { organizationSetupEnLangClient } from './organization-setup/organization-setup.en';
import { organizationSidebarEnLangClient } from './organization-sidebar/organization-sidebar.en';
import { organizationSignupEnLangClient } from './organization-signup/organization-signup.en';
import { organizationUserEnLangClient } from './organization-user/organization-user.en';
import { relationFlimDefBlabDefSearchEnLangClient } from './relation-flim-def-blab-def-search/relation-flim-def-blab-def-search.en';
import { relationInputEnLangClient } from './relation-input/relation-input.en';
import { reminderEnLangClient } from './reminder/reminder.en';
import { signupEnLangClient } from './signup/signup.en';
import { userActivityEnLangClient } from './user-activity/user-activity.en';
import { userInputEnLangClient } from './user-input/user-input.en';
import { userProfileEnLangClient } from './user-profile/user-profile.en';
import { userSettingsEnLangClient } from './user-settings/user-settings.en';
import { userSetupEnLangClient } from './user-setup/user-setup.en';
import { userToolbarEnLangClient } from './user-toolbar/user-toolbar.en';
import { userTrashEnLangClient } from './user-trash/user-trash.en';
import { workspaceActivityEnLangClient } from './workspace-activity/workspace-activity.en';
import { workspaceEnLangClient } from './workspace/workspace.en';
import { yourContentLangEnClient } from './your-content/your-content.en';

const enLangClient = clientLanguagesDict[Language.EN];

export const enLangWebClient = {
  ...enLang,
  ...enLangClient,
  blabItemCrud: {
    access: `Access`,
    activitySideOutlet: {
      activityHeadline: `Activity`,
      backBtnTooltip: 'Back',
      threadHeadline: `Thread`,
    },
    closeBtnTooltip: 'Close',
    confirmDeleteBlabItem: {
      confirmButtonLabel: `Delete`,
      message: `Are you sure you want to delete this record?`,
      title: `Delete record?`,
    },
    follower: `Follower`,
    newItem: `New {{itemName}}`,
    noTitle: `Untitled`,
    optionsBtnTooltip: 'Optionen',
    optionsMenu: {
      copyItemURL: `Copy URL`,
      createBlabItemTemplate: 'Create template',
      customizeFields: `Customize fields`,
      bookmark: 'Add to favorites',
      unbookmark: 'Remove from favorites',
      permissionSettings: `Permission settings`,
      delete: `Delete`,
      duplicate: `Duplicate`,
      newRecord: `New`,
      print: `Print`,
      developerInfo: 'Developer info',
    },
    overview: {
      blabViews: 'Views',
      blabViewSelection: {
        allViews: `All {{blabName}}`,
      },
      tableRowHeight: `Row height`,
      tableRowHeights: {
        EXTRA_TALL: `Extra Tall`,
        MEDIUM: `Medium`,
        SHORT: `Small`,
        TALL: `Tall`,
      },
    },
    relatedActivityTab: {
      headline: 'Related actitvity is coming soon!',
      subline: `When you connect some records with this one, you'll see here the related activities here.`,
    },
    savedRemindersBtnTooltip: 'Saved reminders',
    saveReminderBtnTooltip: 'Save reminder',
    share: 'Share',
  },
  core: {
    ...enLangClient.core,
    cancel: `Cancel`,
    confirmationDefaults: {
      confirmButtonLabel: `Delete`,
      message: `Are you sure you want to delete this view?`,
      rejectButtonLabel: `Cancel`,
      title: `Delete view?`,
    },

    timeStamps: {
      hourDelta: `{{delta}}h`,
      minuteDelta: `{{delta}}m`,
      noDate: `No Date`,
      now: `Now`,
      past: `Overdue`,
      secondDelta: `{{delta}}s`,
      today: `Today`,
      tomorrow: `Tomorrow`,
      tomorrowTime: `Tomorrow {{time}}`,
      yesterday: `Yesterday`,
      yesterdayTime: `Yesterday {{time}}`,
    },
    userStatus: {
      offline: 'away',
      offline_snoozed: 'notifications paused',
      online: 'active',
      online_snoozed: 'notifications paused',
    },
  },
  fm: {
    ...enLangClient.fm,
    activeUser: activeUserEnLangClient,
    activeUserEntityInfo: activeUserEntityInfoEnLangClient,
    activity: activityEnLangClient,
    billing: {
      ...enLangClient.fm.billing,
      ...billingLangEnClient,
    },
    blabDef: blabDefEnLangClient,
    blabDefActivity: blabDefActivityEnLangClient,
    blabDefCreate: blabDefCreateEnLangClient,
    blabDefFlimDefEdit: blabDefFlimDefEditEnLangClient,
    blabItem: blabItemEnLangClient,
    blabItemComment: blabItemCommentEnLangClient,
    blabItemCreation: blabItemCreationEnLangClient,
    blabItemIncomingRelation: blabItemIncomingRelationEnLangClient,
    blabItemReminder: blabItemReminderEnLangClient,
    blabItemSelectionSearch: {
      searchForBlabItems: 'Search for content',
    },
    blabItemTable: blabItemTableEnLangClient,
    blabView: blabViewEnLangClient,
    calendar: {
      share: {
        linkCopiedToast: 'Copied to clipboard',
        resetConfirmationModal: {
          confirmButtonLabel: 'Reset',
          message: `Are you sure you want to reset this calendar link?
          This will invalidate the existing calendar link.`,
          title: 'Reset calendar link?',
        },
      },
    },
    categoryInput: categoryInputEnLangClient,
    chat: { ...chatEnLangClient, ...enLangClient.fm.chat },
    editProfile: editProfileEnLangClient,
    findMyOrganizations: findMyOrganizationsEnLangClient,
    flimDefSettings: flimDefSettingsEnLangClient,
    forgotPassword: forgotPasswordEnLangClient,
    getStarted: getStartedEnLangClient,
    globalSearch: globalSearchEnLangClient,
    home: homeEnLangClient,
    invalidLink: invalidLinkEnLangClient,
    login: loginEnLangClient,
    mainContentTitle: {
      creationBlabDefTitle: `New {{itemName}}`,
    },
    notifications: notificationsEnLangClient,
    organization: {},
    organizationActivity: {},
    organizationInvitation: organizationInvitationEnLangClient,
    organizationPlan: organizationPlanLangEnClient,
    organizationProfile: organizationProfileEnLangClient,
    organizationSetup: organizationSetupEnLangClient,
    organizationSidebar: organizationSidebarEnLangClient,
    organizationSignup: organizationSignupEnLangClient,
    organizationUser: organizationUserEnLangClient,
    orgSettings: organizationSettingsEnLangClient,
    orgSettingsGeneral: organizationSettingsGeneralEnLangClient,
    reaction: {
      addReactionBtnTooltip: 'Add reaction',
      reactors: `{{reactorStr}} and {{finalReactorStr}}`,
      you: 'You',
    },
    relationFlimDefBlabDefSearch: relationFlimDefBlabDefSearchEnLangClient,
    relationInput: relationInputEnLangClient,
    reminder: reminderEnLangClient,
    session: {
      notifications: {
        error: `Invalid or no session, please sign in.`,
      },
    },
    signup: signupEnLangClient,
    userActivity: userActivityEnLangClient,
    userActivityThread: {
      backBtnTooltip: 'Back',
      headerTitle: 'Post',
    },
    userInput: userInputEnLangClient,
    userProfile: userProfileEnLangClient,
    userSettings: userSettingsEnLangClient,
    userSetup: userSetupEnLangClient,
    userStatusIndicator: {
      active: 'Active',
      activeDnd: 'Active, notifications paused',
      away: 'Away',
      awayDnd: 'Away, notifciations paused',
    },
    userToolbar: userToolbarEnLangClient,
    userTrash: userTrashEnLangClient,
    workspace: workspaceEnLangClient,
    workspaceActivity: workspaceActivityEnLangClient,
    yourContent: yourContentLangEnClient,
  },
  public: {
    contactLink: 'https://get.tapeapp.com/en/imprint',
    contactLinkLabel: 'Contact us',
    termsAndConditionsLink: 'https://get.tapeapp.com/en/privacy',
    termsAndConditionsLinkLabel: 'Privacy & Terms',
  },
  redirectUserMessage: {
    blabItemCreation: {
      buttonText: `Save`,
      buttonTextSaveAndNew: `Save and new`,
      buttonTextSaveAndDuplicate: `Save and duplicate`,
      buttonTextNestedCreation: `Save and return`,

      customizeFieldsBtnText: 'Customize fields',
      closeBtnTooltip: 'Close',
      description: `When you do, all activity will show up here.`,
      headline: `You haven't created this {{itemName}} yet`,
      title: `Untitled`,
    },
  },
};
