import { SubscriptionClient } from 'subscriptions-transport-ws';

interface JitteredSubscriptionClientOptions {
  uri: string;
  minDelayMs?: number;
  maxDelayMs?: number;
  connectionParams?: () => any | object;
}

/** Creates a subscpription client that delays resubscription by a random delay. This attempt to reduce load spikes during peak traffic where many clients */
export function createJitteredSubscriptionClient({
  uri,
  minDelayMs = 100, // Minimum reconnect delay
  maxDelayMs = 15_000, // Maximum reconnect delay
  connectionParams,
}: JitteredSubscriptionClientOptions) {
  // Create the client with reconnect: false, we'll control reconnect manually
  const client = new SubscriptionClient(uri, {
    reconnect: false,
    lazy: true,
    inactivityTimeout: 0,
    connectionParams,
  });

  let isReconnecting = false;

  function jitterDelay() {
    const delay = Math.floor(Math.random() * (maxDelayMs - minDelayMs + 1) + minDelayMs);
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  client.onDisconnected(() => {
    if (isReconnecting) return;
    isReconnecting = true;

    // eslint-disable-next-line no-console
    console.warn('[WebSocket] Disconnected — reconnecting with jitter...');

    jitterDelay()
      .then(() => {
        try {
          client.close(false, false); // Close without force/unsub
          (client as any).connect(); // Safe workaround for private method
        } finally {
          isReconnecting = false;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('[WebSocket] Failed to reconnect:', error);
        isReconnecting = false;
      });
  });

  return client;
}
