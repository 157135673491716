/** Yields a negative integer sequence, used for local client IDs. */
export function* getLocalIdSequence(startWith = -1): Generator<number, number, number> {
  let intialId = startWith;
  while (true) {
    yield intialId--;
  }
}

/** Yields a negative integer sequence, used for local client IDs. */
export function* getPositiveLocalIdSequence(startWith = 1): Generator<number, number, number> {
  let intialId = startWith;
  while (true) {
    yield intialId++;
  }
}
