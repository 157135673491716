import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  GqlActiveUserBlabItemService,
  GqlBlabItemActivityService,
  GqlBlabItemLiveUpdateService,
} from '@t5s/shared/gql-services';
import { EMPTY, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { ClientSideLiveSubscriptionException } from '@t5s/client/value-object/live-subscription-context';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { isGqlClientErrorItemUnavailable } from '@t5s/client/util/error';
import { BlabItemDisplayActions } from './blab-item-display.actions';

@Injectable()
export class BlabItemDisplayLiveSubscriptionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlBlabItemliveUpdates: GqlBlabItemLiveUpdateService,
    private readonly gqlActivityService: GqlBlabItemActivityService,
    private readonly gqlActiveUserBlabItem: GqlActiveUserBlabItemService,
    private readonly activeUserId$: ActiveUserIdObservable,
  ) {}

  readonly establishLiveSubscriptionsBlabItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.establishLiveSubscriptions),
      map(BlabItemDisplayActions.subscribeToBlabItemLiveUpdates),
    ),
  );

  readonly establishLiveSubscriptionsBlabItemActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.establishLiveSubscriptions),
      map(BlabItemDisplayActions.subscribeToBlabItemActivity),
    ),
  );

  readonly unsubscribeFromBlabItemLiveSubscriptionsBlabItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.unsubscribeFromBlabItemLiveSubscriptions),
      map(BlabItemDisplayActions.unsubscribeFromBlabItemLiveUpdates),
    ),
  );

  readonly unsubscribeFromBlabItemLiveSubscriptionsBlabItemActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.unsubscribeFromBlabItemLiveSubscriptions),
      map(BlabItemDisplayActions.unsubscribeFromBlabItemActivity),
    ),
  );

  readonly unsubscribeFromBlabItemLiveSubscriptionsActiveUserBlabItemInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.unsubscribeFromBlabItemLiveSubscriptions),
      map(BlabItemDisplayActions.unsubscribeFromActiveUserBlabItemInfo),
    ),
  );

  readonly blabItemLiveUpdateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.subscribeToBlabItemLiveUpdates),
      debounceTime(250),
      mergeMap(({ blabItemId }) => {
        const shouldUnsubscribe$ = this.actions$.pipe(
          ofType(BlabItemDisplayActions.unsubscribeFromBlabItemLiveUpdates),
          filter((action) => action.blabItemId === blabItemId),
        );

        return this.gqlBlabItemliveUpdates.subscribeToBlabItemData(blabItemId).pipe(
          map((flimVal) => BlabItemDisplayActions.blabItemLiveUpdate({ flimVal, blabItemId })),
          catchError((error) => {
            if (isGqlClientErrorItemUnavailable(error)) {
              return EMPTY;
            }

            return of(BlabItemDisplayActions.subscribeToBlabItemLiveUpdatesException({ blabItemId, error }));
          }),
          startWith(BlabItemDisplayActions.subscribeToBlabItemLiveUpdatesSuccess({ blabItemId })),
          takeUntil(shouldUnsubscribe$),
        );
      }),
    ),
  );

  readonly blabItemActivitySubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.subscribeToBlabItemActivity),
      debounceTime(750),
      mergeMap(({ blabItemId }) => {
        const shouldUnsubscribe$ = this.actions$.pipe(
          ofType(BlabItemDisplayActions.unsubscribeFromBlabItemActivity),
          filter((action) => action.blabItemId === blabItemId),
        );

        return this.gqlActivityService.subscribeToBlabItemActivity(blabItemId).pipe(
          map((update) => BlabItemDisplayActions.blabItemActivityLiveUpdate({ update, blabItemId })),
          catchError((error) => {
            if (isGqlClientErrorItemUnavailable(error)) {
              return EMPTY;
            }

            return of(BlabItemDisplayActions.subscribeToBlabItemActivityException({ blabItemId, error }));
          }),
          startWith(BlabItemDisplayActions.subscribeToBlabItemActivitySuccess({ blabItemId })),
          takeUntil(shouldUnsubscribe$),
        );
      }),
    ),
  );

  readonly blabItemActivitySubscriptionErroredReloadActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.subscribeToBlabItemActivityException),
      map(BlabItemDisplayActions.loadBlabItemActivities),
    ),
  );

  readonly blabItemActivityLiveUpdateReloadTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.blabItemActivityLiveUpdate),
      map(BlabItemDisplayActions.reloadBlabItemTitle),
    ),
  );

  private readonly REESTABLISHMENT_DEBOUNCE = 3000;

  readonly subscribeToBlabItemActivityExceptionResubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.subscribeToBlabItemActivityException),
      filter(({ error }) => !(error instanceof ClientSideLiveSubscriptionException)),
      debounceTime(this.REESTABLISHMENT_DEBOUNCE),
      concatLatestFrom(() => this.activeUserId$),
      filter(([, activeUserId]) => activeUserId !== undefined),
      map(([{ blabItemId }]) => BlabItemDisplayActions.subscribeToBlabItemActivity({ blabItemId })),
    ),
  );

  readonly subscribeToBlabItemLiveUpdatesExceptionResubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.subscribeToBlabItemLiveUpdatesException),
      filter(({ error }) => !(error instanceof ClientSideLiveSubscriptionException)),
      debounceTime(this.REESTABLISHMENT_DEBOUNCE),
      concatLatestFrom(() => this.activeUserId$),
      filter(([, activeUserId]) => activeUserId !== undefined),
      map(([{ blabItemId }]) => BlabItemDisplayActions.subscribeToBlabItemLiveUpdates({ blabItemId })),
    ),
  );
}
