import gql from 'graphql-tag';

// THIS FILE HAS BEEN AUTO-GENERATED BY "graphql-cli-generate-fragments"
// DO NOT EDIT THIS FILE DIRECTLY


export const _BlabItemPersistedTitleDtoNoNestingFragment = gql`
fragment _BlabItemPersistedTitleDtoNoNesting on _BlabItemPersistedTitleDto {
  blabItemId
  blabItemPersistedTitle
}
`

export const _DatabaseVersionDtoNoNestingFragment = gql`
fragment _DatabaseVersionDtoNoNesting on _DatabaseVersionDto {
  databaseVersionStr
}
`

export const _TestJobQueueExistsResultNoNestingFragment = gql`
fragment _TestJobQueueExistsResultNoNesting on _TestJobQueueExistsResult {
  exists
}
`

export const _GetOrganizationJobStatusResultDtoNoNestingFragment = gql`
fragment _GetOrganizationJobStatusResultDtoNoNesting on _GetOrganizationJobStatusResultDto {
  jobId
  status
}
`

export const _testTimeMockDtoNoNestingFragment = gql`
fragment _testTimeMockDtoNoNesting on _testTimeMockDto {
  currentTime
  isKeepingTime
}
`

export const _DateArrivedJobDtoNoNestingFragment = gql`
fragment _DateArrivedJobDtoNoNesting on _DateArrivedJobDto {
  id
  executedAt
  blabItemId
  scheduleAt
  workflowDefId
  skippedAt
}
`

export const _DatePeriodicJobDtoNoNestingFragment = gql`
fragment _DatePeriodicJobDtoNoNesting on _DatePeriodicJobDto {
  id
  executedAt
  scheduleAt
  workflowDefId
  skippedAt
}
`

export const _GetWorkflowOfOrganizationAreThrottledNoNestingFragment = gql`
fragment _GetWorkflowOfOrganizationAreThrottledNoNesting on _GetWorkflowOfOrganizationAreThrottled {
  isThrottled
}
`

export const _OrganizationWorkflowUsageReportDtoNoNestingFragment = gql`
fragment _OrganizationWorkflowUsageReportDtoNoNesting on _OrganizationWorkflowUsageReportDto {
  organizationId
  numConsumedActions
  numSentEmails
  createdAt
}
`

export const ActiveUserApiKeyDtoNoNestingFragment = gql`
fragment ActiveUserApiKeyDtoNoNesting on ActiveUserApiKeyDto {
  userApiKey
}
`

export const ActiveUserDtoNoNestingFragment = gql`
fragment ActiveUserDtoNoNesting on ActiveUserDto {
  id
  primaryName
  secondaryName
  email
  language
  dateFormat
  timeFormat
  firstWeekday
  decimalFormat
  measurementSystem
  organizationId
  featureAccessLevel
  partnerStatus
  createdAt
  metadata
  hasCompletedSetup
  hasCompletedWorkspaceSetup
  autoApplyTimezone
  canPublishWorkspaceTemplates
  activityCount
  lastActivityAt
}
`

export const ActiveUserOrganizationDtoNoNestingFragment = gql`
fragment ActiveUserOrganizationDtoNoNesting on ActiveUserOrganizationDto {
  id
  slug
  name
  activityLimit
  activityCount
  plan
  numUsers
  hasWorkflowAutomationAccess
  disableTracking
  billingCustomerId
}
`

export const WorkspacePreviewDtoNoNestingFragment = gql`
fragment WorkspacePreviewDtoNoNesting on WorkspacePreviewDto {
  id
  slug
  position
  icon
  name
  path
  organizationId
}
`

export const OrganizationProfileDtoNoNestingFragment = gql`
fragment OrganizationProfileDtoNoNesting on OrganizationProfileDto {
  id
  slug
  name
  location
  website
  email
  phoneNumber
}
`

export const ThumbnailDtoNoNestingFragment = gql`
fragment ThumbnailDtoNoNesting on ThumbnailDto {
  id
  small
  smallWidth
  smallHeight
  medium
  mediumWidth
  mediumHeight
  large
  largeWidth
  largeHeight
}
`

export const ActiveUserRolesDtoNoNestingFragment = gql`
fragment ActiveUserRolesDtoNoNesting on ActiveUserRolesDto {
  organization
}
`

export const ActiveUserWorkspaceRoleDtoNoNestingFragment = gql`
fragment ActiveUserWorkspaceRoleDtoNoNesting on ActiveUserWorkspaceRoleDto {
  workspaceId
  role
}
`

export const ActiveUserStatusDtoNoNestingFragment = gql`
fragment ActiveUserStatusDtoNoNesting on ActiveUserStatusDto {
  userId
  presence
  note
  emoji
  clearStatusAt
  doNotDisturb
  clearDoNotDisturbAt
  incognito
}
`

export const ActiveUserBlabDefFollowInfoDtoNoNestingFragment = gql`
fragment ActiveUserBlabDefFollowInfoDtoNoNesting on ActiveUserBlabDefFollowInfoDto {
  blabDefId
  isFollowing
}
`

export const ActiveUserBlabDefInfoDtoNoNestingFragment = gql`
fragment ActiveUserBlabDefInfoDtoNoNesting on ActiveUserBlabDefInfoDto {
  blabDefId
  viewSidebarCollapsed
  privateViewsInViewSidebarCollapsed
  publicViewsInViewSidebarCollapsed
}
`

export const BlabViewDtoNoNestingFragment = gql`
fragment BlabViewDtoNoNesting on BlabViewDto {
  id
  blabDefId
  name
  layout
  position
  isDefault
  isPublic
  showProgress
  total
  numCompleted
  numIncomplete
  tableRowHeight
  tableWrapCells
  boardGroupByFlimDefId
  boardCardSize
  boardCardPreviewFitImage
  boardCardPreviewFlimDefId
  boardLargeText
  boardHideEmptyColumns
  boardColorColumns
}
`

export const BlabViewSortDtoNoNestingFragment = gql`
fragment BlabViewSortDtoNoNesting on BlabViewSortDto {
  blabViewId
  flimDefId
  direction
  sortingProperty
}
`

export const SingleTextFlimStatDefDtoNoNestingFragment = gql`
fragment SingleTextFlimStatDefDtoNoNesting on SingleTextFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleTextFlimStatType
}
`

export const SingleUserFlimStatDefDtoNoNestingFragment = gql`
fragment SingleUserFlimStatDefDtoNoNesting on SingleUserFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleUserFlimStatType
}
`

export const SingleCategoryFlimStatDefDtoNoNestingFragment = gql`
fragment SingleCategoryFlimStatDefDtoNoNesting on SingleCategoryFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleCategoryFlimStatType
}
`

export const SingleAttachmentFlimStatDefDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimStatDefDtoNoNesting on SingleAttachmentFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleAttachmentFlimStatType
}
`

export const SingleRelationFlimStatDefDtoNoNestingFragment = gql`
fragment SingleRelationFlimStatDefDtoNoNesting on SingleRelationFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleRelationFlimStatType
}
`

export const SingleDateFlimStatDefDtoNoNestingFragment = gql`
fragment SingleDateFlimStatDefDtoNoNesting on SingleDateFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleDateFlimStatType
}
`

export const RangeDateFlimStatDefDtoNoNestingFragment = gql`
fragment RangeDateFlimStatDefDtoNoNesting on RangeDateFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  rangeDateFlimStatType
}
`

export const MultiRelationFlimStatDefDtoNoNestingFragment = gql`
fragment MultiRelationFlimStatDefDtoNoNesting on MultiRelationFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiRelationFlimStatType
}
`

export const NumberFlimStatDefDtoNoNestingFragment = gql`
fragment NumberFlimStatDefDtoNoNesting on NumberFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  numberFlimStatType
}
`

export const StatusFlimStatDefDtoNoNestingFragment = gql`
fragment StatusFlimStatDefDtoNoNesting on StatusFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  statusFlimStatType
}
`

export const ChecklistFlimStatDefDtoNoNestingFragment = gql`
fragment ChecklistFlimStatDefDtoNoNesting on ChecklistFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  checklistFlimStatType
}
`

export const MultiLinkFlimStatDefDtoNoNestingFragment = gql`
fragment MultiLinkFlimStatDefDtoNoNesting on MultiLinkFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiLinkFlimStatType
}
`

export const CreatedAtFlimStatDefDtoNoNestingFragment = gql`
fragment CreatedAtFlimStatDefDtoNoNesting on CreatedAtFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  createdAtFlimStatType
}
`

export const CreatedByFlimStatDefDtoNoNestingFragment = gql`
fragment CreatedByFlimStatDefDtoNoNesting on CreatedByFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  createdByFlimStatType
}
`

export const LastModifiedAtFlimStatDefDtoNoNestingFragment = gql`
fragment LastModifiedAtFlimStatDefDtoNoNesting on LastModifiedAtFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  lastModifiedAtFlimStatType
}
`

export const LastModifiedByFlimStatDefDtoNoNestingFragment = gql`
fragment LastModifiedByFlimStatDefDtoNoNesting on LastModifiedByFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  lastModifiedByFlimStatType
}
`

export const MultiAttachmentFlimStatDefDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimStatDefDtoNoNesting on MultiAttachmentFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiAttachmentFlimStatType
}
`

export const MultiImageFlimStatDefDtoNoNestingFragment = gql`
fragment MultiImageFlimStatDefDtoNoNesting on MultiImageFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiImageFlimStatType
}
`

export const MultiTextFlimStatDefDtoNoNestingFragment = gql`
fragment MultiTextFlimStatDefDtoNoNesting on MultiTextFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiTextFlimStatType
}
`

export const MultiCategoryFlimStatDefDtoNoNestingFragment = gql`
fragment MultiCategoryFlimStatDefDtoNoNesting on MultiCategoryFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiCategoryFlimStatType
}
`

export const MultiUserFlimStatDefDtoNoNestingFragment = gql`
fragment MultiUserFlimStatDefDtoNoNesting on MultiUserFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiUserFlimStatType
}
`

export const CalculationFlimStatDefDtoNoNestingFragment = gql`
fragment CalculationFlimStatDefDtoNoNesting on CalculationFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  calculationFlimStatType
}
`

export const MultiEmailFlimStatDefDtoNoNestingFragment = gql`
fragment MultiEmailFlimStatDefDtoNoNesting on MultiEmailFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiEmailFlimStatType
}
`

export const MultiPhoneFlimStatDefDtoNoNestingFragment = gql`
fragment MultiPhoneFlimStatDefDtoNoNesting on MultiPhoneFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  multiPhoneFlimStatType
}
`

export const SingleLocationFlimStatDefDtoNoNestingFragment = gql`
fragment SingleLocationFlimStatDefDtoNoNesting on SingleLocationFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  singleLocationFlimStatType
}
`

export const UniqueIdFlimStatDefDtoNoNestingFragment = gql`
fragment UniqueIdFlimStatDefDtoNoNesting on UniqueIdFlimStatDefDto {
  id
  type
  blabViewId
  flimDefId
  uniqueIdFlimStatType
}
`

export const SingleCategorySplitByDtoNoNestingFragment = gql`
fragment SingleCategorySplitByDtoNoNesting on SingleCategorySplitByDto {
  blabViewId
  type
  singleCategoryFlimDefId
  limit
  sorting
}
`

export const SingleCategorySplitBySectionDtoNoNestingFragment = gql`
fragment SingleCategorySplitBySectionDtoNoNesting on SingleCategorySplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  singleCategoryOptionDefId
}
`

export const SingleCategoryOptionDefDtoNoNestingFragment = gql`
fragment SingleCategoryOptionDefDtoNoNesting on SingleCategoryOptionDefDto {
  id
  label
  color
  position
}
`

export const MultiCategorySplitByDtoNoNestingFragment = gql`
fragment MultiCategorySplitByDtoNoNesting on MultiCategorySplitByDto {
  blabViewId
  type
  multiCategoryFlimDefId
  limit
  sorting
}
`

export const MultiCategorySplitBySectionDtoNoNestingFragment = gql`
fragment MultiCategorySplitBySectionDtoNoNesting on MultiCategorySplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  multiCategoryOptionDefId
}
`

export const MultiCategoryOptionDefDtoNoNestingFragment = gql`
fragment MultiCategoryOptionDefDtoNoNesting on MultiCategoryOptionDefDto {
  id
  label
  color
  position
}
`

export const SingleUserSplitByDtoNoNestingFragment = gql`
fragment SingleUserSplitByDtoNoNesting on SingleUserSplitByDto {
  blabViewId
  type
  singleUserFlimDefId
  limit
  sorting
}
`

export const SingleUserSplitBySectionDtoNoNestingFragment = gql`
fragment SingleUserSplitBySectionDtoNoNesting on SingleUserSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  userId
}
`

export const UserProfilePreviewDtoNoNestingFragment = gql`
fragment UserProfilePreviewDtoNoNesting on UserProfilePreviewDto {
  id
  email
  primaryName
  secondaryName
  jobDescription
  organizationId
}
`

export const MultiUserSplitByDtoNoNestingFragment = gql`
fragment MultiUserSplitByDtoNoNesting on MultiUserSplitByDto {
  blabViewId
  type
  multiUserFlimDefId
  limit
  sorting
}
`

export const MultiUserSplitBySectionDtoNoNestingFragment = gql`
fragment MultiUserSplitBySectionDtoNoNesting on MultiUserSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  userId
}
`

export const SingleRelationSplitByDtoNoNestingFragment = gql`
fragment SingleRelationSplitByDtoNoNesting on SingleRelationSplitByDto {
  blabViewId
  type
  singleRelationFlimDefId
  limit
  sorting
}
`

export const SingleRelationSplitBySectionDtoNoNestingFragment = gql`
fragment SingleRelationSplitBySectionDtoNoNesting on SingleRelationSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  blabItemId
}
`

export const BlabItemPreviewDtoNoNestingFragment = gql`
fragment BlabItemPreviewDtoNoNesting on BlabItemPreviewDto {
  id
  title
  blabDefId
  activeUserCanView
}
`

export const BlabDefPreviewDtoNoNestingFragment = gql`
fragment BlabDefPreviewDtoNoNesting on BlabDefPreviewDto {
  id
  slug
  position
  positionV2
  icon
  iconv2
  itemIcon
  type
  blabName
  itemName
  workspaceId
}
`

export const MultiRelationSplitByDtoNoNestingFragment = gql`
fragment MultiRelationSplitByDtoNoNesting on MultiRelationSplitByDto {
  blabViewId
  type
  multiRelationFlimDefId
  limit
  sorting
}
`

export const MultiRelationSplitBySectionDtoNoNestingFragment = gql`
fragment MultiRelationSplitBySectionDtoNoNesting on MultiRelationSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  blabItemId
}
`

export const StatusSplitByDtoNoNestingFragment = gql`
fragment StatusSplitByDtoNoNesting on StatusSplitByDto {
  blabViewId
  type
  statusFlimDefId
  limit
  sorting
}
`

export const StatusSplitBySectionDtoNoNestingFragment = gql`
fragment StatusSplitBySectionDtoNoNesting on StatusSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  statusOptionDefId
}
`

export const StatusOptionDefDtoNoNestingFragment = gql`
fragment StatusOptionDefDtoNoNesting on StatusOptionDefDto {
  id
  label
  color
  meansCompleted
  position
}
`

export const SingleDateSplitByDtoNoNestingFragment = gql`
fragment SingleDateSplitByDtoNoNesting on SingleDateSplitByDto {
  blabViewId
  type
  singleDateFlimDefId
  period
  limit
  sorting
}
`

export const SingleDateSplitBySectionDtoNoNestingFragment = gql`
fragment SingleDateSplitBySectionDtoNoNesting on SingleDateSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  startDate
  endDate
  weekday
}
`

export const RangeDateSplitByDtoNoNestingFragment = gql`
fragment RangeDateSplitByDtoNoNesting on RangeDateSplitByDto {
  blabViewId
  type
  rangeDateFlimDefId
  period
  limit
  sorting
}
`

export const RangeDateSplitBySectionDtoNoNestingFragment = gql`
fragment RangeDateSplitBySectionDtoNoNesting on RangeDateSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  startDate
  endDate
  weekday
}
`

export const CreatedAtSplitByDtoNoNestingFragment = gql`
fragment CreatedAtSplitByDtoNoNesting on CreatedAtSplitByDto {
  blabViewId
  type
  createdAtFlimDefId
  limit
  sorting
  period
}
`

export const CreatedAtSplitBySectionDtoNoNestingFragment = gql`
fragment CreatedAtSplitBySectionDtoNoNesting on CreatedAtSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  startDate
  endDate
  weekday
}
`

export const LastModifiedAtSplitByDtoNoNestingFragment = gql`
fragment LastModifiedAtSplitByDtoNoNesting on LastModifiedAtSplitByDto {
  blabViewId
  type
  lastModifiedAtFlimDefId
  limit
  sorting
  period
}
`

export const LastModifiedAtSplitBySectionDtoNoNestingFragment = gql`
fragment LastModifiedAtSplitBySectionDtoNoNesting on LastModifiedAtSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  startDate
  endDate
  weekday
}
`

export const CalculationSplitByDtoNoNestingFragment = gql`
fragment CalculationSplitByDtoNoNesting on CalculationSplitByDto {
  blabViewId
  type
  calculationFlimDefId
  limit
  sorting
  period
}
`

export const CalculationSplitBySectionDtoNoNestingFragment = gql`
fragment CalculationSplitBySectionDtoNoNesting on CalculationSplitBySectionDto {
  id
  type
  flimDefId
  total
  numCompleted
  numIncomplete
  startDate
  endDate
  weekday
}
`

export const BlabViewColumnDtoNoNestingFragment = gql`
fragment BlabViewColumnDtoNoNesting on BlabViewColumnDto {
  blabViewId
  flimDefId
  position
  hidden
  width
}
`

export const BlabViewListPropertyDtoNoNestingFragment = gql`
fragment BlabViewListPropertyDtoNoNesting on BlabViewListPropertyDto {
  blabViewId
  flimDefId
  position
  shown
}
`

export const SingleTextFlimDefDtoNoNestingFragment = gql`
fragment SingleTextFlimDefDtoNoNesting on SingleTextFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  defaultValue
}
`

export const MultiTextFlimDefDtoNoNestingFragment = gql`
fragment MultiTextFlimDefDtoNoNesting on MultiTextFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  disableRichTextFormatting
  defaultValue
}
`

export const SingleCategoryFlimDefDtoNoNestingFragment = gql`
fragment SingleCategoryFlimDefDtoNoNesting on SingleCategoryFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  layout
  showColorInCalendar
  defaultCategoryOptionDefId
  showInRecord
}
`

export const MultiCategoryFlimDefDtoNoNestingFragment = gql`
fragment MultiCategoryFlimDefDtoNoNesting on MultiCategoryFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  layout
  showInRecord
}
`

export const SingleRelationFlimDefDtoNoNestingFragment = gql`
fragment SingleRelationFlimDefDtoNoNesting on SingleRelationFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
}
`

export const BlabReferenceDtoNoNestingFragment = gql`
fragment BlabReferenceDtoNoNesting on BlabReferenceDto {
  blabDefId
  blabViewId
  position
}
`

export const BlabReferenceBlabDefPreviewDtoNoNestingFragment = gql`
fragment BlabReferenceBlabDefPreviewDtoNoNesting on BlabReferenceBlabDefPreviewDto {
  id
  position
  blabName
  itemName
  slug
  icon
  iconv2
  itemIcon
  workspaceId
}
`

export const BlabViewPreviewDtoNoNestingFragment = gql`
fragment BlabViewPreviewDtoNoNesting on BlabViewPreviewDto {
  id
  blabDefId
  name
  layout
  position
  showProgress
  total
  numCompleted
  numIncomplete
  isPublic
  isDefault
}
`

export const MultiRelationFlimDefDtoNoNestingFragment = gql`
fragment MultiRelationFlimDefDtoNoNesting on MultiRelationFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
}
`

export const SingleAttachmentFlimDefDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimDefDtoNoNesting on SingleAttachmentFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
}
`

export const MultiAttachmentFlimDefDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimDefDtoNoNesting on MultiAttachmentFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
}
`

export const SingleUserFlimDefDtoNoNestingFragment = gql`
fragment SingleUserFlimDefDtoNoNesting on SingleUserFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showInFocus
  showHelpText
  helpText
  showInRecord
  doNotNotify
}
`

export const MultiUserFlimDefDtoNoNestingFragment = gql`
fragment MultiUserFlimDefDtoNoNesting on MultiUserFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  doNotNotify
  showInFocus
}
`

export const SingleDateFlimDefDtoNoNestingFragment = gql`
fragment SingleDateFlimDefDtoNoNesting on SingleDateFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  defaultDate
  defaultHasTime
  timeSetting
  showInCalendar
  isDueDateOfStatusField
}
`

export const RangeDateFlimDefDtoNoNestingFragment = gql`
fragment RangeDateFlimDefDtoNoNesting on RangeDateFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  defaultStartDate
  defaultHasTime
  defaultEndDate
  requireEndDate
  timeSetting
  showInCalendar
  isDueDateOfStatusField
}
`

export const ChecklistFlimDefDtoNoNestingFragment = gql`
fragment ChecklistFlimDefDtoNoNesting on ChecklistFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showCompletedDefault
  showInRecord
  showInFocus
}
`

export const MultiLinkFlimDefDtoNoNestingFragment = gql`
fragment MultiLinkFlimDefDtoNoNesting on MultiLinkFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
}
`

export const CreatedAtFlimDefDtoNoNestingFragment = gql`
fragment CreatedAtFlimDefDtoNoNesting on CreatedAtFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  showInRecord
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showTime
}
`

export const CreatedByFlimDefDtoNoNestingFragment = gql`
fragment CreatedByFlimDefDtoNoNesting on CreatedByFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  showInRecord
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
}
`

export const LastModifiedAtFlimDefDtoNoNestingFragment = gql`
fragment LastModifiedAtFlimDefDtoNoNesting on LastModifiedAtFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  showInRecord
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showTime
}
`

export const LastModifiedByFlimDefDtoNoNestingFragment = gql`
fragment LastModifiedByFlimDefDtoNoNesting on LastModifiedByFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  showInRecord
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
}
`

export const NumberFlimDefDtoNoNestingFragment = gql`
fragment NumberFlimDefDtoNoNesting on NumberFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  precision
  unitLocation
  unitLabel
  hideThousandSeparator
}
`

export const StatusFlimDefDtoNoNestingFragment = gql`
fragment StatusFlimDefDtoNoNesting on StatusFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  statusLayout
  showColorInCalendar
  defaultStatusOptionDefId
  showInRecord
}
`

export const CalculationFlimDefDtoNoNestingFragment = gql`
fragment CalculationFlimDefDtoNoNesting on CalculationFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  showInRecord
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  largeEditor
  script
  scriptResultType
  isInvalid
  precision
  unitLocation
  unitLabel
  hideThousandSeparator
  timeSetting
  showInCalendar
}
`

export const MultiEmailFlimDefDtoNoNestingFragment = gql`
fragment MultiEmailFlimDefDtoNoNesting on MultiEmailFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  allowOnlySingleEntry
}
`

export const MultiPhoneFlimDefDtoNoNestingFragment = gql`
fragment MultiPhoneFlimDefDtoNoNesting on MultiPhoneFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  callLinkProtocol
  allowOnlySingleEntry
}
`

export const MultiImageFlimDefDtoNoNestingFragment = gql`
fragment MultiImageFlimDefDtoNoNesting on MultiImageFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  imageFlimLayout
  showInRecord
}
`

export const SingleLocationFlimDefDtoNoNestingFragment = gql`
fragment SingleLocationFlimDefDtoNoNesting on SingleLocationFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  showMap
}
`

export const UniqueIdFlimDefDtoNoNestingFragment = gql`
fragment UniqueIdFlimDefDtoNoNesting on UniqueIdFlimDefDto {
  id
  blabDefId
  name
  slug
  type
  position
  required
  hiddenIfEmpty
  alwaysHidden
  showHelpText
  helpText
  showInRecord
  minDigits
  uniqueIdPrefix
}
`

export const BlabViewBoardPropertyDtoNoNestingFragment = gql`
fragment BlabViewBoardPropertyDtoNoNesting on BlabViewBoardPropertyDto {
  blabViewId
  flimDefId
  position
  shown
}
`

export const BlabViewBoardStatusColumnDtoNoNestingFragment = gql`
fragment BlabViewBoardStatusColumnDtoNoNesting on BlabViewBoardStatusColumnDto {
  blabViewId
  flimDefId
  flimType
  statusOptionDefId
  position
  hidden
}
`

export const BlabViewBoardSingleCategoryColumnDtoNoNestingFragment = gql`
fragment BlabViewBoardSingleCategoryColumnDtoNoNesting on BlabViewBoardSingleCategoryColumnDto {
  blabViewId
  flimDefId
  flimType
  singleCategoryOptionDefId
  position
  hidden
}
`

export const ActiveUserAutoSaveBlabViewDtoNoNestingFragment = gql`
fragment ActiveUserAutoSaveBlabViewDtoNoNesting on ActiveUserAutoSaveBlabViewDto {
  blabDefId
  originBlabViewId
  layout
  tableRowHeight
  tableWrapCells
  boardGroupByFlimDefId
  boardCardSize
  boardCardPreviewFitImage
  boardCardPreviewFlimDefId
  boardLargeText
  boardHideEmptyColumns
  boardColorColumns
}
`

export const ActiveUserSingleCategorySplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserSingleCategorySplitBySectionDtoNoNesting on ActiveUserSingleCategorySplitBySectionDto {
  id
  type
  flimDefId
  singleCategoryOptionDefId
}
`

export const ActiveUserMultiCategorySplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserMultiCategorySplitBySectionDtoNoNesting on ActiveUserMultiCategorySplitBySectionDto {
  id
  type
  flimDefId
  multiCategoryOptionDefId
}
`

export const ActiveUserSingleUserSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserSingleUserSplitBySectionDtoNoNesting on ActiveUserSingleUserSplitBySectionDto {
  id
  type
  flimDefId
  userId
}
`

export const ActiveUserMultiUserSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserMultiUserSplitBySectionDtoNoNesting on ActiveUserMultiUserSplitBySectionDto {
  id
  type
  flimDefId
  userId
}
`

export const ActiveUserSingleRelationSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserSingleRelationSplitBySectionDtoNoNesting on ActiveUserSingleRelationSplitBySectionDto {
  id
  type
  flimDefId
  blabItemId
}
`

export const ActiveUserMultiRelationSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserMultiRelationSplitBySectionDtoNoNesting on ActiveUserMultiRelationSplitBySectionDto {
  id
  type
  flimDefId
  blabItemId
}
`

export const ActiveUserStatusSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserStatusSplitBySectionDtoNoNesting on ActiveUserStatusSplitBySectionDto {
  id
  type
  flimDefId
  statusOptionDefId
}
`

export const ActiveUserSingleDateSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserSingleDateSplitBySectionDtoNoNesting on ActiveUserSingleDateSplitBySectionDto {
  id
  type
  flimDefId
  startDate
  endDate
  weekday
}
`

export const ActiveUserRangeDateSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserRangeDateSplitBySectionDtoNoNesting on ActiveUserRangeDateSplitBySectionDto {
  id
  type
  flimDefId
  startDate
  endDate
  weekday
}
`

export const ActiveUserCreatedAtSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserCreatedAtSplitBySectionDtoNoNesting on ActiveUserCreatedAtSplitBySectionDto {
  id
  type
  flimDefId
  startDate
  endDate
  weekday
}
`

export const ActiveUserLastModifiedAtSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserLastModifiedAtSplitBySectionDtoNoNesting on ActiveUserLastModifiedAtSplitBySectionDto {
  id
  type
  flimDefId
  startDate
  endDate
  weekday
}
`

export const ActiveUserCalculationSplitBySectionDtoNoNestingFragment = gql`
fragment ActiveUserCalculationSplitBySectionDtoNoNesting on ActiveUserCalculationSplitBySectionDto {
  id
  type
  flimDefId
  startDate
  endDate
  weekday
}
`

export const ActiveUserSplitByCompletedDtoNoNestingFragment = gql`
fragment ActiveUserSplitByCompletedDtoNoNesting on ActiveUserSplitByCompletedDto {
  completed
  statusFlimDefId
}
`

export const ActiveUserBlabItemInfoDtoNoNestingFragment = gql`
fragment ActiveUserBlabItemInfoDtoNoNesting on ActiveUserBlabItemInfoDto {
  blabItemId
  userId
  isFollowing
  isBeingNotified
  hasReminder
  numOverdueReminder
  focusSectionId
}
`

export const CalendarEventsDtoNoNestingFragment = gql`
fragment CalendarEventsDtoNoNesting on CalendarEventsDto {
  from
  to
}
`

export const BlabItemCalendarEventDtoNoNestingFragment = gql`
fragment BlabItemCalendarEventDtoNoNesting on BlabItemCalendarEventDto {
  id
  type
  startDate
  endDate
  hasTime
  title
  calendarSubscriptionId
  blabItemId
  blabDefId
}
`

export const ActiveUserCalendarSubscriptionDtoNoNestingFragment = gql`
fragment ActiveUserCalendarSubscriptionDtoNoNesting on ActiveUserCalendarSubscriptionDto {
  id
  userId
  type
  active
  color
}
`

export const ReminderCalendarEventDtoNoNestingFragment = gql`
fragment ReminderCalendarEventDtoNoNesting on ReminderCalendarEventDto {
  id
  type
  startDate
  endDate
  hasTime
  title
  calendarSubscriptionId
  completed
  reminderId
}
`

export const ChecklistEntryCalendarEventDtoNoNestingFragment = gql`
fragment ChecklistEntryCalendarEventDtoNoNesting on ChecklistEntryCalendarEventDto {
  id
  type
  startDate
  endDate
  hasTime
  title
  calendarSubscriptionId
  completed
  checklistFlimValEntryId
  blabItemId
}
`

export const ActiveUserCalendarSettingsDtoNoNestingFragment = gql`
fragment ActiveUserCalendarSettingsDtoNoNesting on ActiveUserCalendarSettingsDto {
  showCompletedReminders
  showCompletedChecklistEntries
  showCompletedBlabItems
  userId
}
`

export const ActiveUserCalendarStaticSharesDtoNoNestingFragment = gql`
fragment ActiveUserCalendarStaticSharesDtoNoNesting on ActiveUserCalendarStaticSharesDto {
  userId
}
`

export const ActiveUserCalendarPrivateUrlShareDtoNoNestingFragment = gql`
fragment ActiveUserCalendarPrivateUrlShareDtoNoNesting on ActiveUserCalendarPrivateUrlShareDto {
  url
  permissionType
}
`

export const ActiveUserCalendarPublicUrlShareDtoNoNestingFragment = gql`
fragment ActiveUserCalendarPublicUrlShareDtoNoNesting on ActiveUserCalendarPublicUrlShareDto {
  url
  permissionType
}
`

export const ActiveUserDevPortalContextDtoNoNestingFragment = gql`
fragment ActiveUserDevPortalContextDtoNoNesting on ActiveUserDevPortalContextDto {
  userId
  apiKey
}
`

export const ActiveUserDevPortalRejectionDtoNoNestingFragment = gql`
fragment ActiveUserDevPortalRejectionDtoNoNesting on ActiveUserDevPortalRejectionDto {
  message
}
`

export const ActiveUserEngagedBlabItemPreviewDtoNoNestingFragment = gql`
fragment ActiveUserEngagedBlabItemPreviewDtoNoNesting on ActiveUserEngagedBlabItemPreviewDto {
  id
  title
  engagementType
  blabDefId
}
`

export const ActiveUserExploreSuggestionDtoNoNestingFragment = gql`
fragment ActiveUserExploreSuggestionDtoNoNesting on ActiveUserExploreSuggestionDto {
  id
  type
  category
  blabItemId
}
`

export const ActiveUserExploreBlabItemPreviewDtoNoNestingFragment = gql`
fragment ActiveUserExploreBlabItemPreviewDtoNoNesting on ActiveUserExploreBlabItemPreviewDto {
  id
  title
  blabDefId
  numActivities
  isFollowing
  authorId
}
`

export const ActiveUserExploreSuggestionEdgeNoNestingFragment = gql`
fragment ActiveUserExploreSuggestionEdgeNoNesting on ActiveUserExploreSuggestionEdge {
  cursor
}
`

export const PageInfoNoNestingFragment = gql`
fragment PageInfoNoNesting on PageInfo {
  hasPreviousPage
  hasNextPage
}
`

export const ActiveUserNotificationSettingsDtoNoNestingFragment = gql`
fragment ActiveUserNotificationSettingsDtoNoNesting on ActiveUserNotificationSettingsDto {
  emailNotificationsActive
  sendDirectNotificationEmailsWhenUserOffline
  sendDirectNotificationEmailsWhenUserOnline
}
`

export const ActiveUserOnboardingTasksDtoNoNestingFragment = gql`
fragment ActiveUserOnboardingTasksDtoNoNesting on ActiveUserOnboardingTasksDto {
  dismissed
  hasProfilePicture
  hasInvitedUsers
  hasCompletedProfile
  hasInstalledMobileApp
}
`

export const OrgUserEdgeNoNestingFragment = gql`
fragment OrgUserEdgeNoNesting on OrgUserEdge {
  cursor
}
`

export const UserProfileDtoNoNestingFragment = gql`
fragment UserProfileDtoNoNesting on UserProfileDto {
  id
  primaryName
  secondaryName
  name
  email
  organizationId
  phoneNumber
  location
  website
  jobDescription
  birthDate
  tzOffset
}
`

export const UserStatusDtoNoNestingFragment = gql`
fragment UserStatusDtoNoNesting on UserStatusDto {
  userId
  presence
  note
  emoji
  doNotDisturb
  lastPresentAt
}
`

export const ActiveUserProfileDtoNoNestingFragment = gql`
fragment ActiveUserProfileDtoNoNesting on ActiveUserProfileDto {
  id
  name
  shortname
  jobDescription
  phoneNumber
  email
  location
  website
  birthDate
  timezone
}
`

export const ActiveUserQuickAddBlabDefDtoNoNestingFragment = gql`
fragment ActiveUserQuickAddBlabDefDtoNoNesting on ActiveUserQuickAddBlabDefDto {
  position
  blabDefId
}
`

export const ActiveUserSuggestedQuickAddBlabDefDtoEdgeNoNestingFragment = gql`
fragment ActiveUserSuggestedQuickAddBlabDefDtoEdgeNoNesting on ActiveUserSuggestedQuickAddBlabDefDtoEdge {
  cursor
}
`

export const ActiveUserSuggestedQuickAddBlabDefDtoNoNestingFragment = gql`
fragment ActiveUserSuggestedQuickAddBlabDefDtoNoNesting on ActiveUserSuggestedQuickAddBlabDefDto {
  userScore
  score
  blabDefId
}
`

export const ActiveUserUserInfoDtoNoNestingFragment = gql`
fragment ActiveUserUserInfoDtoNoNesting on ActiveUserUserInfoDto {
  userId
  targetUserId
  isFollowing
  isBeingNotified
}
`

export const ActiveUserWorkflowDefInfoDtoNoNestingFragment = gql`
fragment ActiveUserWorkflowDefInfoDtoNoNesting on ActiveUserWorkflowDefInfoDto {
  workflowDefId
  userId
  isFollowing
  lastViewedAt
}
`

export const WorkflowCenterRecentWorkflowPreviewDtoNoNestingFragment = gql`
fragment WorkflowCenterRecentWorkflowPreviewDtoNoNesting on WorkflowCenterRecentWorkflowPreviewDto {
  id
  blabDefId
  name
  description
  isPaused
  isBroken
  timezone
  triggerType
  createdAt
  createdByUserId
  lastModifiedAt
  lastModifiedByUserId
}
`

export const WorkflowCenterWorkflowActionPreviewNoNestingFragment = gql`
fragment WorkflowCenterWorkflowActionPreviewNoNesting on WorkflowCenterWorkflowActionPreview {
  id
  actionType
  customName
}
`

export const ActiveUserWorkspaceInfoDtoNoNestingFragment = gql`
fragment ActiveUserWorkspaceInfoDtoNoNesting on ActiveUserWorkspaceInfoDto {
  workspaceId
  lastSelectedBlabDefId
}
`

export const ApiInfoDtoNoNestingFragment = gql`
fragment ApiInfoDtoNoNesting on ApiInfoDto {
  lastBreakingApiChange
}
`

export const AudioRoomDtoNoNestingFragment = gql`
fragment AudioRoomDtoNoNesting on AudioRoomDto {
  id
  name
  authorId
}
`

export const AudioRoomParticipantDtoNoNestingFragment = gql`
fragment AudioRoomParticipantDtoNoNesting on AudioRoomParticipantDto {
  userId
  roomId
  muted
}
`

export const AudioRoomPreviewDtoNoNestingFragment = gql`
fragment AudioRoomPreviewDtoNoNesting on AudioRoomPreviewDto {
  id
  name
  authorId
}
`

export const BillingCustomerDtoNoNestingFragment = gql`
fragment BillingCustomerDtoNoNesting on BillingCustomerDto {
  id
  balance
  email
  firstName
  lastName
  companyName
  taxId
  businessCustomer
  country
  stateProvinceRegion
  city
  postalCode
  streetAddress
  suiteUnit
  invoiceNote
}
`

export const BillingCustomerValidationResultDtoNoNestingFragment = gql`
fragment BillingCustomerValidationResultDtoNoNesting on BillingCustomerValidationResultDto {
  taxId
}
`

export const ActiveBillingSubscriptionDtoNoNestingFragment = gql`
fragment ActiveBillingSubscriptionDtoNoNesting on ActiveBillingSubscriptionDto {
  active
  type
  plan
  billingPeriod
  billingCycleAnchor
  currentPeriodStart
  currentPeriodEnd
  createdAt
  cancelAtPeriodEnd
  requiresPaymentMethod
  quantity
}
`

export const BillingSubscriptionActionNoNestingFragment = gql`
fragment BillingSubscriptionActionNoNesting on BillingSubscriptionAction {
  redirectToUrl
  useStripeSdk
  clientSecret
}
`

export const SubscriptionInvoicePreviewDtoNoNestingFragment = gql`
fragment SubscriptionInvoicePreviewDtoNoNesting on SubscriptionInvoicePreviewDto {
  plan
  billingPeriod
  total
  amountDue
  currency
}
`

export const LicenseInvoiceItemDtoNoNestingFragment = gql`
fragment LicenseInvoiceItemDtoNoNesting on LicenseInvoiceItemDto {
  id
  type
  description
  amount
  currency
  unitAmount
  quantity
}
`

export const ProrationInvoiceItemDtoNoNestingFragment = gql`
fragment ProrationInvoiceItemDtoNoNesting on ProrationInvoiceItemDto {
  id
  type
  amount
  currency
}
`

export const DiscountInvoiceItemDtoNoNestingFragment = gql`
fragment DiscountInvoiceItemDtoNoNesting on DiscountInvoiceItemDto {
  id
  type
  name
  percentOff
  amountOff
  amountOffCurrency
}
`

export const TaxInvoiceItemDtoNoNestingFragment = gql`
fragment TaxInvoiceItemDtoNoNesting on TaxInvoiceItemDto {
  id
  type
  reason
  taxId
  displayName
  amount
  currency
  percentage
}
`

export const CreditInvoiceItemDtoNoNestingFragment = gql`
fragment CreditInvoiceItemDtoNoNesting on CreditInvoiceItemDto {
  id
  type
  description
  amount
  currency
}
`

export const IncompleteBillingSubscriptionDtoNoNestingFragment = gql`
fragment IncompleteBillingSubscriptionDtoNoNesting on IncompleteBillingSubscriptionDto {
  active
  type
  plan
  billingPeriod
  billingCycleAnchor
  currentPeriodStart
  currentPeriodEnd
  createdAt
  cancelAtPeriodEnd
  requiresPaymentMethod
  quantity
}
`

export const NoActiveOrIncompleteBillingSubscriptionDtoNoNestingFragment = gql`
fragment NoActiveOrIncompleteBillingSubscriptionDtoNoNesting on NoActiveOrIncompleteBillingSubscriptionDto {
  active
  type
  periodEnd
}
`

export const CardPaymentMethodDtoNoNestingFragment = gql`
fragment CardPaymentMethodDtoNoNesting on CardPaymentMethodDto {
  id
  type
  createdAt
  createdByUserId
  isDefault
  error
  lastFour
  name
  postalCode
}
`

export const SepaDebitPaymentMethodDtoNoNestingFragment = gql`
fragment SepaDebitPaymentMethodDtoNoNesting on SepaDebitPaymentMethodDto {
  id
  type
  createdAt
  createdByUserId
  isDefault
  error
  lastFour
  name
  postalCode
}
`

export const SubscriptionInvoiceDtoNoNestingFragment = gql`
fragment SubscriptionInvoiceDtoNoNesting on SubscriptionInvoiceDto {
  id
  createdAt
  amountDue
  number
  currency
  status
  pdfDownloadUrl
}
`

export const BlabDefDtoNoNestingFragment = gql`
fragment BlabDefDtoNoNesting on BlabDefDto {
  id
  slug
  blabName
  itemName
  description
  enablePermissions
  icon
  iconv2
  type
  itemIcon
  workspaceId
  position
  positionV2
  blabItemIdSequence
}
`

export const WorkspaceDtoNoNestingFragment = gql`
fragment WorkspaceDtoNoNesting on WorkspaceDto {
  id
  slug
  name
  position
  type
  createdAt
  description
  icon
  path
  organizationId
  numUsers
  hasWorkspaceTemplatePublication
}
`

export const BlabDefDataImportActivityPreviewDtoNoNestingFragment = gql`
fragment BlabDefDataImportActivityPreviewDtoNoNesting on BlabDefDataImportActivityPreviewDto {
  id
  fileId
  blabDefId
  createdAt
  createdByUserId
}
`

export const BlabDefDataImportFileDtoNoNestingFragment = gql`
fragment BlabDefDataImportFileDtoNoNesting on BlabDefDataImportFileDto {
  id
  name
  fileType
  numImportableRows
}
`

export const BlabDefDataImportActivityDtoNoNestingFragment = gql`
fragment BlabDefDataImportActivityDtoNoNesting on BlabDefDataImportActivityDto {
  id
  fileId
  blabDefId
  createdAt
  createdByUserId
  config
}
`

export const BlabDefDataImportToExistingBlabDefConfigPreviewDtoNoNestingFragment = gql`
fragment BlabDefDataImportToExistingBlabDefConfigPreviewDtoNoNesting on BlabDefDataImportToExistingBlabDefConfigPreviewDto {
  importFileParsingError
  characterCode
  csvValueSeparator
}
`

export const BlabDefDataImportFileColumnDtoNoNestingFragment = gql`
fragment BlabDefDataImportFileColumnDtoNoNesting on BlabDefDataImportFileColumnDto {
  columnIndex
  columnName
}
`

export const BlabDefDataImportFileCellValuesInfoDtoNoNestingFragment = gql`
fragment BlabDefDataImportFileCellValuesInfoDtoNoNesting on BlabDefDataImportFileCellValuesInfoDto {
  amountTotal
  amountEmpty
  amountNotEmpty
  maxTextLength
  amountInvalidSingleTextValues
  amountInvalidMultiTextValues
  amountNumbers
  maxNumberPrecision
  numberPrefix
  numberPostfix
  amountNumbersWithPreOrPostfix
  maxNumberPrecisionWithPreOrPostfix
  hideThousandSeparator
  amountUniqueSingleOptionValues
  amountInvalidSingleOptionValues
  amountUniqueMultiOptionValues
  amountInvalidMultiOptionValues
  amountSingleUserValues
  amountMultiUserValues
  amountDateTimeValues
  amountCalendarDayValues
  amountPhoneNumbers
  amountInvalidPhoneNumbers
  amountEmailAddresses
  amountInvalidEmailAddresses
  amountMultiUrlValues
  amountInvalidMultiUrlValues
  amountLocationValues
  amountInvalidLocationValues
}
`

export const BlabDefDataImportToNewBlabDefConfigPreviewDtoNoNestingFragment = gql`
fragment BlabDefDataImportToNewBlabDefConfigPreviewDtoNoNesting on BlabDefDataImportToNewBlabDefConfigPreviewDto {
  importFileParsingError
  characterCode
  csvValueSeparator
}
`

export const BlabDefDeveloperInfoDtoNoNestingFragment = gql`
fragment BlabDefDeveloperInfoDtoNoNesting on BlabDefDeveloperInfoDto {
  blabDefId
  workspaceId
  blabName
}
`

export const FlimDefDeveloperInfoDtoNoNestingFragment = gql`
fragment FlimDefDeveloperInfoDtoNoNesting on FlimDefDeveloperInfoDto {
  flimDefId
  blabDefId
  position
  name
  slug
  type
}
`

export const BlabDefWebhookDtoNoNestingFragment = gql`
fragment BlabDefWebhookDtoNoNesting on BlabDefWebhookDto {
  id
  url
  position
  active
  webhookType
  blabDefId
}
`

export const ActiveUserBlabDefInboundEmailSettingsDtoNoNestingFragment = gql`
fragment ActiveUserBlabDefInboundEmailSettingsDtoNoNesting on ActiveUserBlabDefInboundEmailSettingsDto {
  blabDefId
  publicEmail
  publicEmailEnabled
  privateEmail
  privateEmailEnabled
  inboundEmailFieldFromFlimDefId
  inboundEmailFieldCcFlimDefId
  inboundEmailFieldSubjectFlimDefId
  inboundEmailFieldBodyFlimDefId
  inboundEmailFieldAttachmentsFlimDefId
}
`

export const BlabDefSearchResultDtoNoNestingFragment = gql`
fragment BlabDefSearchResultDtoNoNesting on BlabDefSearchResultDto {
  type
}
`

export const BlabDefSettingsDtoNoNestingFragment = gql`
fragment BlabDefSettingsDtoNoNesting on BlabDefSettingsDto {
  blabDefId
  blabName
  itemName
  icon
  description
  enablePermissions
  slug
}
`

export const SharedBlabDefDtoNoNestingFragment = gql`
fragment SharedBlabDefDtoNoNesting on SharedBlabDefDto {
  id
  blabName
  itemName
  description
  icon
  workspaceId
  position
  type
}
`

export const BlabItemDetailDtoNoNestingFragment = gql`
fragment BlabItemDetailDtoNoNesting on BlabItemDetailDto {
  id
  title
  blabDefId
  createdAt
  numFollowers
  numAccess
  numComments
  isTemplate
  templateTitle
}
`

export const BlabItemEdgeNoNestingFragment = gql`
fragment BlabItemEdgeNoNesting on BlabItemEdge {
  cursor
}
`

export const BlabItemViewDtoNoNestingFragment = gql`
fragment BlabItemViewDtoNoNesting on BlabItemViewDto {
  id
  title
  viewPosition
  blabDefId
  createdAt
  numComments
  isTemplate
  templateTitle
}
`

export const BlabItemCommentActivityDtoNoNestingFragment = gql`
fragment BlabItemCommentActivityDtoNoNesting on BlabItemCommentActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  clientSideId
  isDeleted
  createdVia
}
`

export const WorkflowDefPreviewDtoNoNestingFragment = gql`
fragment WorkflowDefPreviewDtoNoNesting on WorkflowDefPreviewDto {
  id
  name
  blabDefId
  activeUserHasPermission
  isDeleted
}
`

export const ReactionDtoNoNestingFragment = gql`
fragment ReactionDtoNoNesting on ReactionDto {
  emoji
}
`

export const BlabItemActivityReplyEdgeNoNestingFragment = gql`
fragment BlabItemActivityReplyEdgeNoNesting on BlabItemActivityReplyEdge {
  cursor
}
`

export const BlabItemActivityReplyDtoNoNestingFragment = gql`
fragment BlabItemActivityReplyDtoNoNesting on BlabItemActivityReplyDto {
  id
  createdAt
  activityId
  userId
  authorWorkflowDefId
  content
  lastEditedAt
  clientSideId
}
`

export const BlabItemActivityReplyAttachmentDtoNoNestingFragment = gql`
fragment BlabItemActivityReplyAttachmentDtoNoNesting on BlabItemActivityReplyAttachmentDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
}
`

export const LinkPreviewDtoNoNestingFragment = gql`
fragment LinkPreviewDtoNoNesting on LinkPreviewDto {
  id
  url
  title
  description
}
`

export const BlabItemCommentDtoNoNestingFragment = gql`
fragment BlabItemCommentDtoNoNesting on BlabItemCommentDto {
  activityId
  content
  lastEditedAt
}
`

export const BlabItemCommentAttachmentDtoNoNestingFragment = gql`
fragment BlabItemCommentAttachmentDtoNoNesting on BlabItemCommentAttachmentDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
}
`

export const BlabItemRevisionActivityDtoNoNestingFragment = gql`
fragment BlabItemRevisionActivityDtoNoNesting on BlabItemRevisionActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  createdVia
}
`

export const SingleTextFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleTextFlimValRevisionDtoNoNesting on SingleTextFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevValue
  value
}
`

export const FlimDefRevisionPreviewDtoNoNestingFragment = gql`
fragment FlimDefRevisionPreviewDtoNoNesting on FlimDefRevisionPreviewDto {
  id
  name
  type
  blabDefId
}
`

export const CategoryFlimDefRevisionPreviewDtoNoNestingFragment = gql`
fragment CategoryFlimDefRevisionPreviewDtoNoNesting on CategoryFlimDefRevisionPreviewDto {
  id
  name
  type
  blabDefId
}
`

export const CategoryOptionDefRevisionPreviewDtoNoNestingFragment = gql`
fragment CategoryOptionDefRevisionPreviewDtoNoNesting on CategoryOptionDefRevisionPreviewDto {
  id
  label
}
`

export const NumberFlimDefRevisionPreviewDtoNoNestingFragment = gql`
fragment NumberFlimDefRevisionPreviewDtoNoNesting on NumberFlimDefRevisionPreviewDto {
  id
  name
  type
  blabDefId
  unitLocation
  unitLabel
}
`

export const StatusFlimDefRevisionPreviewDtoNoNestingFragment = gql`
fragment StatusFlimDefRevisionPreviewDtoNoNesting on StatusFlimDefRevisionPreviewDto {
  id
  name
  type
  blabDefId
}
`

export const StatusOptionDefRevisionPreviewDtoNoNestingFragment = gql`
fragment StatusOptionDefRevisionPreviewDtoNoNesting on StatusOptionDefRevisionPreviewDto {
  id
  label
}
`

export const MultiTextFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiTextFlimValRevisionDtoNoNesting on MultiTextFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevUnformattedValue
  unformattedValue
  changeType
}
`

export const SingleCategoryFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleCategoryFlimValRevisionDtoNoNesting on SingleCategoryFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevCategoryOptionDefId
  categoryOptionDefId
}
`

export const MultiCategoryFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiCategoryFlimValRevisionDtoNoNesting on MultiCategoryFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevCategoryOptionDefIds
  categoryOptionDefIds
}
`

export const SingleRelationFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleRelationFlimValRevisionDtoNoNesting on SingleRelationFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevReferencedBlabItemId
  referencedBlabItemId
}
`

export const MultiRelationFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiRelationFlimValRevisionDtoNoNesting on MultiRelationFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevReferencedBlabItemIds
  referencedBlabItemIds
}
`

export const IncomingRelationRevisionDtoNoNestingFragment = gql`
fragment IncomingRelationRevisionDtoNoNesting on IncomingRelationRevisionDto {
  id
  type
  createdAt
  referencingBlabItemId
  referencingFlimDefId
  isBeingReferenced
}
`

export const SingleAttachmentFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimValRevisionDtoNoNesting on SingleAttachmentFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevAttachmentId
  attachmentId
}
`

export const FlimValAttachmentDtoNoNestingFragment = gql`
fragment FlimValAttachmentDtoNoNesting on FlimValAttachmentDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
  authorId
  authorWorkflowDefId
  flimDefId
  createdVia
}
`

export const MultiAttachmentFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimValRevisionDtoNoNesting on MultiAttachmentFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevAttachmentIds
  attachmentIds
}
`

export const MultiImageFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiImageFlimValRevisionDtoNoNesting on MultiImageFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevImageIds
  imageIds
}
`

export const FlimValImageDtoNoNestingFragment = gql`
fragment FlimValImageDtoNoNesting on FlimValImageDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
  authorWorkflowDefId
  authorId
  flimDefId
  createdVia
}
`

export const SingleUserFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleUserFlimValRevisionDtoNoNesting on SingleUserFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevUserId
  userId
}
`

export const MultiUserFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiUserFlimValRevisionDtoNoNesting on MultiUserFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevUserIds
  userIds
}
`

export const SingleDateFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleDateFlimValRevisionDtoNoNesting on SingleDateFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevDate
  prevHasTime
  date
  hasTime
}
`

export const RangeDateFlimValRevisionDtoNoNestingFragment = gql`
fragment RangeDateFlimValRevisionDtoNoNesting on RangeDateFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevStartDate
  prevHasTime
  prevEndDate
  startDate
  hasTime
  endDate
}
`

export const ChecklistFlimValRevisionDtoNoNestingFragment = gql`
fragment ChecklistFlimValRevisionDtoNoNesting on ChecklistFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevChecklistEntryId
  checklistEntryId
  prevCompleted
  completed
  prevAssigneeUserId
  assigneeUserId
}
`

export const ChecklistFlimValEntryDtoNoNestingFragment = gql`
fragment ChecklistFlimValEntryDtoNoNesting on ChecklistFlimValEntryDto {
  id
  blabItemId
  flimDefId
  position
  completed
  title
  description
  dueAt
  dueAtHasTime
  assigneeUserId
}
`

export const MultiLinkFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiLinkFlimValRevisionDtoNoNesting on MultiLinkFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevMultiLinkEntryId
  multiLinkEntryId
  prevUrl
  url
}
`

export const MultiLinkFlimValEntryDtoNoNestingFragment = gql`
fragment MultiLinkFlimValEntryDtoNoNesting on MultiLinkFlimValEntryDto {
  id
  blabItemId
  flimDefId
  position
}
`

export const NumberFlimValRevisionDtoNoNestingFragment = gql`
fragment NumberFlimValRevisionDtoNoNesting on NumberFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevDecimal
  prevPrecision
  decimal
  precision
}
`

export const StatusFlimValRevisionDtoNoNestingFragment = gql`
fragment StatusFlimValRevisionDtoNoNesting on StatusFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevStatusOptionDefId
  statusOptionDefId
}
`

export const MultiEmailFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiEmailFlimValRevisionDtoNoNesting on MultiEmailFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevEmailAddress
  emailAddress
  prevEmailAddressType
  emailAddressType
}
`

export const MultiPhoneFlimValRevisionDtoNoNestingFragment = gql`
fragment MultiPhoneFlimValRevisionDtoNoNesting on MultiPhoneFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  prevPhoneNumber
  phoneNumber
  prevPhoneNumberType
  phoneNumberType
}
`

export const SingleLocationFlimValRevisionDtoNoNestingFragment = gql`
fragment SingleLocationFlimValRevisionDtoNoNesting on SingleLocationFlimValRevisionDto {
  id
  type
  flimType
  createdAt
  blabItemId
  flimDefId
  city
  prevCity
  state
  prevState
  country
  prevCountry
  streetAddress
  prevStreetAddress
  postalCode
  prevPostalCode
  originalFormattedAddress
  prevOriginalFormattedAddress
}
`

export const BlabItemCreatedActivityDtoNoNestingFragment = gql`
fragment BlabItemCreatedActivityDtoNoNesting on BlabItemCreatedActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  creationType
  createdVia
}
`

export const BlabItemDeletedActivityDtoNoNestingFragment = gql`
fragment BlabItemDeletedActivityDtoNoNesting on BlabItemDeletedActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  createdVia
}
`

export const BlabItemRestoredActivityDtoNoNestingFragment = gql`
fragment BlabItemRestoredActivityDtoNoNesting on BlabItemRestoredActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  createdVia
}
`

export const BlabItemInvitationCreatedActivityDtoNoNestingFragment = gql`
fragment BlabItemInvitationCreatedActivityDtoNoNesting on BlabItemInvitationCreatedActivityDto {
  id
  blabItemId
  type
  createdAt
  authorId
  authorWorkflowDefId
  numReplies
  targetUserId
  createdVia
  invitationText
}
`

export const BlabItemActivityEdgeNoNestingFragment = gql`
fragment BlabItemActivityEdgeNoNesting on BlabItemActivityEdge {
  cursor
}
`

export const BlabItemActivityCountsDtoNoNestingFragment = gql`
fragment BlabItemActivityCountsDtoNoNesting on BlabItemActivityCountsDto {
  blabItemId
  commentActivityCount
  updateActivityCount
}
`

export const GetBlabItemFocusSectionResultDtoNoNestingFragment = gql`
fragment GetBlabItemFocusSectionResultDtoNoNesting on GetBlabItemFocusSectionResultDto {
  focusSectionId
}
`

export const GetChecklistFlimValEntryFocusSectionResultDtoNoNestingFragment = gql`
fragment GetChecklistFlimValEntryFocusSectionResultDtoNoNesting on GetChecklistFlimValEntryFocusSectionResultDto {
  focusSectionId
}
`

export const BlabItemFollowersEdgeNoNestingFragment = gql`
fragment BlabItemFollowersEdgeNoNesting on BlabItemFollowersEdge {
  cursor
}
`

export const IncomingRelationFlimDefPreviewDtoNoNestingFragment = gql`
fragment IncomingRelationFlimDefPreviewDtoNoNesting on IncomingRelationFlimDefPreviewDto {
  numReferencingBlabItems
}
`

export const ReferencingBlabItemEdgeNoNestingFragment = gql`
fragment ReferencingBlabItemEdgeNoNesting on ReferencingBlabItemEdge {
  cursor
}
`

export const IncomingBlabItemEdgeNoNestingFragment = gql`
fragment IncomingBlabItemEdgeNoNesting on IncomingBlabItemEdge {
  cursor
}
`

export const ReminderEdgeNoNestingFragment = gql`
fragment ReminderEdgeNoNesting on ReminderEdge {
  cursor
}
`

export const ReminderDtoNoNestingFragment = gql`
fragment ReminderDtoNoNesting on ReminderDto {
  id
  statusUpdatedAt
  title
  description
  status
  dueAt
  dueAtHasTime
  isBeingNotified
  referencedBlabItemId
}
`

export const BlabItemSearchResultDtoNoNestingFragment = gql`
fragment BlabItemSearchResultDtoNoNesting on BlabItemSearchResultDto {
  type
}
`

export const BlabItemStatsResultDtoNoNestingFragment = gql`
fragment BlabItemStatsResultDtoNoNesting on BlabItemStatsResultDto {
  total
  totalEditable
  blabDefId
}
`

export const SingleTextFlimStatResultDtoNoNestingFragment = gql`
fragment SingleTextFlimStatResultDtoNoNesting on SingleTextFlimStatResultDto {
  singleTextFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiTextFlimStatResultDtoNoNestingFragment = gql`
fragment MultiTextFlimStatResultDtoNoNesting on MultiTextFlimStatResultDto {
  multiTextFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleCategoryFlimStatResultDtoNoNestingFragment = gql`
fragment SingleCategoryFlimStatResultDtoNoNesting on SingleCategoryFlimStatResultDto {
  singleCategoryFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiCategoryFlimStatResultDtoNoNestingFragment = gql`
fragment MultiCategoryFlimStatResultDtoNoNesting on MultiCategoryFlimStatResultDto {
  multiCategoryFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleUserFlimStatResultDtoNoNestingFragment = gql`
fragment SingleUserFlimStatResultDtoNoNesting on SingleUserFlimStatResultDto {
  singleUserFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiUserFlimStatResultDtoNoNestingFragment = gql`
fragment MultiUserFlimStatResultDtoNoNesting on MultiUserFlimStatResultDto {
  multiUserFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleAttachmentFlimStatResultDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimStatResultDtoNoNesting on SingleAttachmentFlimStatResultDto {
  singleAttachmentFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiAttachmentFlimStatResultDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimStatResultDtoNoNesting on MultiAttachmentFlimStatResultDto {
  multiAttachmentFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleRelationFlimStatResultDtoNoNestingFragment = gql`
fragment SingleRelationFlimStatResultDtoNoNesting on SingleRelationFlimStatResultDto {
  singleRelationFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiRelationFlimStatResultDtoNoNestingFragment = gql`
fragment MultiRelationFlimStatResultDtoNoNesting on MultiRelationFlimStatResultDto {
  multiRelationFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleDateFlimStatResultDtoNoNestingFragment = gql`
fragment SingleDateFlimStatResultDtoNoNesting on SingleDateFlimStatResultDto {
  singleDateFlimStatType
  flimDefId
  result
  flimType
}
`

export const RangeDateFlimStatResultDtoNoNestingFragment = gql`
fragment RangeDateFlimStatResultDtoNoNesting on RangeDateFlimStatResultDto {
  rangeDateFlimStatType
  flimDefId
  result
  flimType
}
`

export const StatusFlimStatResultDtoNoNestingFragment = gql`
fragment StatusFlimStatResultDtoNoNesting on StatusFlimStatResultDto {
  statusFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiLinkFlimStatResultDtoNoNestingFragment = gql`
fragment MultiLinkFlimStatResultDtoNoNesting on MultiLinkFlimStatResultDto {
  multiLinkFlimStatType
  flimDefId
  result
  flimType
}
`

export const LastModifiedAtFlimStatResultDtoNoNestingFragment = gql`
fragment LastModifiedAtFlimStatResultDtoNoNesting on LastModifiedAtFlimStatResultDto {
  lastModifiedAtFlimStatType
  flimDefId
  result
  flimType
}
`

export const LastModifiedByFlimStatResultDtoNoNestingFragment = gql`
fragment LastModifiedByFlimStatResultDtoNoNesting on LastModifiedByFlimStatResultDto {
  lastModifiedByFlimStatType
  flimDefId
  result
  flimType
}
`

export const CreatedAtFlimStatResultDtoNoNestingFragment = gql`
fragment CreatedAtFlimStatResultDtoNoNesting on CreatedAtFlimStatResultDto {
  createdAtFlimStatType
  flimDefId
  result
  flimType
}
`

export const CreatedByFlimStatResultDtoNoNestingFragment = gql`
fragment CreatedByFlimStatResultDtoNoNesting on CreatedByFlimStatResultDto {
  createdByFlimStatType
  flimDefId
  result
  flimType
}
`

export const NumberFlimStatResultDtoNoNestingFragment = gql`
fragment NumberFlimStatResultDtoNoNesting on NumberFlimStatResultDto {
  numberFlimStatType
  flimDefId
  result
  flimType
}
`

export const ChecklistFlimStatResultDtoNoNestingFragment = gql`
fragment ChecklistFlimStatResultDtoNoNesting on ChecklistFlimStatResultDto {
  checklistFlimStatType
  flimDefId
  result
  flimType
}
`

export const CalculationFlimStatResultDtoNoNestingFragment = gql`
fragment CalculationFlimStatResultDtoNoNesting on CalculationFlimStatResultDto {
  calculationFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiEmailFlimStatResultDtoNoNestingFragment = gql`
fragment MultiEmailFlimStatResultDtoNoNesting on MultiEmailFlimStatResultDto {
  multiEmailFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiPhoneFlimStatResultDtoNoNestingFragment = gql`
fragment MultiPhoneFlimStatResultDtoNoNesting on MultiPhoneFlimStatResultDto {
  multiPhoneFlimStatType
  flimDefId
  result
  flimType
}
`

export const MultiImageFlimStatResultDtoNoNestingFragment = gql`
fragment MultiImageFlimStatResultDtoNoNesting on MultiImageFlimStatResultDto {
  multiImageFlimStatType
  flimDefId
  result
  flimType
}
`

export const SingleLocationFlimStatResultDtoNoNestingFragment = gql`
fragment SingleLocationFlimStatResultDtoNoNesting on SingleLocationFlimStatResultDto {
  singleLocationFlimStatType
  flimDefId
  result
  flimType
}
`

export const UniqueIdFlimStatResultDtoNoNestingFragment = gql`
fragment UniqueIdFlimStatResultDtoNoNesting on UniqueIdFlimStatResultDto {
  uniqueIdFlimStatType
  flimDefId
  result
  flimType
}
`

export const BlabItemTemplateDtoNoNestingFragment = gql`
fragment BlabItemTemplateDtoNoNesting on BlabItemTemplateDto {
  id
  title
  position
  blabDefId
  isPrivate
  isDefault
}
`

export const GetBlabViewsPaginatedResultDtoNoNestingFragment = gql`
fragment GetBlabViewsPaginatedResultDtoNoNesting on GetBlabViewsPaginatedResultDto {
  numTotal
}
`

export const ChatGroupSearchResultDtoNoNestingFragment = gql`
fragment ChatGroupSearchResultDtoNoNesting on ChatGroupSearchResultDto {
  type
}
`

export const ChatGroupDtoNoNestingFragment = gql`
fragment ChatGroupDtoNoNesting on ChatGroupDto {
  type
  conversationId
  name
  userReadUntil
  mutedByUser
  numUnreadActivities
}
`

export const UserSearchResultDtoNoNestingFragment = gql`
fragment UserSearchResultDtoNoNesting on UserSearchResultDto {
  type
}
`

export const CheckEmailResultDtoNoNestingFragment = gql`
fragment CheckEmailResultDtoNoNesting on CheckEmailResultDto {
  result
}
`

export const FocusDueDateSectionDtoNoNestingFragment = gql`
fragment FocusDueDateSectionDtoNoNesting on FocusDueDateSectionDto {
  type
  expanded
  total
}
`

export const FocusSectionPreviewDtoNoNestingFragment = gql`
fragment FocusSectionPreviewDtoNoNesting on FocusSectionPreviewDto {
  id
  name
  position
  initialFocusSectionType
  isDefault
}
`

export const FocusSectionWithTotalDtoNoNestingFragment = gql`
fragment FocusSectionWithTotalDtoNoNesting on FocusSectionWithTotalDto {
  id
  name
  position
  expanded
  isDefault
  total
  initialFocusSectionType
}
`

export const FocusSettingsDtoNoNestingFragment = gql`
fragment FocusSettingsDtoNoNesting on FocusSettingsDto {
  sortWithinSections
  sortType
  completedFilterType
  excludeReminders
  excludeChecklistEntries
  excludeBlabItems
}
`

export const WorkspaceSearchResultDtoNoNestingFragment = gql`
fragment WorkspaceSearchResultDtoNoNesting on WorkspaceSearchResultDto {
  type
}
`

export const ChatMessageSearchResultDtoNoNestingFragment = gql`
fragment ChatMessageSearchResultDtoNoNesting on ChatMessageSearchResultDto {
  type
  cursor
}
`

export const ChatMessageActivityDtoNoNestingFragment = gql`
fragment ChatMessageActivityDtoNoNesting on ChatMessageActivityDto {
  id
  type
  createdAt
  userId
  conversationId
  content
  unformatted
  clientSideId
}
`

export const ChatMessageAttachmentDtoNoNestingFragment = gql`
fragment ChatMessageAttachmentDtoNoNesting on ChatMessageAttachmentDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
}
`

export const SearchHistoryItemQueryDtoNoNestingFragment = gql`
fragment SearchHistoryItemQueryDtoNoNesting on SearchHistoryItemQueryDto {
  id
  type
  query
}
`

export const SearchHistoryItemResultDtoNoNestingFragment = gql`
fragment SearchHistoryItemResultDtoNoNesting on SearchHistoryItemResultDto {
  id
  type
}
`

export const VoidNoNestingFragment = gql`
fragment VoidNoNesting on Void {
  _void
}
`

export const LocationAutocompleteSuggestionDtoNoNestingFragment = gql`
fragment LocationAutocompleteSuggestionDtoNoNesting on LocationAutocompleteSuggestionDto {
  locationId
  description
  mainText
  secondaryText
}
`

export const GetLocationAutocompleteSuggestionsRejectionDtoNoNestingFragment = gql`
fragment GetLocationAutocompleteSuggestionsRejectionDtoNoNesting on GetLocationAutocompleteSuggestionsRejectionDto {
  message
}
`

export const LocationDetailsDtoNoNestingFragment = gql`
fragment LocationDetailsDtoNoNesting on LocationDetailsDto {
  address
  city
  country
  state
  postalCode
  streetAddress
  locationId
  embedUrl
  latitude
  longitude
}
`

export const GetLocationDetailsRejectionDtoNoNestingFragment = gql`
fragment GetLocationDetailsRejectionDtoNoNesting on GetLocationDetailsRejectionDto {
  message
}
`

export const NewsfeedActivityEdgeNoNestingFragment = gql`
fragment NewsfeedActivityEdgeNoNesting on NewsfeedActivityEdge {
  cursor
}
`

export const NewsfeedActivityDtoNoNestingFragment = gql`
fragment NewsfeedActivityDtoNoNesting on NewsfeedActivityDto {
  id
  following
  type
}
`

export const BlabDefInvitationCreatedActivityDtoNoNestingFragment = gql`
fragment BlabDefInvitationCreatedActivityDtoNoNesting on BlabDefInvitationCreatedActivityDto {
  id
  blabDefId
  type
  createdAt
  authorId
  numReplies
  targetUserId
  invitationText
}
`

export const BlabDefActivityReplyEdgeNoNestingFragment = gql`
fragment BlabDefActivityReplyEdgeNoNesting on BlabDefActivityReplyEdge {
  cursor
}
`

export const BlabDefActivityReplyDtoNoNestingFragment = gql`
fragment BlabDefActivityReplyDtoNoNesting on BlabDefActivityReplyDto {
  id
  createdAt
  activityId
  userId
  content
}
`

export const WorkspaceCreatedActivityDtoNoNestingFragment = gql`
fragment WorkspaceCreatedActivityDtoNoNesting on WorkspaceCreatedActivityDto {
  id
  workspaceId
  type
  createdAt
  authorId
  numReplies
}
`

export const WorkspaceActivityReplyEdgeNoNestingFragment = gql`
fragment WorkspaceActivityReplyEdgeNoNesting on WorkspaceActivityReplyEdge {
  cursor
}
`

export const WorkspaceActivityReplyDtoNoNestingFragment = gql`
fragment WorkspaceActivityReplyDtoNoNesting on WorkspaceActivityReplyDto {
  id
  createdAt
  activityId
  userId
  content
}
`

export const WorkspaceUserAddedActivityDtoNoNestingFragment = gql`
fragment WorkspaceUserAddedActivityDtoNoNesting on WorkspaceUserAddedActivityDto {
  id
  workspaceId
  type
  createdAt
  authorId
  targetUserId
  role
  numReplies
}
`

export const OrganizationCreatedActivityDtoNoNestingFragment = gql`
fragment OrganizationCreatedActivityDtoNoNesting on OrganizationCreatedActivityDto {
  id
  organizationId
  type
  createdAt
  authorId
  numReplies
}
`

export const OrganizationActivityReplyEdgeNoNestingFragment = gql`
fragment OrganizationActivityReplyEdgeNoNesting on OrganizationActivityReplyEdge {
  cursor
}
`

export const OrganizationActivityReplyDtoNoNestingFragment = gql`
fragment OrganizationActivityReplyDtoNoNesting on OrganizationActivityReplyDto {
  id
  createdAt
  activityId
  userId
  content
}
`

export const OrganizationLogoChangedActivityDtoNoNestingFragment = gql`
fragment OrganizationLogoChangedActivityDtoNoNesting on OrganizationLogoChangedActivityDto {
  id
  organizationId
  type
  createdAt
  authorId
  numReplies
}
`

export const UserCreatedActivityDtoNoNestingFragment = gql`
fragment UserCreatedActivityDtoNoNesting on UserCreatedActivityDto {
  id
  userId
  type
  createdAt
  authorId
  numReplies
}
`

export const UserActivityReplyEdgeNoNestingFragment = gql`
fragment UserActivityReplyEdgeNoNesting on UserActivityReplyEdge {
  cursor
}
`

export const UserActivityReplyDtoNoNestingFragment = gql`
fragment UserActivityReplyDtoNoNesting on UserActivityReplyDto {
  id
  createdAt
  activityId
  userId
  content
}
`

export const UserPostActivityDtoNoNestingFragment = gql`
fragment UserPostActivityDtoNoNesting on UserPostActivityDto {
  id
  userId
  type
  createdAt
  authorId
  numReplies
}
`

export const UserPostDtoNoNestingFragment = gql`
fragment UserPostDtoNoNesting on UserPostDto {
  activityId
  content
}
`

export const UserPostAttachmentDtoNoNestingFragment = gql`
fragment UserPostAttachmentDtoNoNesting on UserPostAttachmentDto {
  id
  key
  url
  viewUrl
  downloadUrl
  createdAt
  name
  extension
  mimetype
  size
  thumbnailId
}
`

export const NotificationEdgeNoNestingFragment = gql`
fragment NotificationEdgeNoNesting on NotificationEdge {
  cursor
}
`

export const NotificationDtoNoNestingFragment = gql`
fragment NotificationDtoNoNesting on NotificationDto {
  type
}
`

export const UserNotificationDtoNoNestingFragment = gql`
fragment UserNotificationDtoNoNesting on UserNotificationDto {
  id
  type
  read
  updatedAt
  userId
  numActivities
}
`

export const UserNotificationActivityDtoNoNestingFragment = gql`
fragment UserNotificationActivityDtoNoNesting on UserNotificationActivityDto {
  notificationId
  direct
}
`

export const BlabItemNotificationDtoNoNestingFragment = gql`
fragment BlabItemNotificationDtoNoNesting on BlabItemNotificationDto {
  id
  type
  read
  updatedAt
  blabItemId
  numActivities
}
`

export const BlabItemNotificationActivityDtoNoNestingFragment = gql`
fragment BlabItemNotificationActivityDtoNoNesting on BlabItemNotificationActivityDto {
  notificationId
  direct
}
`

export const WorkspaceNotificationDtoNoNestingFragment = gql`
fragment WorkspaceNotificationDtoNoNesting on WorkspaceNotificationDto {
  id
  type
  read
  updatedAt
  workspaceId
  userId
  numActivities
}
`

export const WorkspaceNotificationActivityDtoNoNestingFragment = gql`
fragment WorkspaceNotificationActivityDtoNoNesting on WorkspaceNotificationActivityDto {
  notificationId
  direct
}
`

export const AudioRoomNotificationDtoNoNestingFragment = gql`
fragment AudioRoomNotificationDtoNoNesting on AudioRoomNotificationDto {
  id
  type
  read
  updatedAt
  audioRoomId
  numActivities
}
`

export const AudioRoomNotificationActivityDtoNoNestingFragment = gql`
fragment AudioRoomNotificationActivityDtoNoNesting on AudioRoomNotificationActivityDto {
  notificationId
  direct
}
`

export const AudioRoomActivityDtoNoNestingFragment = gql`
fragment AudioRoomActivityDtoNoNesting on AudioRoomActivityDto {
  id
  audioRoomId
  type
  createdAt
  authorId
}
`

export const WorkflowDefNotificationDtoNoNestingFragment = gql`
fragment WorkflowDefNotificationDtoNoNesting on WorkflowDefNotificationDto {
  id
  type
  read
  updatedAt
  workflowDefId
  numActivities
}
`

export const WorkflowDefNotificationActivityDtoNoNestingFragment = gql`
fragment WorkflowDefNotificationActivityDtoNoNesting on WorkflowDefNotificationActivityDto {
  notificationId
  direct
}
`

export const WorkflowDefActivityDtoNoNestingFragment = gql`
fragment WorkflowDefActivityDtoNoNesting on WorkflowDefActivityDto {
  id
  workflowDefId
  type
  createdAt
  authorId
}
`

export const BlabDefNotificationDtoNoNestingFragment = gql`
fragment BlabDefNotificationDtoNoNesting on BlabDefNotificationDto {
  id
  type
  read
  updatedAt
  blabDefId
  numActivities
}
`

export const BlabDefNotificationActivityDtoNoNestingFragment = gql`
fragment BlabDefNotificationActivityDtoNoNesting on BlabDefNotificationActivityDto {
  notificationId
  direct
}
`

export const UserGroupNotificationDtoNoNestingFragment = gql`
fragment UserGroupNotificationDtoNoNesting on UserGroupNotificationDto {
  id
  type
  read
  updatedAt
  userGroupId
  userGroupName
  numActivities
}
`

export const UserGroupNotificationActivityDtoNoNestingFragment = gql`
fragment UserGroupNotificationActivityDtoNoNesting on UserGroupNotificationActivityDto {
  notificationId
  direct
}
`

export const UserGroupActivityDtoNoNestingFragment = gql`
fragment UserGroupActivityDtoNoNesting on UserGroupActivityDto {
  id
  userGroupId
  type
  createdAt
  authorId
}
`

export const OrganizationActivityEdgeNoNestingFragment = gql`
fragment OrganizationActivityEdgeNoNesting on OrganizationActivityEdge {
  cursor
}
`

export const OrganizationInvitationEdgeDtoNoNestingFragment = gql`
fragment OrganizationInvitationEdgeDtoNoNesting on OrganizationInvitationEdgeDto {
  cursor
}
`

export const OrganizationInvitationDtoNoNestingFragment = gql`
fragment OrganizationInvitationDtoNoNesting on OrganizationInvitationDto {
  id
  email
  role
  status
  createdAt
  acceptedAt
  userId
  authorId
  approvedOrDeniedById
}
`

export const OrganizationInvitationSignupUserInfoDtoNoNestingFragment = gql`
fragment OrganizationInvitationSignupUserInfoDtoNoNesting on OrganizationInvitationSignupUserInfoDto {
  organizationId
}
`

export const GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAcceptedNoNestingFragment = gql`
fragment GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAcceptedNoNesting on GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAccepted {
  findMyOrganizationsToken
}
`

export const OrganizationPlanDtoNoNestingFragment = gql`
fragment OrganizationPlanDtoNoNesting on OrganizationPlanDto {
  id
  plan
}
`

export const BillingPriceDtoNoNestingFragment = gql`
fragment BillingPriceDtoNoNesting on BillingPriceDto {
  id
  currency
  unitAmount
  billingPeriod
}
`

export const OrganizationProfileEditDtoNoNestingFragment = gql`
fragment OrganizationProfileEditDtoNoNesting on OrganizationProfileEditDto {
  id
  slug
  name
  description
  location
  website
  email
  phoneNumber
}
`

export const OrganizationAuthenticationProviderDtoNoNestingFragment = gql`
fragment OrganizationAuthenticationProviderDtoNoNesting on OrganizationAuthenticationProviderDto {
  id
  organizationId
  hostAddress
  hostPort
  userName
  encryption
  label
  isTapeInternalSmtp
  isDefault
}
`

export const OrganizationSettingsDtoNoNestingFragment = gql`
fragment OrganizationSettingsDtoNoNesting on OrganizationSettingsDto {
  organizationId
  defaultLanguage
  preferFullnameInUserProfile
  requireInvitationApproval
}
`

export const OrganizationSmtpSettingsDtoNoNestingFragment = gql`
fragment OrganizationSmtpSettingsDtoNoNesting on OrganizationSmtpSettingsDto {
  id
  organizationId
  hostAddress
  hostPort
  userName
  encryption
  label
  isTapeInternalSmtp
  isDefault
}
`

export const OrganizationUserEdgeDtoNoNestingFragment = gql`
fragment OrganizationUserEdgeDtoNoNesting on OrganizationUserEdgeDto {
  cursor
}
`

export const OrganizationUserDtoNoNestingFragment = gql`
fragment OrganizationUserDtoNoNesting on OrganizationUserDto {
  id
  name
  shortname
  email
  organizationId
  jobDescription
  role
  deactivated
  isBilled
  invitationId
}
`

export const OrganizationWorkspaceEdgeDtoNoNestingFragment = gql`
fragment OrganizationWorkspaceEdgeDtoNoNesting on OrganizationWorkspaceEdgeDto {
  cursor
}
`

export const OrganizationWorkspaceDtoNoNestingFragment = gql`
fragment OrganizationWorkspaceDtoNoNesting on OrganizationWorkspaceDto {
  id
  organizationId
  name
  path
  icon
  slug
  position
  type
  archived
  numUsers
  createdAt
  activeUserRole
}
`

export const RelationFlimDefBlabDefSearchResultDtoNoNestingFragment = gql`
fragment RelationFlimDefBlabDefSearchResultDtoNoNesting on RelationFlimDefBlabDefSearchResultDto {
  type
}
`

export const RelationFlimDefBlabDefSearchResultBlabDefPreviewDtoNoNestingFragment = gql`
fragment RelationFlimDefBlabDefSearchResultBlabDefPreviewDtoNoNesting on RelationFlimDefBlabDefSearchResultBlabDefPreviewDto {
  id
  position
  blabName
  itemName
  slug
  icon
  iconv2
  itemIcon
  workspaceId
}
`

export const GetNumRemindersResultDtoNoNestingFragment = gql`
fragment GetNumRemindersResultDtoNoNesting on GetNumRemindersResultDto {
  numReminders
}
`

export const GetReminderFocusSectionResultDtoNoNestingFragment = gql`
fragment GetReminderFocusSectionResultDtoNoNesting on GetReminderFocusSectionResultDto {
  focusSectionId
}
`

export const ShareContextResultDtoNoNestingFragment = gql`
fragment ShareContextResultDtoNoNesting on ShareContextResultDto {
  rejection
}
`

export const OrganizationShareContextDtoNoNestingFragment = gql`
fragment OrganizationShareContextDtoNoNesting on OrganizationShareContextDto {
  type
  canDuplicate
}
`

export const ShareContextUserSessionDtoNoNestingFragment = gql`
fragment ShareContextUserSessionDtoNoNesting on ShareContextUserSessionDto {
  active
  organizationRole
  organizationSlug
  userId
  createdAt
}
`

export const WorkspaceShareContextDtoNoNestingFragment = gql`
fragment WorkspaceShareContextDtoNoNesting on WorkspaceShareContextDto {
  type
  workspaceId
  canDuplicate
}
`

export const BlabDefShareContextDtoNoNestingFragment = gql`
fragment BlabDefShareContextDtoNoNesting on BlabDefShareContextDto {
  type
  blabDefId
}
`

export const BlabItemShareContextDtoNoNestingFragment = gql`
fragment BlabItemShareContextDtoNoNesting on BlabItemShareContextDto {
  type
  blabItemId
}
`

export const StripeClientConfigDtoNoNestingFragment = gql`
fragment StripeClientConfigDtoNoNesting on StripeClientConfigDto {
  publishableApiKey
}
`

export const UserActivityEdgeNoNestingFragment = gql`
fragment UserActivityEdgeNoNesting on UserActivityEdge {
  cursor
}
`

export const UserClientAppBubbleDtoNoNestingFragment = gql`
fragment UserClientAppBubbleDtoNoNesting on UserClientAppBubbleDto {
  type
  userId
  amount
}
`

export const UserFollowersEdgeNoNestingFragment = gql`
fragment UserFollowersEdgeNoNesting on UserFollowersEdge {
  cursor
}
`

export const UserFollowingEdgeNoNestingFragment = gql`
fragment UserFollowingEdgeNoNesting on UserFollowingEdge {
  cursor
}
`

export const UserFollowInfoDtoNoNestingFragment = gql`
fragment UserFollowInfoDtoNoNesting on UserFollowInfoDto {
  userId
  followers
  following
}
`

export const UserPasswordValidityResultDtoNoNestingFragment = gql`
fragment UserPasswordValidityResultDtoNoNesting on UserPasswordValidityResultDto {
  result
}
`

export const VapidKeyNoNestingFragment = gql`
fragment VapidKeyNoNesting on VapidKey {
  pubkey
}
`

export const UserSessionDtoNoNestingFragment = gql`
fragment UserSessionDtoNoNesting on UserSessionDto {
  active
  userId
  createdAt
}
`

export const UserSettingsMicrosoftIntegrationStatusDtoNoNestingFragment = gql`
fragment UserSettingsMicrosoftIntegrationStatusDtoNoNesting on UserSettingsMicrosoftIntegrationStatusDto {
  status
  lastAccess
}
`

export const UserSettingsDtoNoNestingFragment = gql`
fragment UserSettingsDtoNoNesting on UserSettingsDto {
  userId
  timezone
  autoApplyTimezone
  optInPromotionalEmails
  language
  dateFormat
  timeFormat
  firstWeekday
  decimalFormat
  measurementSystem
}
`

export const UserTrashEdgeDtoNoNestingFragment = gql`
fragment UserTrashEdgeDtoNoNesting on UserTrashEdgeDto {
  cursor
}
`

export const UserTrashDtoNoNestingFragment = gql`
fragment UserTrashDtoNoNesting on UserTrashDto {
  id
  createdAt
  deletedByUserId
  blabItemId
  blabDefId
}
`

export const ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment = gql`
fragment ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNesting on ActiveUserGlobalWorkflowRunsFilteringSettingsDto {
  statuses
  blabDefIds
  workflowDefIds
  searchQuery
}
`

export const OrganizationWorkflowStatusDtoNoNestingFragment = gql`
fragment OrganizationWorkflowStatusDtoNoNesting on OrganizationWorkflowStatusDto {
  organizationIsThrottled
  organizationIsDisabled
}
`

export const WorkflowDefLookupDtoNoNestingFragment = gql`
fragment WorkflowDefLookupDtoNoNesting on WorkflowDefLookupDto {
  workflowDefId
}
`

export const WorkflowDefBlabDefLookupDtoNoNestingFragment = gql`
fragment WorkflowDefBlabDefLookupDtoNoNesting on WorkflowDefBlabDefLookupDto {
  id
  slug
  position
  icon
  iconv2
  itemIcon
  type
  blabName
  itemName
  workspaceId
  userHasPermission
}
`

export const WorkflowDefWorkspaceLookupDtoNoNestingFragment = gql`
fragment WorkflowDefWorkspaceLookupDtoNoNesting on WorkflowDefWorkspaceLookupDto {
  id
  slug
  position
  icon
  name
  organizationId
}
`

export const WorkflowDefLookupBlabViewDtoNoNestingFragment = gql`
fragment WorkflowDefLookupBlabViewDtoNoNesting on WorkflowDefLookupBlabViewDto {
  id
  name
  blabDefId
  layout
  isDefault
}
`

export const WorkflowDefLookupBlabDefRelationDtoNoNestingFragment = gql`
fragment WorkflowDefLookupBlabDefRelationDtoNoNesting on WorkflowDefLookupBlabDefRelationDto {
  sourceBlabDefId
  targetBlabDefId
  relationFlimDefId
}
`

export const WorkflowDefLookupWorkspaceUserPreviewDtoNoNestingFragment = gql`
fragment WorkflowDefLookupWorkspaceUserPreviewDtoNoNesting on WorkflowDefLookupWorkspaceUserPreviewDto {
  workspaceId
  userId
}
`

export const WorkflowDefLookupWorkflowDefDtoNoNestingFragment = gql`
fragment WorkflowDefLookupWorkflowDefDtoNoNesting on WorkflowDefLookupWorkflowDefDto {
  id
  name
  blabDefId
  triggerType
  triggerDefinition
}
`

export const WorkflowDefLookupIncomingWorkflowDefPreviewDtoNoNestingFragment = gql`
fragment WorkflowDefLookupIncomingWorkflowDefPreviewDtoNoNesting on WorkflowDefLookupIncomingWorkflowDefPreviewDto {
  id
  name
  blabDefId
  activeUserHasPermission
  isDeleted
}
`

export const OauthIntegrationDtoNoNestingFragment = gql`
fragment OauthIntegrationDtoNoNesting on OauthIntegrationDto {
  id
  name
  clientId
  clientSecret
  authorizationUrl
  accessTokenUrl
  resourceOwnerDetailsUrl
  hasValidAccessToken
  accessTokenIssuedAt
}
`

export const WorkflowDefUniverseCallableWorkflowDefDtoNoNestingFragment = gql`
fragment WorkflowDefUniverseCallableWorkflowDefDtoNoNesting on WorkflowDefUniverseCallableWorkflowDefDto {
  id
  name
  blabDefId
  triggerType
  triggerDefinition
}
`

export const WorkflowDefUniverseBlabViewDtoNoNestingFragment = gql`
fragment WorkflowDefUniverseBlabViewDtoNoNesting on WorkflowDefUniverseBlabViewDto {
  id
  name
  position
  blabDefId
  isPublic
  total
  layout
  isDefault
}
`

export const WorkflowDefWithLookupDtoNoNestingFragment = gql`
fragment WorkflowDefWithLookupDtoNoNesting on WorkflowDefWithLookupDto {
  id
  name
  description
  showDescription
  createdAt
  createdByUserId
  lastModifiedAt
  lastModifiedByUserId
  triggerType
  filterDefinition
  actionDefinition
  triggerDefinition
  validationErrors
  isBroken
  isPaused
  savedByUser
  templateSelected
  fullWidth
  blabDefId
  timezone
  webhookUrl
}
`

export const WorkflowWebhookLastPayloadDtoNoNestingFragment = gql`
fragment WorkflowWebhookLastPayloadDtoNoNesting on WorkflowWebhookLastPayloadDto {
  rawPayload
  parsedPayload
  contentType
}
`

export const GlobalWorkflowRunPreviewEdgeNoNestingFragment = gql`
fragment GlobalWorkflowRunPreviewEdgeNoNesting on GlobalWorkflowRunPreviewEdge {
  cursor
}
`

export const GlobalWorkflowRunPreviewDtoNoNestingFragment = gql`
fragment GlobalWorkflowRunPreviewDtoNoNesting on GlobalWorkflowRunPreviewDto {
  id
  workflowDefId
  workflowDefRevisionId
  blabDefId
  type
  status
  failureReason
  numActionsUsed
  createdAt
  completedAt
  previewForActionId
}
`

export const ExportGlobalWorkflowRunsResultDtoNoNestingFragment = gql`
fragment ExportGlobalWorkflowRunsResultDtoNoNesting on ExportGlobalWorkflowRunsResultDto {
  numRuns
  size
  filename
  downloadUrl
}
`

export const WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment = gql`
fragment WorkflowCenterSearchWorkflowPreviewDtoNoNesting on WorkflowCenterSearchWorkflowPreviewDto {
  id
  blabDefId
  name
  description
  isPaused
  isBroken
  timezone
  triggerType
  createdAt
  createdByUserId
  lastModifiedAt
  lastModifiedByUserId
}
`

export const GlobalWorkflowRunDetailDtoNoNestingFragment = gql`
fragment GlobalWorkflowRunDetailDtoNoNesting on GlobalWorkflowRunDetailDto {
  id
  workflowDefId
  workflowDefRevisionId
  blabDefId
  triggeredOnBlabItemId
  type
  status
  failureReason
  errorMsg
  numActionsUsed
  createdAt
  completedAt
  previewForActionId
}
`

export const WorkflowDefRevisionDtoNoNestingFragment = gql`
fragment WorkflowDefRevisionDtoNoNesting on WorkflowDefRevisionDto {
  id
  name
  triggerType
  filterDefinition
  actionDefinition
  triggerDefinition
  isBroken
  isPaused
  blabDefId
}
`

export const WorkflowRunFiltersSetupFailedLogEventNoNestingFragment = gql`
fragment WorkflowRunFiltersSetupFailedLogEventNoNesting on WorkflowRunFiltersSetupFailedLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  exceptionMessage
}
`

export const WorkflowRunFiltersExecutionFailedLogEventNoNestingFragment = gql`
fragment WorkflowRunFiltersExecutionFailedLogEventNoNesting on WorkflowRunFiltersExecutionFailedLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  exceptionMessage
}
`

export const WorkflowRunFilterUserLogEventNoNestingFragment = gql`
fragment WorkflowRunFilterUserLogEventNoNesting on WorkflowRunFilterUserLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  userMessage
  level
}
`

export const WorkflowRunActionsSetupFailedLogEventNoNestingFragment = gql`
fragment WorkflowRunActionsSetupFailedLogEventNoNesting on WorkflowRunActionsSetupFailedLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  exceptionMessage
}
`

export const WorkflowRunActionSkippingLogEventNoNestingFragment = gql`
fragment WorkflowRunActionSkippingLogEventNoNesting on WorkflowRunActionSkippingLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
}
`

export const WorkflowRunActionStartedLogEventNoNestingFragment = gql`
fragment WorkflowRunActionStartedLogEventNoNesting on WorkflowRunActionStartedLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
}
`

export const WorkflowRunActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunActionSucceededLogEventNoNesting on WorkflowRunActionSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
}
`

export const WorkflowRunActionExecutionSyntaxErrorEventNoNestingFragment = gql`
fragment WorkflowRunActionExecutionSyntaxErrorEventNoNesting on WorkflowRunActionExecutionSyntaxErrorEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  exceptionMessage
}
`

export const WorkflowRunActionFailedLogEventNoNestingFragment = gql`
fragment WorkflowRunActionFailedLogEventNoNesting on WorkflowRunActionFailedLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  exceptionMessage
}
`

export const WorkflowRunActionExceptionCaughtLogEventNoNestingFragment = gql`
fragment WorkflowRunActionExceptionCaughtLogEventNoNesting on WorkflowRunActionExceptionCaughtLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  exceptionMessage
}
`

export const WorkflowRunActionUserLogEventNoNestingFragment = gql`
fragment WorkflowRunActionUserLogEventNoNesting on WorkflowRunActionUserLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  userMessage
  level
}
`

export const WorkflowRunActionDebugLogEventNoNestingFragment = gql`
fragment WorkflowRunActionDebugLogEventNoNesting on WorkflowRunActionDebugLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  debugMessage
  level
}
`

export const WorkflowRunForLoopActionBreakResultLogEventNoNestingFragment = gql`
fragment WorkflowRunForLoopActionBreakResultLogEventNoNesting on WorkflowRunForLoopActionBreakResultLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  booleanResult
}
`

export const WorkflowRunForLoopActionContinueResultLogEventNoNestingFragment = gql`
fragment WorkflowRunForLoopActionContinueResultLogEventNoNesting on WorkflowRunForLoopActionContinueResultLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  booleanResult
}
`

export const WorkflowRunForLoopActionBreakExceptionLogEventNoNestingFragment = gql`
fragment WorkflowRunForLoopActionBreakExceptionLogEventNoNesting on WorkflowRunForLoopActionBreakExceptionLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  exceptionMessage
}
`

export const WorkflowRunForLoopActionContinueExceptionLogEventNoNestingFragment = gql`
fragment WorkflowRunForLoopActionContinueExceptionLogEventNoNesting on WorkflowRunForLoopActionContinueExceptionLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  exceptionMessage
}
`

export const WorkflowRunConditionalActionLogEventNoNestingFragment = gql`
fragment WorkflowRunConditionalActionLogEventNoNesting on WorkflowRunConditionalActionLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  matchConditionResult
}
`

export const WorkflowRunBlabItemCreateActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunBlabItemCreateActionSucceededLogEventNoNesting on WorkflowRunBlabItemCreateActionSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  createdBlabItemId
}
`

export const WorkflowRunBlabItemCommentCreateActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunBlabItemCommentCreateActionSucceededLogEventNoNesting on WorkflowRunBlabItemCommentCreateActionSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  createdBlabItemCommentMessage
}
`

export const WorkflowRunHttpCallActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunHttpCallActionSucceededLogEventNoNesting on WorkflowRunHttpCallActionSucceededLogEvent {
  type
  workflowRunId
  sortIndex
  loggedAt
  actionId
  actionExecutionId
  httpResponseHeaders
  httpResponseBody
  httpResponseCode
}
`

export const WorkflowRunCollectBlabItemsActionSuceededLogEventNoNestingFragment = gql`
fragment WorkflowRunCollectBlabItemsActionSuceededLogEventNoNesting on WorkflowRunCollectBlabItemsActionSuceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  blabDefId
  numCollected
}
`

export const WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEventNoNesting on WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  updatedBlabItemId
}
`

export const WorkflowRunCollectedRecordsCommentCreateSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunCollectedRecordsCommentCreateSucceededLogEventNoNesting on WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  blabItemId
}
`

export const WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEventNoNesting on WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  updatedBlabItemId
}
`

export const WorkflowPreviewRunActionContextOutputLogEventNoNestingFragment = gql`
fragment WorkflowPreviewRunActionContextOutputLogEventNoNesting on WorkflowPreviewRunActionContextOutputLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  contextOutput
}
`

export const WorkflowRunActionEmailSendAttemptLogEventNoNestingFragment = gql`
fragment WorkflowRunActionEmailSendAttemptLogEventNoNesting on WorkflowRunActionEmailSendAttemptLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  from
  subject
  blabItemId
}
`

export const WorkflowRunCreatePdfActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunCreatePdfActionSucceededLogEventNoNesting on WorkflowRunCreatePdfActionSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  filename
  filesize
  downloadLink
}
`

export const WorkflowRunGenerateWeblinkActionSucceededLogEventNoNestingFragment = gql`
fragment WorkflowRunGenerateWeblinkActionSucceededLogEventNoNesting on WorkflowRunGenerateWeblinkActionSucceededLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  weblink
  blabItemId
  calledWorkflowDefId
}
`

export const WorkflowRunCollectedBlabItemsCallWorkfowActionLogEventNoNestingFragment = gql`
fragment WorkflowRunCollectedBlabItemsCallWorkfowActionLogEventNoNesting on WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  calledWorkflowDefId
}
`

export const WorkflowRunCurrentBlabItemCallWorkfowActionLogEventNoNestingFragment = gql`
fragment WorkflowRunCurrentBlabItemCallWorkfowActionLogEventNoNesting on WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent {
  type
  workflowRunId
  loggedAt
  sortIndex
  actionId
  actionExecutionId
  calledWorkflowDefId
}
`

export const WorkflowHomeCurrentInfoDtoNoNestingFragment = gql`
fragment WorkflowHomeCurrentInfoDtoNoNesting on WorkflowHomeCurrentInfoDto {
  numActiveWorkflowDefs
  numRunningWorkflowRuns
  numScheduledWorkflowRuns
  numActionsUsedRecently
}
`

export const WorkflowHomeTimeIntervalInfoDtoNoNestingFragment = gql`
fragment WorkflowHomeTimeIntervalInfoDtoNoNesting on WorkflowHomeTimeIntervalInfoDto {
  interval
  numWorkflowDefs
  numWorkflowRuns
  numActionsUsed
  numActionsTotal
}
`

export const WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDtoNoNestingFragment = gql`
fragment WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDtoNoNesting on WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDto {
  numWorkflowRunsSuccess
  numWorkflowRunsFailed
  numWorkflowRunsCancelled
}
`

export const WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDtoNoNestingFragment = gql`
fragment WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDtoNoNesting on WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDto {
  numWorkflowDefsActive
  numWorkflowDefsPaused
  numWorkflowDefsBroken
}
`

export const WorkflowRunPreviewEdgeNoNestingFragment = gql`
fragment WorkflowRunPreviewEdgeNoNesting on WorkflowRunPreviewEdge {
  cursor
}
`

export const WorkflowRunPreviewDtoNoNestingFragment = gql`
fragment WorkflowRunPreviewDtoNoNesting on WorkflowRunPreviewDto {
  id
  workflowDefId
  workflowDefRevisionId
  type
  status
  failureReason
  numActionsUsed
  createdAt
  completedAt
  previewForActionId
}
`

export const ExportWorkflowRunsOfWorkflowDefResultDtoNoNestingFragment = gql`
fragment ExportWorkflowRunsOfWorkflowDefResultDtoNoNesting on ExportWorkflowRunsOfWorkflowDefResultDto {
  numRuns
  size
  filename
  downloadUrl
}
`

export const WorkflowRunDetailDtoNoNestingFragment = gql`
fragment WorkflowRunDetailDtoNoNesting on WorkflowRunDetailDto {
  id
  workflowDefId
  workflowDefRevisionId
  triggeredOnBlabItemId
  triggeredByWorkflowDefId
  type
  status
  failureReason
  errorMsg
  numActionsUsed
  createdAt
  completedAt
  previewForActionId
}
`

export const WorkflowCenterWebhookDtoNoNestingFragment = gql`
fragment WorkflowCenterWebhookDtoNoNesting on WorkflowCenterWebhookDto {
  id
  url
  position
  active
  webhookType
  organizationId
}
`

export const WorkflowCenterWorkspaceDtoNoNestingFragment = gql`
fragment WorkflowCenterWorkspaceDtoNoNesting on WorkflowCenterWorkspaceDto {
  id
  name
  position
  icon
  iconv2
  organizationId
  slug
}
`

export const WorkflowCenterBlabDefDtoNoNestingFragment = gql`
fragment WorkflowCenterBlabDefDtoNoNesting on WorkflowCenterBlabDefDto {
  id
  blabName
  itemName
  position
  positionV2
  description
  numWorkflows
  icon
  iconv2
  type
  slug
  workspaceId
}
`

export const WorkflowCenterBlabDefNestedWorkspaceDtoNoNestingFragment = gql`
fragment WorkflowCenterBlabDefNestedWorkspaceDtoNoNesting on WorkflowCenterBlabDefNestedWorkspaceDto {
  id
  name
  position
  slug
  organizationId
  icon
  iconv2
}
`

export const WorkflowCenterWorkflowPreviewDtoNoNestingFragment = gql`
fragment WorkflowCenterWorkflowPreviewDtoNoNesting on WorkflowCenterWorkflowPreviewDto {
  id
  blabDefId
  name
  description
  isPaused
  isBroken
  timezone
  triggerType
  createdAt
  createdByUserId
  lastModifiedAt
  lastModifiedByUserId
}
`

export const WorkspaceBlabDefEdgeDtoNoNestingFragment = gql`
fragment WorkspaceBlabDefEdgeDtoNoNesting on WorkspaceBlabDefEdgeDto {
  cursor
}
`

export const WorkspaceBlabDefDtoNoNestingFragment = gql`
fragment WorkspaceBlabDefDtoNoNesting on WorkspaceBlabDefDto {
  id
  blabName
  itemName
  slug
  icon
  archived
  workspaceId
  numBlabItems
  createdAt
  position
  blabItemIdSequence
}
`

export const WorkspaceMembershipEdgeDtoNoNestingFragment = gql`
fragment WorkspaceMembershipEdgeDtoNoNesting on WorkspaceMembershipEdgeDto {
  cursor
}
`

export const WorkspaceUserDtoNoNestingFragment = gql`
fragment WorkspaceUserDtoNoNesting on WorkspaceUserDto {
  userId
  workspaceId
  primaryName
  secondaryName
  email
  role
  deactivated
  explicitRole
}
`

export const WorkspaceInvitationDtoNoNestingFragment = gql`
fragment WorkspaceInvitationDtoNoNesting on WorkspaceInvitationDto {
  role
  workspaceId
  organizationInvitationId
  email
  status
  createdAt
}
`

export const WorkspaceSettingsDtoNoNestingFragment = gql`
fragment WorkspaceSettingsDtoNoNesting on WorkspaceSettingsDto {
  workspaceId
  name
  slug
  type
  icon
  description
}
`

export const WorkspaceShareSettingsDtoNoNestingFragment = gql`
fragment WorkspaceShareSettingsDtoNoNesting on WorkspaceShareSettingsDto {
  workspaceId
  enabled
  allowDuplication
  token
  organizationId
  workspaceType
  workspaceDefaultRole
  numOrganizationUsers
}
`

export const SharedWorkspaceDtoNoNestingFragment = gql`
fragment SharedWorkspaceDtoNoNesting on SharedWorkspaceDto {
  id
  name
  description
  icon
}
`

export const WorkspaceTemplateDtoNoNestingFragment = gql`
fragment WorkspaceTemplateDtoNoNesting on WorkspaceTemplateDto {
  id
  templateDescription
  templateName
  templateSubtitle
  language
  publicationDate
  previewImageThumbnailId
  titleImageThumbnailId
  image3ThumbnailId
  image4ThumbnailId
  downloadCount
  templateShareLink
}
`

export const WorkspaceTemplatePublishInfoDtoNoNestingFragment = gql`
fragment WorkspaceTemplatePublishInfoDtoNoNesting on WorkspaceTemplatePublishInfoDto {
  id
  workspaceId
  templateSubtitle
  templateDescription
  templateName
  language
  downloadCountOffset
  realDownloadCount
  previewImageThumbnailId
  titleImageThumbnailId
  image3ThumbnailId
  image4ThumbnailId
  templateShareLink
  publicationDate
}
`

export const ChatActivityDtoEdgeNoNestingFragment = gql`
fragment ChatActivityDtoEdgeNoNesting on ChatActivityDtoEdge {
  cursor
}
`

export const ChatDialogDtoNoNestingFragment = gql`
fragment ChatDialogDtoNoNesting on ChatDialogDto {
  type
  conversationId
  userReadUntil
  mutedByUser
  numUnreadActivities
}
`

export const ChatGroupUserDtoEdgeNoNestingFragment = gql`
fragment ChatGroupUserDtoEdgeNoNesting on ChatGroupUserDtoEdge {
  cursor
}
`

export const ConversationDtoEdgeNoNestingFragment = gql`
fragment ConversationDtoEdgeNoNesting on ConversationDtoEdge {
  cursor
}
`

export const FocusEntryEdgeNoNestingFragment = gql`
fragment FocusEntryEdgeNoNesting on FocusEntryEdge {
  cursor
}
`

export const ReminderFocusEntryDtoNoNestingFragment = gql`
fragment ReminderFocusEntryDtoNoNesting on ReminderFocusEntryDto {
  id
  type
  position
  sectionId
}
`

export const ChecklistFocusEntryDtoNoNestingFragment = gql`
fragment ChecklistFocusEntryDtoNoNesting on ChecklistFocusEntryDto {
  id
  type
  position
  sectionId
  blabDefHasMultipleChecklistFlimDefs
  flimDefName
}
`

export const ChecklistFlimValEntryFocusPreviewDtoNoNestingFragment = gql`
fragment ChecklistFlimValEntryFocusPreviewDtoNoNesting on ChecklistFlimValEntryFocusPreviewDto {
  id
  blabItemId
  flimDefId
  position
  completed
  title
  description
  dueAt
  dueAtHasTime
  assigneeUserId
}
`

export const BlabItemFocusEntryDtoNoNestingFragment = gql`
fragment BlabItemFocusEntryDtoNoNesting on BlabItemFocusEntryDto {
  id
  type
  position
  sectionId
}
`

export const BlabItemFocusPreviewDtoNoNestingFragment = gql`
fragment BlabItemFocusPreviewDtoNoNesting on BlabItemFocusPreviewDto {
  id
  title
  blabDefId
  hasFocusDueAt
  requireFocusDueAt
  hasFocusDueAtEnd
  requireFocusDueAtEnd
  focusDueAtTimeSetting
  dueAt
  dueAtEnd
  dueAtHasTime
  selectedStatusOptionDefId
  numOpenChecklistEntries
}
`

export const CalculationScriptMultiTextResultPreviewDtoNoNestingFragment = gql`
fragment CalculationScriptMultiTextResultPreviewDtoNoNesting on CalculationScriptMultiTextResultPreviewDto {
  resultType
  value
  unformatted
}
`

export const CalculationScriptNumberResultPreviewDtoNoNestingFragment = gql`
fragment CalculationScriptNumberResultPreviewDtoNoNesting on CalculationScriptNumberResultPreviewDto {
  resultType
  decimal
}
`

export const CalculationScriptSingleDateResultPreviewDtoNoNestingFragment = gql`
fragment CalculationScriptSingleDateResultPreviewDtoNoNesting on CalculationScriptSingleDateResultPreviewDto {
  resultType
  date
  hasTime
}
`

export const GetCalculationScriptResultPreviewRejectionDtoNoNestingFragment = gql`
fragment GetCalculationScriptResultPreviewRejectionDtoNoNesting on GetCalculationScriptResultPreviewRejectionDto {
  reason
  errorMsg
}
`

export const _testJobQueueDtoNoNestingFragment = gql`
fragment _testJobQueueDtoNoNesting on _testJobQueueDto {
  job
}
`

export const JoinAudioRoomResultDtoNoNestingFragment = gql`
fragment JoinAudioRoomResultDtoNoNesting on JoinAudioRoomResultDto {
  roomId
}
`

export const JoinAudioRoomTransportOptionsNoNestingFragment = gql`
fragment JoinAudioRoomTransportOptionsNoNesting on JoinAudioRoomTransportOptions {
  roomId
  receiveTransportOptions
  sendTransportOptions
  routerRtpCapabilities
}
`

export const AudioRoomProducerOptionsNoNestingFragment = gql`
fragment AudioRoomProducerOptionsNoNesting on AudioRoomProducerOptions {
  producerId
}
`

export const AudioRoomConsumerOptionsNoNestingFragment = gql`
fragment AudioRoomConsumerOptionsNoNesting on AudioRoomConsumerOptions {
  userId
  roomId
  consumerOptions
}
`

export const ReportActiveUserAudioRoomPresenceDtoNoNestingFragment = gql`
fragment ReportActiveUserAudioRoomPresenceDtoNoNesting on ReportActiveUserAudioRoomPresenceDto {
  result
}
`

export const AudioRoomDetailDtoNoNestingFragment = gql`
fragment AudioRoomDetailDtoNoNesting on AudioRoomDetailDto {
  id
  name
  authorId
}
`

export const AudioRoomParticipantDetailDtoNoNestingFragment = gql`
fragment AudioRoomParticipantDetailDtoNoNesting on AudioRoomParticipantDetailDto {
  userId
  roomId
  muted
  producerId
}
`

export const ExportBlabDefDataResultDtoNoNestingFragment = gql`
fragment ExportBlabDefDataResultDtoNoNesting on ExportBlabDefDataResultDto {
  dataExportId
}
`

export const ImportBlabDefDataToExistingBlabDefResultDtoNoNestingFragment = gql`
fragment ImportBlabDefDataToExistingBlabDefResultDtoNoNesting on ImportBlabDefDataToExistingBlabDefResultDto {
  jobId
}
`

export const ImportBlabDefDataToNewBlabDefResultDtoNoNestingFragment = gql`
fragment ImportBlabDefDataToNewBlabDefResultDtoNoNesting on ImportBlabDefDataToNewBlabDefResultDto {
  jobId
}
`

export const UpdateBlabDefWebhookResultDtoNoNestingFragment = gql`
fragment UpdateBlabDefWebhookResultDtoNoNesting on UpdateBlabDefWebhookResultDto {
  verificationRequested
}
`

export const DuplicateBlabDefResultDtoNoNestingFragment = gql`
fragment DuplicateBlabDefResultDtoNoNesting on DuplicateBlabDefResultDto {
  jobId
}
`

export const DeleteBlabItemsInfoNoNestingFragment = gql`
fragment DeleteBlabItemsInfoNoNesting on DeleteBlabItemsInfo {
  numDeleted
}
`

export const UpdateBlabItemsFlimValResponseNoNestingFragment = gql`
fragment UpdateBlabItemsFlimValResponseNoNesting on UpdateBlabItemsFlimValResponse {
  jobId
}
`

export const BulkFollowBlabItemsResponseNoNestingFragment = gql`
fragment BulkFollowBlabItemsResponseNoNesting on BulkFollowBlabItemsResponse {
  jobId
}
`

export const ChatVideoCallActivityDtoNoNestingFragment = gql`
fragment ChatVideoCallActivityDtoNoNesting on ChatVideoCallActivityDto {
  id
  type
  createdAt
  userId
  conversationId
}
`

export const VideoCallDtoNoNestingFragment = gql`
fragment VideoCallDtoNoNesting on VideoCallDto {
  videoCallUrl
}
`

export const MicrosoftUserAuthenticationRequiredDtoNoNestingFragment = gql`
fragment MicrosoftUserAuthenticationRequiredDtoNoNesting on MicrosoftUserAuthenticationRequiredDto {
  authenticationUrl
}
`

export const FindMyOrgUserHasPendingInvitationDtoNoNestingFragment = gql`
fragment FindMyOrgUserHasPendingInvitationDtoNoNesting on FindMyOrgUserHasPendingInvitationDto {
  acceptInvitationToken
}
`

export const AuthenticateWithFindMyOrgsTokenRejectionDtoNoNestingFragment = gql`
fragment AuthenticateWithFindMyOrgsTokenRejectionDtoNoNesting on AuthenticateWithFindMyOrgsTokenRejectionDto {
  reason
}
`

export const AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDtoNoNestingFragment = gql`
fragment AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDtoNoNesting on AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto {
  userId
  jwt
}
`

export const FocusSectionDtoNoNestingFragment = gql`
fragment FocusSectionDtoNoNesting on FocusSectionDto {
  id
  name
  position
  expanded
  isDefault
  initialFocusSectionType
}
`

export const MoveAllFocusSectionEntriesResultDtoNoNestingFragment = gql`
fragment MoveAllFocusSectionEntriesResultDtoNoNesting on MoveAllFocusSectionEntriesResultDto {
  numEntriesMoved
}
`

export const MarkAllNotificationsAsReadResultDtoNoNestingFragment = gql`
fragment MarkAllNotificationsAsReadResultDtoNoNesting on MarkAllNotificationsAsReadResultDto {
  numAffected
}
`

export const CopyOrganizationInvitationLinkResultDtoNoNestingFragment = gql`
fragment CopyOrganizationInvitationLinkResultDtoNoNesting on CopyOrganizationInvitationLinkResultDto {
  link
}
`

export const UpdateOrganizationSlugResultDtoNoNestingFragment = gql`
fragment UpdateOrganizationSlugResultDtoNoNesting on UpdateOrganizationSlugResultDto {
  type
  slug
}
`

export const OrganizationAuthenticationProviderRejectionDtoNoNestingFragment = gql`
fragment OrganizationAuthenticationProviderRejectionDtoNoNesting on OrganizationAuthenticationProviderRejectionDto {
  rejectionReason
  errorMessage
}
`

export const OrganizationSmtpSettingsRejectionDtoNoNestingFragment = gql`
fragment OrganizationSmtpSettingsRejectionDtoNoNesting on OrganizationSmtpSettingsRejectionDto {
  rejectionReason
  errorMessage
}
`

export const OrganizationSignupVerificationSessionDtoNoNestingFragment = gql`
fragment OrganizationSignupVerificationSessionDtoNoNesting on OrganizationSignupVerificationSessionDto {
  id
}
`

export const ValidateOrganizationSignupVerificationCodeResponseNoNestingFragment = gql`
fragment ValidateOrganizationSignupVerificationCodeResponseNoNesting on ValidateOrganizationSignupVerificationCodeResponse {
  result
}
`

export const DeleteOrganizationWorkspaceResultDtoNoNestingFragment = gql`
fragment DeleteOrganizationWorkspaceResultDtoNoNesting on DeleteOrganizationWorkspaceResultDto {
  jobId
}
`

export const ArchiveAllCompletedRemindersResultDtoNoNestingFragment = gql`
fragment ArchiveAllCompletedRemindersResultDtoNoNesting on ArchiveAllCompletedRemindersResultDto {
  numArchived
}
`

export const SpreadsheetImportFlimDefCadidatesDtoNoNestingFragment = gql`
fragment SpreadsheetImportFlimDefCadidatesDtoNoNesting on SpreadsheetImportFlimDefCadidatesDto {
  worksheetId
}
`

export const SpreadsheetColumnFlimDefCandidatesDtoNoNestingFragment = gql`
fragment SpreadsheetColumnFlimDefCandidatesDtoNoNesting on SpreadsheetColumnFlimDefCandidatesDto {
  suggestedFlimType
  flimDefName
  spreadsheetColumnKey
}
`

export const AuthenticateWithCredentialsSuccessMfaRequiredDtoNoNestingFragment = gql`
fragment AuthenticateWithCredentialsSuccessMfaRequiredDtoNoNesting on AuthenticateWithCredentialsSuccessMfaRequiredDto {
  userId
  jwt
}
`

export const AuthenticateWithCredentialsRejectionDtoNoNestingFragment = gql`
fragment AuthenticateWithCredentialsRejectionDtoNoNesting on AuthenticateWithCredentialsRejectionDto {
  reason
}
`

export const ChangeUserEmailVerificationSessionDtoNoNestingFragment = gql`
fragment ChangeUserEmailVerificationSessionDtoNoNesting on ChangeUserEmailVerificationSessionDto {
  id
}
`

export const RequestChangeUserEmailVerificationRejectionDtoNoNestingFragment = gql`
fragment RequestChangeUserEmailVerificationRejectionDtoNoNesting on RequestChangeUserEmailVerificationRejectionDto {
  result
}
`

export const PerformChangeUserEmailResultNoNestingFragment = gql`
fragment PerformChangeUserEmailResultNoNesting on PerformChangeUserEmailResult {
  result
}
`

export const ChangeUserPasswordResultDtoNoNestingFragment = gql`
fragment ChangeUserPasswordResultDtoNoNesting on ChangeUserPasswordResultDto {
  result
}
`

export const EmptyUserTrashResultDtoNoNestingFragment = gql`
fragment EmptyUserTrashResultDtoNoNesting on EmptyUserTrashResultDto {
  numRemoved
}
`

export const GenerateWorkflowDefCodeResultDtoNoNestingFragment = gql`
fragment GenerateWorkflowDefCodeResultDtoNoNesting on GenerateWorkflowDefCodeResultDto {
  typeScript
  javaScript
  vars
}
`

export const WorkflowDefDtoNoNestingFragment = gql`
fragment WorkflowDefDtoNoNesting on WorkflowDefDto {
  id
  name
  description
  showDescription
  createdAt
  createdByUserId
  lastModifiedAt
  lastModifiedByUserId
  timezone
  triggerType
  filterDefinition
  actionDefinition
  triggerDefinition
  isBroken
  isPaused
  savedByUser
  templateSelected
  fullWidth
  blabDefId
  webhookUrl
}
`

export const ManualRunsTriggeredDtoNoNestingFragment = gql`
fragment ManualRunsTriggeredDtoNoNesting on ManualRunsTriggeredDto {
  manualRunsTriggered
}
`

export const CancelWorkflowRunsResultNoNestingFragment = gql`
fragment CancelWorkflowRunsResultNoNesting on CancelWorkflowRunsResult {
  numCancelledRuns
}
`

export const UpdateWorkflowCenterWebhookResultDtoNoNestingFragment = gql`
fragment UpdateWorkflowCenterWebhookResultDtoNoNesting on UpdateWorkflowCenterWebhookResultDto {
  verificationRequested
}
`

export const RemoveWorkspaceMembershipRejectedNoNestingFragment = gql`
fragment RemoveWorkspaceMembershipRejectedNoNesting on RemoveWorkspaceMembershipRejected {
  rejectionReason
}
`

export const UpdateWorkspaceSlugResultDtoNoNestingFragment = gql`
fragment UpdateWorkspaceSlugResultDtoNoNesting on UpdateWorkspaceSlugResultDto {
  type
  slug
}
`

export const DuplicateSharedWorkspaceResultDtoNoNestingFragment = gql`
fragment DuplicateSharedWorkspaceResultDtoNoNesting on DuplicateSharedWorkspaceResultDto {
  rejectionReason
}
`

export const ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNestingFragment = gql`
fragment ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNesting on ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto {
  flimDefId
}
`

export const ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNesting on ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto {
  flimDefId
}
`

export const ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNestingFragment = gql`
fragment ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNesting on ConvertMultiCategoryFlimToSingleCategoryFlimResultDto {
  flimDefId
}
`

export const ConvertSingleCategoryFlimToStatusFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleCategoryFlimToStatusFlimResultDtoNoNesting on ConvertSingleCategoryFlimToStatusFlimResultDto {
  flimDefId
}
`

export const ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNesting on ConvertSingleCategoryFlimToSingleTextFlimResultDto {
  flimDefId
}
`

export const ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNesting on ConvertSingleCategoryFlimToMultiCategoryFlimResultDto {
  flimDefId
}
`

export const ConvertRangeDateFlimToSingleDateFlimResultDtoNoNestingFragment = gql`
fragment ConvertRangeDateFlimToSingleDateFlimResultDtoNoNesting on ConvertRangeDateFlimToSingleDateFlimResultDto {
  flimDefId
}
`

export const ConvertSingleDateFlimToRangeDateFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleDateFlimToRangeDateFlimResultDtoNoNesting on ConvertSingleDateFlimToRangeDateFlimResultDto {
  flimDefId
}
`

export const ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNestingFragment = gql`
fragment ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNesting on ConvertMultiRelationFlimToSingleRelationFlimResultDto {
  flimDefId
}
`

export const ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNesting on ConvertSingleRelationFlimToMultiRelationFlimResultDto {
  flimDefId
}
`

export const ConvertMultiTextFlimToSingleTextFlimResultDtoNoNestingFragment = gql`
fragment ConvertMultiTextFlimToSingleTextFlimResultDtoNoNesting on ConvertMultiTextFlimToSingleTextFlimResultDto {
  flimDefId
}
`

export const ConvertSingleTextFlimToMultiTextFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleTextFlimToMultiTextFlimResultDtoNoNesting on ConvertSingleTextFlimToMultiTextFlimResultDto {
  flimDefId
}
`

export const ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNesting on ConvertSingleTextFlimToSingleCategoryFlimResultDto {
  flimDefId
}
`

export const ConvertSingleTextFlimToNumberFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleTextFlimToNumberFlimResultDtoNoNesting on ConvertSingleTextFlimToNumberFlimResultDto {
  flimDefId
}
`

export const ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNesting on ConvertSingleTextFlimToMultiLinkFlimResultDto {
  flimDefId
}
`

export const ConvertMultiUserFlimToSingleUserFlimResultDtoNoNestingFragment = gql`
fragment ConvertMultiUserFlimToSingleUserFlimResultDtoNoNesting on ConvertMultiUserFlimToSingleUserFlimResultDto {
  flimDefId
}
`

export const ConvertSingleUserFlimToMultiUserFlimResultDtoNoNestingFragment = gql`
fragment ConvertSingleUserFlimToMultiUserFlimResultDtoNoNesting on ConvertSingleUserFlimToMultiUserFlimResultDto {
  flimDefId
}
`

export const MultiAttachmentFlimValDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimValDtoNoNesting on MultiAttachmentFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const MultiAttachmentFlimValFileReferenceDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimValFileReferenceDtoNoNesting on MultiAttachmentFlimValFileReferenceDto {
  blabItemId
  flimDefId
  attachmentId
  position
  cardThumbnailPositionX
  cardThumbnailPositionY
}
`

export const SingleAttachmentFlimValDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimValDtoNoNesting on SingleAttachmentFlimValDto {
  type
  blabItemId
  flimDefId
  attachmentId
  cardThumbnailPositionX
  cardThumbnailPositionY
}
`

export const MultiCategoryFlimValDtoNoNestingFragment = gql`
fragment MultiCategoryFlimValDtoNoNesting on MultiCategoryFlimValDto {
  type
  blabItemId
  flimDefId
  categoryOptionDefIds
}
`

export const SingleCategoryFlimValDtoNoNestingFragment = gql`
fragment SingleCategoryFlimValDtoNoNesting on SingleCategoryFlimValDto {
  type
  blabItemId
  flimDefId
  categoryOptionDefId
}
`

export const RangeDateFlimValDtoNoNestingFragment = gql`
fragment RangeDateFlimValDtoNoNesting on RangeDateFlimValDto {
  type
  blabItemId
  flimDefId
  startDate
  hasTime
  endDate
}
`

export const SingleDateFlimValDtoNoNestingFragment = gql`
fragment SingleDateFlimValDtoNoNesting on SingleDateFlimValDto {
  type
  blabItemId
  flimDefId
  date
  hasTime
}
`

export const MultiEmailFlimValEntryDtoNoNestingFragment = gql`
fragment MultiEmailFlimValEntryDtoNoNesting on MultiEmailFlimValEntryDto {
  id
  blabItemId
  flimDefId
  position
  emailAddress
  emailAddressType
}
`

export const MultiImageFlimValDtoNoNestingFragment = gql`
fragment MultiImageFlimValDtoNoNesting on MultiImageFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const MultiImageFlimValFileReferenceDtoNoNestingFragment = gql`
fragment MultiImageFlimValFileReferenceDtoNoNesting on MultiImageFlimValFileReferenceDto {
  blabItemId
  flimDefId
  imageId
  position
  cardThumbnailPositionX
  cardThumbnailPositionY
}
`

export const SingleLocationFlimValDtoNoNestingFragment = gql`
fragment SingleLocationFlimValDtoNoNesting on SingleLocationFlimValDto {
  type
  blabItemId
  flimDefId
  originalFormattedAddress
  originalAddressModified
  city
  country
  state
  postalCode
  streetAddress
  mapEmbedUrl
  googleMapsPlaceId
  hideMapEmbed
  locationId
  latitude
  longitude
}
`

export const NumberFlimValDtoNoNestingFragment = gql`
fragment NumberFlimValDtoNoNesting on NumberFlimValDto {
  type
  blabItemId
  flimDefId
  decimal
}
`

export const MultiPhoneFlimValEntryDtoNoNestingFragment = gql`
fragment MultiPhoneFlimValEntryDtoNoNesting on MultiPhoneFlimValEntryDto {
  id
  blabItemId
  flimDefId
  position
  phoneNumber
  phoneNumberType
}
`

export const MultiRelationFlimValDtoNoNestingFragment = gql`
fragment MultiRelationFlimValDtoNoNesting on MultiRelationFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const BlabItemReferenceDtoNoNestingFragment = gql`
fragment BlabItemReferenceDtoNoNesting on BlabItemReferenceDto {
  blabItemId
  position
}
`

export const SingleRelationFlimValDtoNoNestingFragment = gql`
fragment SingleRelationFlimValDtoNoNesting on SingleRelationFlimValDto {
  type
  blabItemId
  flimDefId
  referencedBlabItemId
}
`

export const StatusFlimValDtoNoNestingFragment = gql`
fragment StatusFlimValDtoNoNesting on StatusFlimValDto {
  type
  blabItemId
  flimDefId
  statusOptionDefId
}
`

export const MultiTextFlimValDtoNoNestingFragment = gql`
fragment MultiTextFlimValDtoNoNesting on MultiTextFlimValDto {
  type
  blabItemId
  flimDefId
  value
  unformatted
}
`

export const SingleTextFlimValDtoNoNestingFragment = gql`
fragment SingleTextFlimValDtoNoNesting on SingleTextFlimValDto {
  type
  blabItemId
  flimDefId
  value
}
`

export const MultiUserFlimValDtoNoNestingFragment = gql`
fragment MultiUserFlimValDtoNoNesting on MultiUserFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const UserReferenceDtoNoNestingFragment = gql`
fragment UserReferenceDtoNoNesting on UserReferenceDto {
  userId
  position
}
`

export const SingleUserFlimValDtoNoNestingFragment = gql`
fragment SingleUserFlimValDtoNoNesting on SingleUserFlimValDto {
  type
  blabItemId
  flimDefId
  userId
}
`

export const ActiveUserChangesSubscriptionEventNoNestingFragment = gql`
fragment ActiveUserChangesSubscriptionEventNoNesting on ActiveUserChangesSubscriptionEvent {
  payload
}
`

export const SubscribeToExportBlabDefDataJobProgressEventNoNestingFragment = gql`
fragment SubscribeToExportBlabDefDataJobProgressEventNoNesting on SubscribeToExportBlabDefDataJobProgressEvent {
  dataExportId
  progress
}
`

export const SubscribeToExportBlabDefDataJobSuccessEventNoNestingFragment = gql`
fragment SubscribeToExportBlabDefDataJobSuccessEventNoNesting on SubscribeToExportBlabDefDataJobSuccessEvent {
  dataExportId
  downloadUrl
  name
  numExportedBlabItemRows
}
`

export const SubscribeToExportBlabDefDataJobFailureEventNoNestingFragment = gql`
fragment SubscribeToExportBlabDefDataJobFailureEventNoNesting on SubscribeToExportBlabDefDataJobFailureEvent {
  dataExportId
  errorType
}
`

export const SubscribeToImportBlabDefDataJobProgressEventNoNestingFragment = gql`
fragment SubscribeToImportBlabDefDataJobProgressEventNoNesting on SubscribeToImportBlabDefDataJobProgressEvent {
  jobId
  progress
}
`

export const SubscribeToImportBlabDefDataJobSuccessEventNoNestingFragment = gql`
fragment SubscribeToImportBlabDefDataJobSuccessEventNoNesting on SubscribeToImportBlabDefDataJobSuccessEvent {
  jobId
  numImportedBlabItemRows
}
`

export const SubscribeToImportBlabDefDataJobFailureEventNoNestingFragment = gql`
fragment SubscribeToImportBlabDefDataJobFailureEventNoNesting on SubscribeToImportBlabDefDataJobFailureEvent {
  jobId
}
`

export const BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDtoNoNestingFragment = gql`
fragment BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDtoNoNesting on BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDto {
  type
  blabDefId
}
`

export const SubscribeToBlabDefDuplicationJobSuccessEventNoNestingFragment = gql`
fragment SubscribeToBlabDefDuplicationJobSuccessEventNoNesting on SubscribeToBlabDefDuplicationJobSuccessEvent {
  jobId
}
`

export const SubscribeToBlabDefDuplicationJobFailureEventNoNestingFragment = gql`
fragment SubscribeToBlabDefDuplicationJobFailureEventNoNesting on SubscribeToBlabDefDuplicationJobFailureEvent {
  jobId
  errorMsg
}
`

export const SubscribeToUpdateBlabItemsFlimValSuccessEventNoNestingFragment = gql`
fragment SubscribeToUpdateBlabItemsFlimValSuccessEventNoNesting on SubscribeToUpdateBlabItemsFlimValSuccessEvent {
  jobId
  success
}
`

export const SubscribeToUpdateBlabItemsFlimValFailureEventNoNestingFragment = gql`
fragment SubscribeToUpdateBlabItemsFlimValFailureEventNoNesting on SubscribeToUpdateBlabItemsFlimValFailureEvent {
  jobId
  success
}
`

export const BlabItemActivityDeletedDtoNoNestingFragment = gql`
fragment BlabItemActivityDeletedDtoNoNesting on BlabItemActivityDeletedDto {
  id
}
`

export const BlabItemActivityReplyDeletedDtoNoNestingFragment = gql`
fragment BlabItemActivityReplyDeletedDtoNoNesting on BlabItemActivityReplyDeletedDto {
  id
}
`

export const SubscribeToBulkFollowUnfollowBlabItemsSuccessEventNoNestingFragment = gql`
fragment SubscribeToBulkFollowUnfollowBlabItemsSuccessEventNoNesting on SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent {
  jobId
  success
}
`

export const SubscribeToBulkFollowUnfollowBlabItemsFailureEventNoNestingFragment = gql`
fragment SubscribeToBulkFollowUnfollowBlabItemsFailureEventNoNesting on SubscribeToBulkFollowUnfollowBlabItemsFailureEvent {
  jobId
  success
}
`

export const ChatActivityClientApplicationBrowserNotificationDtoNoNestingFragment = gql`
fragment ChatActivityClientApplicationBrowserNotificationDtoNoNesting on ChatActivityClientApplicationBrowserNotificationDto {
  title
  body
  imageUrl
  createdAt
  conversationId
}
`

export const NotificationClientApplicationBrowserNotificationDtoNoNestingFragment = gql`
fragment NotificationClientApplicationBrowserNotificationDtoNoNesting on NotificationClientApplicationBrowserNotificationDto {
  _void
}
`

export const SubscribeToOauthIntegrationAuthenticationStatusSuccessEventNoNestingFragment = gql`
fragment SubscribeToOauthIntegrationAuthenticationStatusSuccessEventNoNesting on SubscribeToOauthIntegrationAuthenticationStatusSuccessEvent {
  integrationId
}
`

export const SubscribeToOauthIntegrationAuthenticationStatusFailureEventNoNestingFragment = gql`
fragment SubscribeToOauthIntegrationAuthenticationStatusFailureEventNoNesting on SubscribeToOauthIntegrationAuthenticationStatusFailureEvent {
  integrationId
  errorMsg
}
`

export const OrganizationChangesSubscriptionEventNoNestingFragment = gql`
fragment OrganizationChangesSubscriptionEventNoNesting on OrganizationChangesSubscriptionEvent {
  payload
}
`

export const ClientAppOrganizationsChangesSubscriptionEventNoNestingFragment = gql`
fragment ClientAppOrganizationsChangesSubscriptionEventNoNesting on ClientAppOrganizationsChangesSubscriptionEvent {
  payload
}
`

export const UserClientApplicationChangesSubscriptionEventNoNestingFragment = gql`
fragment UserClientApplicationChangesSubscriptionEventNoNesting on UserClientApplicationChangesSubscriptionEvent {
  payload
}
`

export const GlobalWorkflowRunsSubscriptionUpdateDtoNoNestingFragment = gql`
fragment GlobalWorkflowRunsSubscriptionUpdateDtoNoNesting on GlobalWorkflowRunsSubscriptionUpdateDto {
  workflowRunId
  status
}
`

export const ManualRunsCompletedDtoNoNestingFragment = gql`
fragment ManualRunsCompletedDtoNoNesting on ManualRunsCompletedDto {
  workflowDefId
  manualRunUuId
}
`

export const WorkflowRunCreatedSubscriptionUpdateNoNestingFragment = gql`
fragment WorkflowRunCreatedSubscriptionUpdateNoNesting on WorkflowRunCreatedSubscriptionUpdate {
  updateType
  workflowRunId
  workflowDefId
  workflowDefRevisionId
  createdAt
  type
  status
  failureReason
  numActionsUsed
  previewForActionId
}
`

export const WorkflowRunLogsAppendedSubscriptionUpdateNoNestingFragment = gql`
fragment WorkflowRunLogsAppendedSubscriptionUpdateNoNesting on WorkflowRunLogsAppendedSubscriptionUpdate {
  updateType
  workflowRunId
  workflowDefId
}
`

export const WorkflowRunUpdatedSubscriptionUpdateNoNestingFragment = gql`
fragment WorkflowRunUpdatedSubscriptionUpdateNoNesting on WorkflowRunUpdatedSubscriptionUpdate {
  updateType
  workflowRunId
  workflowDefId
  type
  status
  failureReason
  numActionsUsed
  previewForActionId
}
`

export const WorkflowCenterWebhookEventDtoNoNestingFragment = gql`
fragment WorkflowCenterWebhookEventDtoNoNesting on WorkflowCenterWebhookEventDto {
  type
}
`

export const SubscribeToWorkspaceDuplicationJobSuccessEventNoNestingFragment = gql`
fragment SubscribeToWorkspaceDuplicationJobSuccessEventNoNesting on SubscribeToWorkspaceDuplicationJobSuccessEvent {
  jobId
}
`

export const SubscribeToWorkspaceDuplicationJobFailureEventNoNestingFragment = gql`
fragment SubscribeToWorkspaceDuplicationJobFailureEventNoNesting on SubscribeToWorkspaceDuplicationJobFailureEvent {
  jobId
  errorMsg
}
`

export const ConversationCreateActivityDtoNoNestingFragment = gql`
fragment ConversationCreateActivityDtoNoNesting on ConversationCreateActivityDto {
  id
  type
  createdAt
  userId
  conversationId
}
`

export const ChatGroupJoinActivityDtoNoNestingFragment = gql`
fragment ChatGroupJoinActivityDtoNoNesting on ChatGroupJoinActivityDto {
  id
  type
  createdAt
  userId
  targetUserId
  conversationId
}
`

export const ChatGroupLeaveActivityDtoNoNestingFragment = gql`
fragment ChatGroupLeaveActivityDtoNoNesting on ChatGroupLeaveActivityDto {
  id
  type
  createdAt
  userId
  conversationId
}
`

export const ChatGroupNameChangeActivityDtoNoNestingFragment = gql`
fragment ChatGroupNameChangeActivityDtoNoNesting on ChatGroupNameChangeActivityDto {
  id
  type
  createdAt
  userId
  content
  conversationId
}
`

export const ChatGroupThumbnailChangeActivityDtoNoNestingFragment = gql`
fragment ChatGroupThumbnailChangeActivityDtoNoNesting on ChatGroupThumbnailChangeActivityDto {
  id
  type
  createdAt
  userId
  conversationId
}
`

export const ChatActivityDeletedDtoNoNestingFragment = gql`
fragment ChatActivityDeletedDtoNoNesting on ChatActivityDeletedDto {
  id
}
`

export const FocusEntryRemovedSubscriptionEventNoNestingFragment = gql`
fragment FocusEntryRemovedSubscriptionEventNoNesting on FocusEntryRemovedSubscriptionEvent {
  id
}
`

export const BlabDefActivityEdgeNoNestingFragment = gql`
fragment BlabDefActivityEdgeNoNesting on BlabDefActivityEdge {
  cursor
}
`

export const BlabDefCreatedActivityDtoNoNestingFragment = gql`
fragment BlabDefCreatedActivityDtoNoNesting on BlabDefCreatedActivityDto {
  id
  blabDefId
  type
  createdAt
  authorId
  numReplies
}
`

export const BulkUnfollowBlabItemsResponseNoNestingFragment = gql`
fragment BulkUnfollowBlabItemsResponseNoNesting on BulkUnfollowBlabItemsResponse {
  jobId
}
`

export const OrganizationDtoNoNestingFragment = gql`
fragment OrganizationDtoNoNesting on OrganizationDto {
  id
  name
  slug
  plan
  numUsers
}
`

export const OrganizationInvitationCreatedDtoNoNestingFragment = gql`
fragment OrganizationInvitationCreatedDtoNoNesting on OrganizationInvitationCreatedDto {
  type
}
`

export const OrganizationInvitationEmailAlreadyInUseDtoNoNestingFragment = gql`
fragment OrganizationInvitationEmailAlreadyInUseDtoNoNesting on OrganizationInvitationEmailAlreadyInUseDto {
  type
  userId
}
`

export const OrganizationInvitationEmailAlreadyInvitedDtoNoNestingFragment = gql`
fragment OrganizationInvitationEmailAlreadyInvitedDtoNoNesting on OrganizationInvitationEmailAlreadyInvitedDto {
  type
}
`

export const OrganizationNotificationActivityDtoNoNestingFragment = gql`
fragment OrganizationNotificationActivityDtoNoNesting on OrganizationNotificationActivityDto {
  notificationId
  direct
}
`

export const OrganizationNotificationDtoNoNestingFragment = gql`
fragment OrganizationNotificationDtoNoNesting on OrganizationNotificationDto {
  id
  type
  read
  updatedAt
  organizationId
  numActivities
}
`

export const UserDtoNoNestingFragment = gql`
fragment UserDtoNoNesting on UserDto {
  id
  name
  email
  timezone
  language
  organizationId
  role
}
`

export const WorkflowExecutionConfigDtoNoNestingFragment = gql`
fragment WorkflowExecutionConfigDtoNoNesting on WorkflowExecutionConfigDto {
  blabItemExecutionType
  webhookExecutionType
  numMostRecentBlabItems
  customWebhookPayload
}
`

export const WorkspaceActivityEdgeNoNestingFragment = gql`
fragment WorkspaceActivityEdgeNoNesting on WorkspaceActivityEdge {
  cursor
}
`

export const MultiLinkFlimValDtoNoNestingFragment = gql`
fragment MultiLinkFlimValDtoNoNesting on MultiLinkFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const ChecklistFlimValDtoNoNestingFragment = gql`
fragment ChecklistFlimValDtoNoNesting on ChecklistFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const CreatedAtFlimValDtoNoNestingFragment = gql`
fragment CreatedAtFlimValDtoNoNesting on CreatedAtFlimValDto {
  type
  blabItemId
  flimDefId
  blabItemCreateDate
}
`

export const CreatedByFlimValDtoNoNestingFragment = gql`
fragment CreatedByFlimValDtoNoNesting on CreatedByFlimValDto {
  type
  blabItemId
  flimDefId
  createdByUserId
  createdByWorkflowDefId
  createdVia
}
`

export const LastModifiedByFlimValDtoNoNestingFragment = gql`
fragment LastModifiedByFlimValDtoNoNesting on LastModifiedByFlimValDto {
  type
  blabItemId
  flimDefId
  lastModifiedByUserId
  lastModifiedByWorkflowDefId
  lastModifiedVia
}
`

export const LastModifiedAtFlimValDtoNoNestingFragment = gql`
fragment LastModifiedAtFlimValDtoNoNesting on LastModifiedAtFlimValDto {
  type
  blabItemId
  flimDefId
  lastModifiedAt
}
`

export const CalculationFlimValDtoNoNestingFragment = gql`
fragment CalculationFlimValDtoNoNesting on CalculationFlimValDto {
  type
  blabItemId
  flimDefId
  isInvalid
  decimal
  value
  unformatted
  date
  hasTime
}
`

export const MultiEmailFlimValDtoNoNestingFragment = gql`
fragment MultiEmailFlimValDtoNoNesting on MultiEmailFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const MultiPhoneFlimValDtoNoNestingFragment = gql`
fragment MultiPhoneFlimValDtoNoNesting on MultiPhoneFlimValDto {
  type
  blabItemId
  flimDefId
}
`

export const UniqueIdFlimValDtoNoNestingFragment = gql`
fragment UniqueIdFlimValDtoNoNesting on UniqueIdFlimValDto {
  type
  blabItemId
  flimDefId
  uniqueId
}
`

export const TimestampMetaFilterDtoNoNestingFragment = gql`
fragment TimestampMetaFilterDtoNoNesting on TimestampMetaFilterDto {
  id
  metaType
  blabViewId
  from
  to
}
`

export const MultiAttachmentFlimFilterDtoNoNestingFragment = gql`
fragment MultiAttachmentFlimFilterDtoNoNesting on MultiAttachmentFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiAttachmentMatchType
}
`

export const SingleAttachmentFlimFilterDtoNoNestingFragment = gql`
fragment SingleAttachmentFlimFilterDtoNoNesting on SingleAttachmentFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  singleAttachmentMatchType
}
`

export const CalculationFlimFilterDtoNoNestingFragment = gql`
fragment CalculationFlimFilterDtoNoNesting on CalculationFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  decimal
  calculationMatchType
  calculationRelativeDate
  calculationRelativeDateRange
  numDays
  numWeeks
  numMonths
  exactDate
  exactEndDate
  weekday
  quarterOfYear
  monthOfYear
}
`

export const MultiCategoryFlimFilterDtoNoNestingFragment = gql`
fragment MultiCategoryFlimFilterDtoNoNesting on MultiCategoryFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  multiCategoryMatchType
}
`

export const SingleCategoryFlimFilterDtoNoNestingFragment = gql`
fragment SingleCategoryFlimFilterDtoNoNesting on SingleCategoryFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  singleCategoryMatchType
}
`

export const ChecklistFlimFilterDtoNoNestingFragment = gql`
fragment ChecklistFlimFilterDtoNoNesting on ChecklistFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  checklistMatchType
  checklistEntryProperty
  searchText
  completed
  checklistDueDateRelativeDateType
  checklistDueDateRelativeDateRangeType
  date
  numDays
  numWeeks
  numMonths
  includeActiveUser
  includeActiveUserIndex
}
`

export const CreatedAtFlimFilterDtoNoNestingFragment = gql`
fragment CreatedAtFlimFilterDtoNoNesting on CreatedAtFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  createdAtMatchType
  exactDate
  exactEndDate
  createdAtRelativeDateRange
  createdAtRelativeDate
  numDays
  numWeeks
  numMonths
  weekday
  quarterOfYear
  monthOfYear
}
`

export const CreatedByFlimFilterDtoNoNestingFragment = gql`
fragment CreatedByFlimFilterDtoNoNesting on CreatedByFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  createdByMatchType
  includeActiveUser
  includeActiveUserIndex
}
`

export const RangeDateFlimFilterDtoNoNestingFragment = gql`
fragment RangeDateFlimFilterDtoNoNesting on RangeDateFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  rangeDateMatchType
  exactDate
  exactEndDate
  rangeDateRelativeDateRange
  rangeDateRelativeDate
  numDays
  numWeeks
  numMonths
  weekday
  quarterOfYear
  monthOfYear
}
`

export const SingleDateFlimFilterDtoNoNestingFragment = gql`
fragment SingleDateFlimFilterDtoNoNesting on SingleDateFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  singleDateMatchType
  exactDate
  exactEndDate
  singleDateRelativeDateRange
  singleDateRelativeDate
  numDays
  numWeeks
  numMonths
  weekday
  quarterOfYear
  monthOfYear
}
`

export const MultiEmailFlimFilterDtoNoNestingFragment = gql`
fragment MultiEmailFlimFilterDtoNoNesting on MultiEmailFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiEmailMatchType
}
`

export const MultiImageFlimFilterDtoNoNestingFragment = gql`
fragment MultiImageFlimFilterDtoNoNesting on MultiImageFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiImageMatchType
}
`

export const LastModifiedAtFlimFilterDtoNoNestingFragment = gql`
fragment LastModifiedAtFlimFilterDtoNoNesting on LastModifiedAtFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  lastModifiedAtMatchType
  exactDate
  exactEndDate
  lastModifiedAtRelativeDateRange
  lastModifiedAtRelativeDate
  numDays
  numWeeks
  numMonths
  weekday
  quarterOfYear
  monthOfYear
}
`

export const LastModifiedByFlimFilterDtoNoNestingFragment = gql`
fragment LastModifiedByFlimFilterDtoNoNesting on LastModifiedByFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  lastModifiedByMatchType
  includeActiveUser
  includeActiveUserIndex
}
`

export const SingleLocationFlimFilterDtoNoNestingFragment = gql`
fragment SingleLocationFlimFilterDtoNoNesting on SingleLocationFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  singleLocationMatchType
}
`

export const MultiLinkFlimFilterDtoNoNestingFragment = gql`
fragment MultiLinkFlimFilterDtoNoNesting on MultiLinkFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiLinkMatchType
}
`

export const NumberFlimFilterDtoNoNestingFragment = gql`
fragment NumberFlimFilterDtoNoNesting on NumberFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  decimal
  numberMatchType
}
`

export const MultiPhoneFlimFilterDtoNoNestingFragment = gql`
fragment MultiPhoneFlimFilterDtoNoNesting on MultiPhoneFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiPhoneMatchType
}
`

export const MultiRelationFlimFilterDtoNoNestingFragment = gql`
fragment MultiRelationFlimFilterDtoNoNesting on MultiRelationFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  multiRelationMatchType
}
`

export const SingleRelationFlimFilterDtoNoNestingFragment = gql`
fragment SingleRelationFlimFilterDtoNoNesting on SingleRelationFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  singleRelationMatchType
}
`

export const StatusFlimFilterDtoNoNestingFragment = gql`
fragment StatusFlimFilterDtoNoNesting on StatusFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  statusMatchType
}
`

export const MultiTextFlimFilterDtoNoNestingFragment = gql`
fragment MultiTextFlimFilterDtoNoNesting on MultiTextFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  multiTextMatchType
}
`

export const SingleTextFlimFilterDtoNoNestingFragment = gql`
fragment SingleTextFlimFilterDtoNoNesting on SingleTextFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  searchText
  singleTextMatchType
}
`

export const UniqueIdFlimFilterDtoNoNestingFragment = gql`
fragment UniqueIdFlimFilterDtoNoNesting on UniqueIdFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  decimal
  uniqueIdMatchType
}
`

export const MultiUserFlimFilterDtoNoNestingFragment = gql`
fragment MultiUserFlimFilterDtoNoNesting on MultiUserFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  multiUserMatchType
  includeActiveUser
  includeActiveUserIndex
}
`

export const SingleUserFlimFilterDtoNoNestingFragment = gql`
fragment SingleUserFlimFilterDtoNoNesting on SingleUserFlimFilterDto {
  id
  type
  blabViewId
  flimDefId
  position
  derivedFromSplitBy
  singleUserMatchType
  includeActiveUser
  includeActiveUserIndex
}
`


