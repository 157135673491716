/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ApiWebsocketClient } from '@t5s/shared/provider-token/api-websocket-client';
import { ApiVersion } from '@t5s/shared/readonly-constant/api';
import { Observable, of, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
const URI = `${wsProtocol}://${window.location.host}`.replace('4200', '3500').replace('4201', '3500');

@Injectable({ providedIn: 'root' })
export class ClientApiWebsocketClient extends ApiWebsocketClient {
  private socket: Socket;

  private hasConnected = false;

  /** Emits when the client has reconnected. */
  private readonly reconnect$$ = new Subject<void>();
  readonly reconnect$ = this.reconnect$$.asObservable();

  private readonly messages$$ = new Subject<unknown>();
  readonly messages$ = this.messages$$.asObservable();

  constructor() {
    super({ baseUrl: '', version: ApiVersion.V1 });

    this.socket = io(URI, {
      transports: ['websocket'],
      path: '/tape-socket-io-ws',
      withCredentials: true,
      autoConnect: false,
    });

    this.socket.on('connect', () => {
      console.log('Websocket client connected.');

      // report reconnection if the client has reconnected
      if (this.hasConnected) {
        this.reconnect$$.next();
      }
    });

    this.socket.on('disconnect', () => {
      console.log('Websocket client disconnected.');
    });

    this.socket.on('error', (error) => {
      console.error('Websocket client error:', error);
    });

    this.socket.on('message', (message) => {
      this.messages$$.next(message);
    });
  }

  dispatch<TInput extends {}>({ message }: { message: TInput }): Observable<{ message: TInput }> {
    this.socket.emit('message', message);

    return of({ message });
  }

  setActiveUserId(activeUserId: number): void {
    super.setActiveUserId(activeUserId);

    if (this.socket) {
      this.socket.auth = { userId: activeUserId };
    }

    // also check if the client has connected, if not perform initial connection
    if (!this.socket.connected) {
      this.socket.connect();
      this.hasConnected = true;
    }
  }

  forceReconnection() {
    this.socket.disconnect();
    this.socket.connect();
  }
}
