
  /* eslint-disable */
  import { getFragmentNames, importFragments } from '../utils';
  import { DocumentNode } from 'graphql';
  import gql from 'graphql-tag';
  import {
    ActiveUserApiKeyDtoNoNestingFragment,
  ActiveUserBlabDefFollowInfoDtoNoNestingFragment,
  ActiveUserBlabDefInboundEmailSettingsDtoNoNestingFragment,
  ActiveUserBlabDefInfoDtoNoNestingFragment,
  ActiveUserBlabItemInfoDtoNoNestingFragment,
  ActiveUserCalendarSettingsDtoNoNestingFragment,
  ActiveUserCalendarStaticSharesDtoNoNestingFragment,
  ActiveUserCalendarSubscriptionDtoNoNestingFragment,
  ActiveUserDtoNoNestingFragment,
  ActiveUserEngagedBlabItemPreviewDtoNoNestingFragment,
  ActiveUserExploreBlabItemPreviewDtoNoNestingFragment,
  ActiveUserExploreSuggestionDtoNoNestingFragment,
  ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment,
  ActiveUserNotificationSettingsDtoNoNestingFragment,
  ActiveUserOnboardingTasksDtoNoNestingFragment,
  ActiveUserProfileDtoNoNestingFragment,
  ActiveUserQuickAddBlabDefDtoNoNestingFragment,
  ActiveUserUserInfoDtoNoNestingFragment,
  ActiveUserWorkflowDefInfoDtoNoNestingFragment,
  ActiveUserWorkspaceInfoDtoNoNestingFragment,
  ApiInfoDtoNoNestingFragment,
  AudioRoomDtoNoNestingFragment,
  AudioRoomPreviewDtoNoNestingFragment,
  BillingCustomerDtoNoNestingFragment,
  BillingCustomerValidationResultDtoNoNestingFragment,
  BlabDefDataImportActivityDtoNoNestingFragment,
  BlabDefDataImportActivityPreviewDtoNoNestingFragment,
  BlabDefDataImportToExistingBlabDefConfigPreviewDtoNoNestingFragment,
  BlabDefDataImportToNewBlabDefConfigPreviewDtoNoNestingFragment,
  BlabDefDeveloperInfoDtoNoNestingFragment,
  BlabDefDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  BlabDefSearchResultDtoNoNestingFragment,
  BlabDefSettingsDtoNoNestingFragment,
  BlabItemActivityCountsDtoNoNestingFragment,
  BlabItemDetailDtoNoNestingFragment,
  BlabItemPreviewDtoNoNestingFragment,
  BlabItemSearchResultDtoNoNestingFragment,
  BlabItemStatsResultDtoNoNestingFragment,
  BlabItemTemplateDtoNoNestingFragment,
  BlabViewDtoNoNestingFragment,
  BlabViewPreviewDtoNoNestingFragment,
  CalendarEventsDtoNoNestingFragment,
  ChatDialogDtoNoNestingFragment,
  ChatGroupDtoNoNestingFragment,
  CheckEmailResultDtoNoNestingFragment,
  ExportGlobalWorkflowRunsResultDtoNoNestingFragment,
  ExportWorkflowRunsOfWorkflowDefResultDtoNoNestingFragment,
  FocusSectionPreviewDtoNoNestingFragment,
  FocusSectionWithTotalDtoNoNestingFragment,
  FocusSettingsDtoNoNestingFragment,
  GetBlabItemFocusSectionResultDtoNoNestingFragment,
  GetBlabViewsPaginatedResultDtoNoNestingFragment,
  GetChecklistFlimValEntryFocusSectionResultDtoNoNestingFragment,
  GetNumRemindersResultDtoNoNestingFragment,
  GetReminderFocusSectionResultDtoNoNestingFragment,
  GlobalWorkflowRunDetailDtoNoNestingFragment,
  IncomingRelationFlimDefPreviewDtoNoNestingFragment,
  OrganizationAuthenticationProviderDtoNoNestingFragment,
  OrganizationPlanDtoNoNestingFragment,
  OrganizationProfileDtoNoNestingFragment,
  OrganizationProfileEditDtoNoNestingFragment,
  OrganizationSettingsDtoNoNestingFragment,
  OrganizationSmtpSettingsDtoNoNestingFragment,
  OrganizationWorkflowStatusDtoNoNestingFragment,
  RelationFlimDefBlabDefSearchResultDtoNoNestingFragment,
  ReminderDtoNoNestingFragment,
  ShareContextResultDtoNoNestingFragment,
  SharedBlabDefDtoNoNestingFragment,
  SharedWorkspaceDtoNoNestingFragment,
  StripeClientConfigDtoNoNestingFragment,
  SubscriptionInvoiceDtoNoNestingFragment,
  SubscriptionInvoicePreviewDtoNoNestingFragment,
  UserClientAppBubbleDtoNoNestingFragment,
  UserFollowInfoDtoNoNestingFragment,
  UserPasswordValidityResultDtoNoNestingFragment,
  UserProfileDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
  UserSearchResultDtoNoNestingFragment,
  UserSessionDtoNoNestingFragment,
  UserSettingsDtoNoNestingFragment,
  UserSettingsMicrosoftIntegrationStatusDtoNoNestingFragment,
  VapidKeyNoNestingFragment,
  WorkflowCenterRecentWorkflowPreviewDtoNoNestingFragment,
  WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment,
  WorkflowCenterWebhookDtoNoNestingFragment,
  WorkflowCenterWorkflowPreviewDtoNoNestingFragment,
  WorkflowCenterWorkspaceDtoNoNestingFragment,
  WorkflowDefLookupBlabViewDtoNoNestingFragment,
  WorkflowDefLookupDtoNoNestingFragment,
  WorkflowDefUniverseBlabViewDtoNoNestingFragment,
  WorkflowDefUniverseCallableWorkflowDefDtoNoNestingFragment,
  WorkflowDefWithLookupDtoNoNestingFragment,
  WorkflowHomeCurrentInfoDtoNoNestingFragment,
  WorkflowHomeTimeIntervalInfoDtoNoNestingFragment,
  WorkflowRunDetailDtoNoNestingFragment,
  WorkflowWebhookLastPayloadDtoNoNestingFragment,
  WorkspaceDtoNoNestingFragment,
  WorkspaceSettingsDtoNoNestingFragment,
  WorkspaceShareSettingsDtoNoNestingFragment,
  WorkspaceTemplateDtoNoNestingFragment,
  WorkspaceTemplatePublishInfoDtoNoNestingFragment,
  _BlabItemPersistedTitleDtoNoNestingFragment,
  _DatabaseVersionDtoNoNestingFragment,
  _DateArrivedJobDtoNoNestingFragment,
  _DatePeriodicJobDtoNoNestingFragment,
  _GetOrganizationJobStatusResultDtoNoNestingFragment,
  _GetWorkflowOfOrganizationAreThrottledNoNestingFragment,
  _OrganizationWorkflowUsageReportDtoNoNestingFragment,
  _TestJobQueueExistsResultNoNestingFragment,
  _testTimeMockDtoNoNestingFragment
  } from './graphql-fragments'
  import {
    CheckEmailInput,
  CheckUserPasswordValidityInput,
  GetActiveUserBlabDefInboundEmailSettingsInput,
  GetActiveUserBlabDefInfoInput,
  GetActiveUserBlabItemInfoInput,
  GetActiveUserBlabItemTemplatesInput,
  GetActiveUserBlabViewPreviewsInput,
  GetActiveUserCalendarSettingsInput,
  GetActiveUserCalendarStaticSharesInput,
  GetActiveUserCalendarSubscriptionsInput,
  GetActiveUserExploreSuggestionsForCategoryInput,
  GetActiveUserExploreSuggestionsInput,
  GetActiveUserQuickAddBlabDefsInput,
  GetActiveUserSuggestedQuickAddBlabDefsInput,
  GetActiveUserUserInfoInput,
  GetActiveUserWorkflowDefInfoInput,
  GetActiveUserWorkspaceInfoInput,
  GetAllIncomingAndOutgoingBlabDefsInput,
  GetAudioRoomInput,
  GetAudioRoomPreviewsInput,
  GetBlabDefDataImportActivitiesInput,
  GetBlabDefDataImportActivityInput,
  GetBlabDefDataImportToExistingBlabDefConfigPreviewInput,
  GetBlabDefDataImportToExistingBlabDefConfigPreviewLookupInput,
  GetBlabDefDataImportToNewBlabDefConfigPreviewInput,
  GetBlabDefDataImportToNewBlabDefConfigPreviewLookupInput,
  GetBlabDefDeveloperInfoInput,
  GetBlabDefFollowingInput,
  GetBlabDefRelationsWithFlimDefsInput,
  GetBlabDefSettingsInput,
  GetBlabItemActivityCountsInput,
  GetBlabItemActivityInput,
  GetBlabItemActivityRepliesInput,
  GetBlabItemFocusSectionInput,
  GetBlabItemFollowersInput,
  GetBlabItemRemindersInput,
  GetBlabItemStatsInput,
  GetBlabItemsForBlabDefInput,
  GetBlabViewInput,
  GetBlabViewsPaginatedInput,
  GetCalculationScriptResultPreviewInput,
  GetCalendarEventsInput,
  GetChangeSubscriptionBillingPeriodInvoicePreviewInput,
  GetChangeSubscriptionPlanInvoicePreviewInput,
  GetChatActivitiesInput,
  GetChatGroupInput,
  GetChatGroupUsersInput,
  GetChecklistFlimValEntryFocusSectionInput,
  GetChronologicalNewsfeedInput,
  GetConfiguredWorkflowPreviewsByBlabDefIdInput,
  GetConversationInput,
  GetConversationsInput,
  GetCreateSubscriptionInvoicePreviewInput,
  GetDefaultBlabViewsForBlabDefsInput,
  GetDialogInput,
  GetFocusDueDateSectionsInput,
  GetFocusEntriesInput,
  GetFocusSectionInput,
  GetFocusSectionPreviewsInput,
  GetFocusSectionsInput,
  GetFocusSettingsInput,
  GetGlobalWorkflowRunsInput,
  GetIncomingBlabItemsInput,
  GetIncomingRelationFlimDefsInput,
  GetLastWorkflowWebhookPayloadInput,
  GetLocationAutocompleteSuggestionsInput,
  GetLocationDetailsInput,
  GetNotificationsInput,
  GetNumRemindersInput,
  GetOrgUsersInput,
  GetOrganizationActivityInput,
  GetOrganizationActivityRepliesInput,
  GetOrganizationInvitationsInput,
  GetOrganizationUserByEmailInput,
  GetOrganizationUsersInput,
  GetOrganizationWorkspacesInput,
  GetReminderFocusSectionInput,
  GetReminderInput,
  GetRemindersInput,
  GetSharedBlabDefInput,
  GetSharedBlabViewInput,
  GetSharedBlabViewsInput,
  GetSharedWorkspaceInput,
  GetSingleBlabItemActivityInput,
  GetSubscriptionActivateRenwalInvoicePreviewInput,
  GetUserActivitiesInput,
  GetUserActivityInput,
  GetUserActivityRepliesInput,
  GetUserFollowInfoInput,
  GetUserFollowersInput,
  GetUserFollowingInput,
  GetUserTrashInput,
  GetWorkflowCenterWebhooksInput,
  GetWorkflowDefLookupInput,
  GetWorkflowDefUniverseBlabViewsInput,
  GetWorkflowDefUniverseCallableWorkflowDefsInput,
  GetWorkflowDefWithLookupInput,
  GetWorkflowHomeCurrentInfoInput,
  GetWorkflowHomeTimeIntervalInfoInput,
  GetWorkflowRunsOfWorkflowDefInput,
  GetWorkspaceActivityRepliesInput,
  GetWorkspaceBlabDefsInput,
  GetWorkspaceMembershipsInput,
  GetWorkspaceSettingsInput,
  GetWorkspaceShareSettingsInput,
  GetWorkspaceTemplateInput,
  GetWorkspaceTemplatePublishInfoInput,
  GetWorkspaceTemplatesInput,
  SearchBlabDefsInput,
  SearchBlabItemsForRelationFlimFilterInput,
  SearchBlabItemsInput,
  SearchExecutionContextBlabItemsInput,
  SearchExistingAndNewConversationsInput,
  SearchInput,
  SearchRelationFlimDefBlabDefsInput,
  SearchUserFlimValUsersInput,
  SearchUsersForUserFlimFilterInput,
  SearchUsersInWorkspaceInput,
  SearchUsersInput,
  SearchUsersWithBlabItemAccessInput,
  SearchWorkflowPreviewsForGlobalRunsFilteringInput,
  SearchWorkflowPreviewsInput,
  SearchWorkspaceMembershipCandidatesInput,
  UpdateBillingCustomerInput,
  _GetBlabItemPersistedTitleInput,
  _GetDateArrivedJobsBetweenDatesInput,
  _GetDatePeriodicJobsBetweenDatesInput,
  _GetOrganizationJobStatusInput,
  _OrganizationWorkflowUsageReportInput,
  _TestJobQueueExistsInput
  } from '@t5s/shared/gql'

  
export function _getBlabItemPersistedTitle(input: _GetBlabItemPersistedTitleInput, fragments: DocumentNode[]= [_BlabItemPersistedTitleDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getBlabItemPersistedTitle($input: _GetBlabItemPersistedTitleInput!) {
        _getBlabItemPersistedTitle (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function _getDatabaseVersion(fragments: DocumentNode[]= [_DatabaseVersionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getDatabaseVersion {
        _getDatabaseVersion  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function _testJobQueueExists(input?: _TestJobQueueExistsInput, fragments: DocumentNode[]= [_TestJobQueueExistsResultNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _testJobQueueExists($input: _TestJobQueueExistsInput) {
        _testJobQueueExists (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function _getOrganizationJobStatus(input?: _GetOrganizationJobStatusInput, fragments: DocumentNode[]= [_GetOrganizationJobStatusResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getOrganizationJobStatus($input: _GetOrganizationJobStatusInput) {
        _getOrganizationJobStatus (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function _getPermissionV2EnabledForOrganization(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query _getPermissionV2EnabledForOrganization {
        _getPermissionV2EnabledForOrganization  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function _testTimeMockGetStatus(fragments: DocumentNode[]= [_testTimeMockDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _testTimeMockGetStatus {
        _testTimeMockGetStatus  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function _getDateArrivedJobsBetweenDates(input: _GetDateArrivedJobsBetweenDatesInput, fragments: DocumentNode[]= [_DateArrivedJobDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getDateArrivedJobsBetweenDates($input: _GetDateArrivedJobsBetweenDatesInput!) {
        _getDateArrivedJobsBetweenDates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function _getDatePeriodicJobsBetweenDates(input: _GetDatePeriodicJobsBetweenDatesInput, fragments: DocumentNode[]= [_DatePeriodicJobDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getDatePeriodicJobsBetweenDates($input: _GetDatePeriodicJobsBetweenDatesInput!) {
        _getDatePeriodicJobsBetweenDates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function _getWorkflowOfOrganizationAreThrottled(fragments: DocumentNode[]= [_GetWorkflowOfOrganizationAreThrottledNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getWorkflowOfOrganizationAreThrottled {
        _getWorkflowOfOrganizationAreThrottled  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function _getOrganizationWorkflowUsageReports(input?: _OrganizationWorkflowUsageReportInput, fragments: DocumentNode[]= [_OrganizationWorkflowUsageReportDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query _getOrganizationWorkflowUsageReports($input: _OrganizationWorkflowUsageReportInput) {
        _getOrganizationWorkflowUsageReports (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserApiKey(fragments: DocumentNode[]= [ActiveUserApiKeyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserApiKey {
        getActiveUserApiKey  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUser(fragments: DocumentNode[]= [ActiveUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUser {
        getActiveUser  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserBlabDefFollowInfo(input: GetBlabDefFollowingInput, fragments: DocumentNode[]= [ActiveUserBlabDefFollowInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabDefFollowInfo($input: GetBlabDefFollowingInput!) {
        getActiveUserBlabDefFollowInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserBlabDefInfo(input: GetActiveUserBlabDefInfoInput, fragments: DocumentNode[]= [ActiveUserBlabDefInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabDefInfo($input: GetActiveUserBlabDefInfoInput!) {
        getActiveUserBlabDefInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabItemInfo($input: GetActiveUserBlabItemInfoInput!) {
        getActiveUserBlabItemInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getCalendarEvents(input: GetCalendarEventsInput, fragments: DocumentNode[]= [CalendarEventsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getCalendarEvents($input: GetCalendarEventsInput!) {
        getCalendarEvents (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserCalendarSettings(input: GetActiveUserCalendarSettingsInput, fragments: DocumentNode[]= [ActiveUserCalendarSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserCalendarSettings($input: GetActiveUserCalendarSettingsInput!) {
        getActiveUserCalendarSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserCalendarStaticShares(input: GetActiveUserCalendarStaticSharesInput, fragments: DocumentNode[]= [ActiveUserCalendarStaticSharesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserCalendarStaticShares($input: GetActiveUserCalendarStaticSharesInput!) {
        getActiveUserCalendarStaticShares (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserCalendarSubscriptions(input?: GetActiveUserCalendarSubscriptionsInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserCalendarSubscriptions($input: GetActiveUserCalendarSubscriptionsInput) {
        getActiveUserCalendarSubscriptions (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserDevPortalContext(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserDevPortalContext {
        getActiveUserDevPortalContext  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserDevPortalDemoBlabItem(fragments: DocumentNode[]= [BlabItemPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserDevPortalDemoBlabItem {
        getActiveUserDevPortalDemoBlabItem  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserEngagedBlabItems(fragments: DocumentNode[]= [ActiveUserEngagedBlabItemPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserEngagedBlabItems {
        getActiveUserEngagedBlabItems  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserExploreSuggestions(input: GetActiveUserExploreSuggestionsInput, fragments: DocumentNode[]= [ActiveUserExploreSuggestionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserExploreSuggestions($input: GetActiveUserExploreSuggestionsInput!) {
        getActiveUserExploreSuggestions (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserExploreSuggestionsForCategory(first: number, input?: GetActiveUserExploreSuggestionsForCategoryInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserExploreSuggestionsForCategory($first: Int!, $input: GetActiveUserExploreSuggestionsForCategoryInput, $after: String) {
        getActiveUserExploreSuggestionsForCategory (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabItemExploreSuggestions(fragments: DocumentNode[]= [ActiveUserExploreBlabItemPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemExploreSuggestions {
        getBlabItemExploreSuggestions  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserNotificationSettings(fragments: DocumentNode[]= [ActiveUserNotificationSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserNotificationSettings {
        getActiveUserNotificationSettings  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserOnboardingTasks(fragments: DocumentNode[]= [ActiveUserOnboardingTasksDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserOnboardingTasks {
        getActiveUserOnboardingTasks  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrgUsers(first: number, input?: GetOrgUsersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrgUsers($first: Int!, $input: GetOrgUsersInput, $after: String) {
        getOrgUsers (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getActiveOrganizationUserProfilesByEmailPrefix(input?: GetOrganizationUserByEmailInput, fragments: DocumentNode[]= [UserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveOrganizationUserProfilesByEmailPrefix($input: GetOrganizationUserByEmailInput) {
        getActiveOrganizationUserProfilesByEmailPrefix (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserProfile(fragments: DocumentNode[]= [ActiveUserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserProfile {
        getActiveUserProfile  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserQuickAddBlabDefs(input: GetActiveUserQuickAddBlabDefsInput, fragments: DocumentNode[]= [ActiveUserQuickAddBlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserQuickAddBlabDefs($input: GetActiveUserQuickAddBlabDefsInput!) {
        getActiveUserQuickAddBlabDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserSuggestedQuickAddBlabDefs(first: number, input?: GetActiveUserSuggestedQuickAddBlabDefsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserSuggestedQuickAddBlabDefs($first: Int!, $input: GetActiveUserSuggestedQuickAddBlabDefsInput, $after: String) {
        getActiveUserSuggestedQuickAddBlabDefs (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getActiveUserUserInfo(input: GetActiveUserUserInfoInput, fragments: DocumentNode[]= [ActiveUserUserInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserUserInfo($input: GetActiveUserUserInfoInput!) {
        getActiveUserUserInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserWorkflowDefInfo(input: GetActiveUserWorkflowDefInfoInput, fragments: DocumentNode[]= [ActiveUserWorkflowDefInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserWorkflowDefInfo($input: GetActiveUserWorkflowDefInfoInput!) {
        getActiveUserWorkflowDefInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserRecentWorkflowDefs(fragments: DocumentNode[]= [WorkflowCenterRecentWorkflowPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserRecentWorkflowDefs {
        getActiveUserRecentWorkflowDefs  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getActiveUserWorkspaceInfo(input: GetActiveUserWorkspaceInfoInput, fragments: DocumentNode[]= [ActiveUserWorkspaceInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserWorkspaceInfo($input: GetActiveUserWorkspaceInfoInput!) {
        getActiveUserWorkspaceInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getApiInfo(fragments: DocumentNode[]= [ApiInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getApiInfo {
        getApiInfo  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getAudioRoom(input?: GetAudioRoomInput, fragments: DocumentNode[]= [AudioRoomDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getAudioRoom($input: GetAudioRoomInput) {
        getAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getAudioRoomPreviews(input?: GetAudioRoomPreviewsInput, fragments: DocumentNode[]= [AudioRoomPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getAudioRoomPreviews($input: GetAudioRoomPreviewsInput) {
        getAudioRoomPreviews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBillingCustomer(fragments: DocumentNode[]= [BillingCustomerDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBillingCustomer {
        getBillingCustomer  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function validateBillingCustomerInput(input: UpdateBillingCustomerInput, fragments: DocumentNode[]= [BillingCustomerValidationResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query validateBillingCustomerInput($input: UpdateBillingCustomerInput!) {
        validateBillingCustomerInput (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBillingSubscription(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBillingSubscription {
        getBillingSubscription  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getPaymentMethods(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getPaymentMethods {
        getPaymentMethods  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getDefaultPaymentMethod(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getDefaultPaymentMethod {
        getDefaultPaymentMethod  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getSubscriptionInvoiceHistory(fragments: DocumentNode[]= [SubscriptionInvoiceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSubscriptionInvoiceHistory {
        getSubscriptionInvoiceHistory  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getSubscriptionCheckoutInvoicePreview(input: GetCreateSubscriptionInvoicePreviewInput, fragments: DocumentNode[]= [SubscriptionInvoicePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSubscriptionCheckoutInvoicePreview($input: GetCreateSubscriptionInvoicePreviewInput!) {
        getSubscriptionCheckoutInvoicePreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChangeSubscriptionBillingPeriodInvoicePreview(input: GetChangeSubscriptionBillingPeriodInvoicePreviewInput, fragments: DocumentNode[]= [SubscriptionInvoicePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getChangeSubscriptionBillingPeriodInvoicePreview($input: GetChangeSubscriptionBillingPeriodInvoicePreviewInput!) {
        getChangeSubscriptionBillingPeriodInvoicePreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChangeSubscriptionPlanInvoicePreview(input: GetChangeSubscriptionPlanInvoicePreviewInput, fragments: DocumentNode[]= [SubscriptionInvoicePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getChangeSubscriptionPlanInvoicePreview($input: GetChangeSubscriptionPlanInvoicePreviewInput!) {
        getChangeSubscriptionPlanInvoicePreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSubscriptionUpcomingInvoicePreview(fragments: DocumentNode[]= [SubscriptionInvoicePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSubscriptionUpcomingInvoicePreview {
        getSubscriptionUpcomingInvoicePreview  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getSubscriptionActivateRenewalInvoicePreview(input: GetSubscriptionActivateRenwalInvoicePreviewInput, fragments: DocumentNode[]= [SubscriptionInvoicePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSubscriptionActivateRenewalInvoicePreview($input: GetSubscriptionActivateRenwalInvoicePreviewInput!) {
        getSubscriptionActivateRenewalInvoicePreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDef(id: number, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDef($id: Int!) {
        getBlabDef (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getBlabDefs(workspaceId: number, fragments: DocumentNode[]= [BlabDefPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefs($workspaceId: Int!) {
        getBlabDefs (workspaceId: $workspaceId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { workspaceId }
  }
}

export function getBlabDefDataImportActivities(input?: GetBlabDefDataImportActivitiesInput, fragments: DocumentNode[]= [BlabDefDataImportActivityPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportActivities($input: GetBlabDefDataImportActivitiesInput) {
        getBlabDefDataImportActivities (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDataImportActivity(input?: GetBlabDefDataImportActivityInput, fragments: DocumentNode[]= [BlabDefDataImportActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportActivity($input: GetBlabDefDataImportActivityInput) {
        getBlabDefDataImportActivity (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDataImportToExistingBlabDefConfigPreview(input: GetBlabDefDataImportToExistingBlabDefConfigPreviewInput, fragments: DocumentNode[]= [BlabDefDataImportToExistingBlabDefConfigPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportToExistingBlabDefConfigPreview($input: GetBlabDefDataImportToExistingBlabDefConfigPreviewInput!) {
        getBlabDefDataImportToExistingBlabDefConfigPreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDataImportToExistingBlabDefConfigPreviewLookup(input: GetBlabDefDataImportToExistingBlabDefConfigPreviewLookupInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportToExistingBlabDefConfigPreviewLookup($input: GetBlabDefDataImportToExistingBlabDefConfigPreviewLookupInput!) {
        getBlabDefDataImportToExistingBlabDefConfigPreviewLookup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDataImportToNewBlabDefConfigPreview(input?: GetBlabDefDataImportToNewBlabDefConfigPreviewInput, fragments: DocumentNode[]= [BlabDefDataImportToNewBlabDefConfigPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportToNewBlabDefConfigPreview($input: GetBlabDefDataImportToNewBlabDefConfigPreviewInput) {
        getBlabDefDataImportToNewBlabDefConfigPreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDataImportToNewBlabDefConfigPreviewLookup(input: GetBlabDefDataImportToNewBlabDefConfigPreviewLookupInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDataImportToNewBlabDefConfigPreviewLookup($input: GetBlabDefDataImportToNewBlabDefConfigPreviewLookupInput!) {
        getBlabDefDataImportToNewBlabDefConfigPreviewLookup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefDeveloperInfo(input: GetBlabDefDeveloperInfoInput, fragments: DocumentNode[]= [BlabDefDeveloperInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefDeveloperInfo($input: GetBlabDefDeveloperInfoInput!) {
        getBlabDefDeveloperInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserBlabDefInboundEmailSettings(input: GetActiveUserBlabDefInboundEmailSettingsInput, fragments: DocumentNode[]= [ActiveUserBlabDefInboundEmailSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabDefInboundEmailSettings($input: GetActiveUserBlabDefInboundEmailSettingsInput!) {
        getActiveUserBlabDefInboundEmailSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchBlabDefs(input: SearchBlabDefsInput, fragments: DocumentNode[]= [BlabDefSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchBlabDefs($input: SearchBlabDefsInput!) {
        searchBlabDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabDefSettings(input: GetBlabDefSettingsInput, fragments: DocumentNode[]= [BlabDefSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefSettings($input: GetBlabDefSettingsInput!) {
        getBlabDefSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSharedBlabDef(input: GetSharedBlabDefInput, fragments: DocumentNode[]= [SharedBlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSharedBlabDef($input: GetSharedBlabDefInput!) {
        getSharedBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItem(id: number, fragments: DocumentNode[]= [BlabItemDetailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItem($id: Int!) {
        getBlabItem (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getBlabItemsForBlabDef(first: number, input?: GetBlabItemsForBlabDefInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemsForBlabDef($first: Int!, $input: GetBlabItemsForBlabDefInput, $after: String) {
        getBlabItemsForBlabDef (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getSingleBlabItemActivity(input?: GetSingleBlabItemActivityInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getSingleBlabItemActivity($input: GetSingleBlabItemActivityInput) {
        getSingleBlabItemActivity (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItemActivity(first: number, input?: GetBlabItemActivityInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemActivity($first: Int!, $input: GetBlabItemActivityInput, $after: String) {
        getBlabItemActivity (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabItemActivityCounts(input: GetBlabItemActivityCountsInput, fragments: DocumentNode[]= [BlabItemActivityCountsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemActivityCounts($input: GetBlabItemActivityCountsInput!) {
        getBlabItemActivityCounts (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItemActivityReplies(first: number, input?: GetBlabItemActivityRepliesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemActivityReplies($first: Int!, $input: GetBlabItemActivityRepliesInput, $after: String) {
        getBlabItemActivityReplies (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabItemFocusSection(input: GetBlabItemFocusSectionInput, fragments: DocumentNode[]= [GetBlabItemFocusSectionResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemFocusSection($input: GetBlabItemFocusSectionInput!) {
        getBlabItemFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChecklistFlimValEntryFocusSection(input: GetChecklistFlimValEntryFocusSectionInput, fragments: DocumentNode[]= [GetChecklistFlimValEntryFocusSectionResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getChecklistFlimValEntryFocusSection($input: GetChecklistFlimValEntryFocusSectionInput!) {
        getChecklistFlimValEntryFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItemFollowers(first: number, input?: GetBlabItemFollowersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemFollowers($first: Int!, $input: GetBlabItemFollowersInput, $after: String) {
        getBlabItemFollowers (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getIncomingRelationFlimDefs(input: GetIncomingRelationFlimDefsInput, fragments: DocumentNode[]= [IncomingRelationFlimDefPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getIncomingRelationFlimDefs($input: GetIncomingRelationFlimDefsInput!) {
        getIncomingRelationFlimDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getIncomingBlabItems(first: number, input?: GetIncomingBlabItemsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getIncomingBlabItems($first: Int!, $input: GetIncomingBlabItemsInput, $after: String) {
        getIncomingBlabItems (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabItemReminders(first: number, input?: GetBlabItemRemindersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemReminders($first: Int!, $input: GetBlabItemRemindersInput, $after: String) {
        getBlabItemReminders (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function searchBlabItems(input: SearchBlabItemsInput, fragments: DocumentNode[]= [BlabItemSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchBlabItems($input: SearchBlabItemsInput!) {
        searchBlabItems (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItemsForSharedBlabDef(first: number, input?: GetBlabItemsForBlabDefInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemsForSharedBlabDef($first: Int!, $input: GetBlabItemsForBlabDefInput, $after: String) {
        getBlabItemsForSharedBlabDef (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabItemStatsForSharedBlabDef(input: GetBlabItemStatsInput, fragments: DocumentNode[]= [BlabItemStatsResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemStatsForSharedBlabDef($input: GetBlabItemStatsInput!) {
        getBlabItemStatsForSharedBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabItemStats(input: GetBlabItemStatsInput, fragments: DocumentNode[]= [BlabItemStatsResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabItemStats($input: GetBlabItemStatsInput!) {
        getBlabItemStats (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserBlabItemTemplates(input: GetActiveUserBlabItemTemplatesInput, fragments: DocumentNode[]= [BlabItemTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabItemTemplates($input: GetActiveUserBlabItemTemplatesInput!) {
        getActiveUserBlabItemTemplates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabView(input: GetBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabView($input: GetBlabViewInput!) {
        getBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getBlabViews(blabDefId: number, fragments: DocumentNode[]= [BlabViewPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabViews($blabDefId: Int!) {
        getBlabViews (blabDefId: $blabDefId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabDefId }
  }
}

export function getBlabViewsPaginated(blabDefId: number, limit: number, offset: number, fragments: DocumentNode[]= [BlabViewPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabViewsPaginated($blabDefId: Int!, $limit: Int!, $offset: Int!) {
        getBlabViewsPaginated (blabDefId: $blabDefId, limit: $limit, offset: $offset) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabDefId, limit, offset }
  }
}

export function getActiveUserBlabViewPreviews(input: GetActiveUserBlabViewPreviewsInput, fragments: DocumentNode[]= [BlabViewPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserBlabViewPreviews($input: GetActiveUserBlabViewPreviewsInput!) {
        getActiveUserBlabViewPreviews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getPublicBlabViewsPaginated(input?: GetBlabViewsPaginatedInput, fragments: DocumentNode[]= [GetBlabViewsPaginatedResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getPublicBlabViewsPaginated($input: GetBlabViewsPaginatedInput) {
        getPublicBlabViewsPaginated (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getPrivateBlabViewsPaginated(input?: GetBlabViewsPaginatedInput, fragments: DocumentNode[]= [GetBlabViewsPaginatedResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getPrivateBlabViewsPaginated($input: GetBlabViewsPaginatedInput) {
        getPrivateBlabViewsPaginated (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSharedBlabView(input: GetSharedBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSharedBlabView($input: GetSharedBlabViewInput!) {
        getSharedBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSharedBlabViews(input: GetSharedBlabViewsInput, fragments: DocumentNode[]= [BlabViewPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSharedBlabViews($input: GetSharedBlabViewsInput!) {
        getSharedBlabViews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchExistingAndNewConversations(input: SearchExistingAndNewConversationsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query searchExistingAndNewConversations($input: SearchExistingAndNewConversationsInput!) {
        searchExistingAndNewConversations (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function checkEmail(input: CheckEmailInput, fragments: DocumentNode[]= [CheckEmailResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query checkEmail($input: CheckEmailInput!) {
        checkEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getUsersForFindMyOrgsToken(token: string, fragments: DocumentNode[]= [UserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUsersForFindMyOrgsToken($token: String!) {
        getUsersForFindMyOrgsToken (token: $token) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { token }
  }
}

export function getFocusDueDateSections(input: GetFocusDueDateSectionsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusDueDateSections($input: GetFocusDueDateSectionsInput!) {
        getFocusDueDateSections (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getFocusSectionPreviews(input: GetFocusSectionPreviewsInput, fragments: DocumentNode[]= [FocusSectionPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusSectionPreviews($input: GetFocusSectionPreviewsInput!) {
        getFocusSectionPreviews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getFocusSection(input: GetFocusSectionInput, fragments: DocumentNode[]= [FocusSectionWithTotalDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusSection($input: GetFocusSectionInput!) {
        getFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getFocusSections(input: GetFocusSectionsInput, fragments: DocumentNode[]= [FocusSectionWithTotalDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusSections($input: GetFocusSectionsInput!) {
        getFocusSections (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getFocusSettings(input: GetFocusSettingsInput, fragments: DocumentNode[]= [FocusSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusSettings($input: GetFocusSettingsInput!) {
        getFocusSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function search(input: SearchInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query search($input: SearchInput!) {
        search (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSearchHistory(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getSearchHistory {
        getSearchHistory  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function checkGqlHealth(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query checkGqlHealth {
        checkGqlHealth  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getLocationAutocompleteSuggestions(input: GetLocationAutocompleteSuggestionsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getLocationAutocompleteSuggestions($input: GetLocationAutocompleteSuggestionsInput!) {
        getLocationAutocompleteSuggestions (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getLocationDetails(input: GetLocationDetailsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getLocationDetails($input: GetLocationDetailsInput!) {
        getLocationDetails (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChronologicalNewsfeed(first: number, input?: GetChronologicalNewsfeedInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getChronologicalNewsfeed($first: Int!, $input: GetChronologicalNewsfeedInput, $after: String) {
        getChronologicalNewsfeed (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getNotifications(first: number, input?: GetNotificationsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getNotifications($first: Int!, $input: GetNotificationsInput, $after: String) {
        getNotifications (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getNotificationsV2(first: number, input?: GetNotificationsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getNotificationsV2($first: Int!, $input: GetNotificationsInput, $after: String) {
        getNotificationsV2 (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getOrganizationActivity(first: number, input?: GetOrganizationActivityInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationActivity($first: Int!, $input: GetOrganizationActivityInput, $after: String) {
        getOrganizationActivity (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getOrganizationActivityReplies(first: number, input?: GetOrganizationActivityRepliesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationActivityReplies($first: Int!, $input: GetOrganizationActivityRepliesInput, $after: String) {
        getOrganizationActivityReplies (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getOrganizationInvitations(first: number, input: GetOrganizationInvitationsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationInvitations($first: Int!, $input: GetOrganizationInvitationsInput!, $after: String) {
        getOrganizationInvitations (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getUserInfoByOrganizationInvitationToken(token: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserInfoByOrganizationInvitationToken($token: String!) {
        getUserInfoByOrganizationInvitationToken (token: $token) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { token }
  }
}

export function getOrganizationPlans(fragments: DocumentNode[]= [OrganizationPlanDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationPlans {
        getOrganizationPlans  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationProfileById(orgId: number, fragments: DocumentNode[]= [OrganizationProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationProfileById($orgId: Int!) {
        getOrganizationProfileById (orgId: $orgId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { orgId }
  }
}

export function getOrganizationProfileBySlug(orgSlug: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationProfileBySlug($orgSlug: String!) {
        getOrganizationProfileBySlug (orgSlug: $orgSlug) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { orgSlug }
  }
}

export function getOrganizationProfileToEdit(fragments: DocumentNode[]= [OrganizationProfileEditDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationProfileToEdit {
        getOrganizationProfileToEdit  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationAuthenticationProvider(fragments: DocumentNode[]= [OrganizationAuthenticationProviderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationAuthenticationProvider {
        getOrganizationAuthenticationProvider  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationSettings(fragments: DocumentNode[]= [OrganizationSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationSettings {
        getOrganizationSettings  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationSmtpSettings(fragments: DocumentNode[]= [OrganizationSmtpSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationSmtpSettings {
        getOrganizationSmtpSettings  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationUsers(first: number, input: GetOrganizationUsersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationUsers($first: Int!, $input: GetOrganizationUsersInput!, $after: String) {
        getOrganizationUsers (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getOrganizationWorkspaces(first: number, input: GetOrganizationWorkspacesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationWorkspaces($first: Int!, $input: GetOrganizationWorkspacesInput!, $after: String) {
        getOrganizationWorkspaces (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function searchRelationFlimDefBlabDefs(input: SearchRelationFlimDefBlabDefsInput, fragments: DocumentNode[]= [RelationFlimDefBlabDefSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchRelationFlimDefBlabDefs($input: SearchRelationFlimDefBlabDefsInput!) {
        searchRelationFlimDefBlabDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getReminders(first: number, input?: GetRemindersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getReminders($first: Int!, $input: GetRemindersInput, $after: String) {
        getReminders (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getReminder(input: GetReminderInput, fragments: DocumentNode[]= [ReminderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getReminder($input: GetReminderInput!) {
        getReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getNumReminders(input: GetNumRemindersInput, fragments: DocumentNode[]= [GetNumRemindersResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getNumReminders($input: GetNumRemindersInput!) {
        getNumReminders (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getReminderFocusSection(input: GetReminderFocusSectionInput, fragments: DocumentNode[]= [GetReminderFocusSectionResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getReminderFocusSection($input: GetReminderFocusSectionInput!) {
        getReminderFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getShareContext(fragments: DocumentNode[]= [ShareContextResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getShareContext {
        getShareContext  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getStripeClientConfig(fragments: DocumentNode[]= [StripeClientConfigDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getStripeClientConfig {
        getStripeClientConfig  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getUserActivity(input?: GetUserActivityInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserActivity($input: GetUserActivityInput) {
        getUserActivity (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getUserActivities(first: number, input?: GetUserActivitiesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserActivities($first: Int!, $input: GetUserActivitiesInput, $after: String) {
        getUserActivities (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getUserActivityReplies(first: number, input?: GetUserActivityRepliesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserActivityReplies($first: Int!, $input: GetUserActivityRepliesInput, $after: String) {
        getUserActivityReplies (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getUserClientAppBubbles(fragments: DocumentNode[]= [UserClientAppBubbleDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserClientAppBubbles {
        getUserClientAppBubbles  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getUserFollowers(first: number, input?: GetUserFollowersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserFollowers($first: Int!, $input: GetUserFollowersInput, $after: String) {
        getUserFollowers (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getUserFollowing(first: number, input?: GetUserFollowingInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserFollowing($first: Int!, $input: GetUserFollowingInput, $after: String) {
        getUserFollowing (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getUserFollowInfo(input?: GetUserFollowInfoInput, fragments: DocumentNode[]= [UserFollowInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserFollowInfo($input: GetUserFollowInfoInput) {
        getUserFollowInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function checkUserPasswordValidity(input: CheckUserPasswordValidityInput, fragments: DocumentNode[]= [UserPasswordValidityResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query checkUserPasswordValidity($input: CheckUserPasswordValidityInput!) {
        checkUserPasswordValidity (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getUserProfile(userId: number, fragments: DocumentNode[]= [UserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserProfile($userId: Int!) {
        getUserProfile (userId: $userId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { userId }
  }
}

export function getServerVapidPubkey(fragments: DocumentNode[]= [VapidKeyNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getServerVapidPubkey {
        getServerVapidPubkey  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function searchUsers(input: SearchUsersInput, fragments: DocumentNode[]= [UserSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchUsers($input: SearchUsersInput!) {
        searchUsers (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchUsersInWorkspace(input: SearchUsersInWorkspaceInput, fragments: DocumentNode[]= [UserSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchUsersInWorkspace($input: SearchUsersInWorkspaceInput!) {
        searchUsersInWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchUsersWithBlabItemAccess(input: SearchUsersWithBlabItemAccessInput, fragments: DocumentNode[]= [UserSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchUsersWithBlabItemAccess($input: SearchUsersWithBlabItemAccessInput!) {
        searchUsersWithBlabItemAccess (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getUserSessions(fragments: DocumentNode[]= [UserSessionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserSessions {
        getUserSessions  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getUserSettingsMicrosoftIntegrationStatus(fragments: DocumentNode[]= [UserSettingsMicrosoftIntegrationStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserSettingsMicrosoftIntegrationStatus {
        getUserSettingsMicrosoftIntegrationStatus  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getUserSettings(fragments: DocumentNode[]= [UserSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserSettings {
        getUserSettings  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getUserTrash(first: number, input: GetUserTrashInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getUserTrash($first: Int!, $input: GetUserTrashInput!, $after: String) {
        getUserTrash (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getActiveUserGlobalWorkflowRunsFilteringSettings(fragments: DocumentNode[]= [ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserGlobalWorkflowRunsFilteringSettings {
        getActiveUserGlobalWorkflowRunsFilteringSettings  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getOrganizationWorkflowStatus(fragments: DocumentNode[]= [OrganizationWorkflowStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getOrganizationWorkflowStatus {
        getOrganizationWorkflowStatus  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getWorkflowDefLookup(input: GetWorkflowDefLookupInput, fragments: DocumentNode[]= [WorkflowDefLookupDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefLookup($input: GetWorkflowDefLookupInput!) {
        getWorkflowDefLookup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowDefUniverseUsers(fragments: DocumentNode[]= [UserProfilePreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefUniverseUsers {
        getWorkflowDefUniverseUsers  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getWorkflowDefUniverseBlabDefRelationsWithFlimDefs(input?: GetBlabDefRelationsWithFlimDefsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefUniverseBlabDefRelationsWithFlimDefs($input: GetBlabDefRelationsWithFlimDefsInput) {
        getWorkflowDefUniverseBlabDefRelationsWithFlimDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchWorkflowExecutionContextBlabItems(input: SearchExecutionContextBlabItemsInput, fragments: DocumentNode[]= [BlabItemPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchWorkflowExecutionContextBlabItems($input: SearchExecutionContextBlabItemsInput!) {
        searchWorkflowExecutionContextBlabItems (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowDefUniverseCallableWorkflowDefs(input: GetWorkflowDefUniverseCallableWorkflowDefsInput, fragments: DocumentNode[]= [WorkflowDefUniverseCallableWorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefUniverseCallableWorkflowDefs($input: GetWorkflowDefUniverseCallableWorkflowDefsInput!) {
        getWorkflowDefUniverseCallableWorkflowDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowDefUniverseBlabViews(input: GetWorkflowDefUniverseBlabViewsInput, fragments: DocumentNode[]= [WorkflowDefUniverseBlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefUniverseBlabViews($input: GetWorkflowDefUniverseBlabViewsInput!) {
        getWorkflowDefUniverseBlabViews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getDefaultBlabViewsForBlabDefs(input: GetDefaultBlabViewsForBlabDefsInput, fragments: DocumentNode[]= [WorkflowDefLookupBlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getDefaultBlabViewsForBlabDefs($input: GetDefaultBlabViewsForBlabDefsInput!) {
        getDefaultBlabViewsForBlabDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowDefWithLookup(input: GetWorkflowDefWithLookupInput, fragments: DocumentNode[]= [WorkflowDefWithLookupDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowDefWithLookup($input: GetWorkflowDefWithLookupInput!) {
        getWorkflowDefWithLookup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getGlobalWorkflowRuns(first: number, input?: GetGlobalWorkflowRunsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getGlobalWorkflowRuns($first: Int!, $input: GetGlobalWorkflowRunsInput, $after: String) {
        getGlobalWorkflowRuns (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function exportGlobalWorkflowRuns(first: number, input: GetGlobalWorkflowRunsInput, fragments: DocumentNode[]= [ExportGlobalWorkflowRunsResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query exportGlobalWorkflowRuns($first: Int!, $input: GetGlobalWorkflowRunsInput!) {
        exportGlobalWorkflowRuns (first: $first, input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input }
  }
}

export function searchWorkflowPreviewsForGlobalRunsFiltering(input?: SearchWorkflowPreviewsForGlobalRunsFilteringInput, fragments: DocumentNode[]= [WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchWorkflowPreviewsForGlobalRunsFiltering($input: SearchWorkflowPreviewsForGlobalRunsFilteringInput) {
        searchWorkflowPreviewsForGlobalRunsFiltering (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getGlobalWorkflowRunDetail(id: number, fragments: DocumentNode[]= [GlobalWorkflowRunDetailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getGlobalWorkflowRunDetail($id: Int!) {
        getGlobalWorkflowRunDetail (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getWorkflowHomeCurrentInfo(input?: GetWorkflowHomeCurrentInfoInput, fragments: DocumentNode[]= [WorkflowHomeCurrentInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowHomeCurrentInfo($input: GetWorkflowHomeCurrentInfoInput) {
        getWorkflowHomeCurrentInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowHomeTimeIntervalInfo(input: GetWorkflowHomeTimeIntervalInfoInput, fragments: DocumentNode[]= [WorkflowHomeTimeIntervalInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowHomeTimeIntervalInfo($input: GetWorkflowHomeTimeIntervalInfoInput!) {
        getWorkflowHomeTimeIntervalInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowRunBlabItem(id: number, fragments: DocumentNode[]= [BlabItemPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowRunBlabItem($id: Int!) {
        getWorkflowRunBlabItem (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getWorkflowRunsOfWorkflowDef(first: number, input?: GetWorkflowRunsOfWorkflowDefInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowRunsOfWorkflowDef($first: Int!, $input: GetWorkflowRunsOfWorkflowDefInput, $after: String) {
        getWorkflowRunsOfWorkflowDef (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function exportWorkflowRunsOfWorkflowDef(first: number, input: GetWorkflowRunsOfWorkflowDefInput, fragments: DocumentNode[]= [ExportWorkflowRunsOfWorkflowDefResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query exportWorkflowRunsOfWorkflowDef($first: Int!, $input: GetWorkflowRunsOfWorkflowDefInput!) {
        exportWorkflowRunsOfWorkflowDef (first: $first, input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input }
  }
}

export function getWorkflowRunDetail(id: number, fragments: DocumentNode[]= [WorkflowRunDetailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowRunDetail($id: Int!) {
        getWorkflowRunDetail (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getLastWorkflowWebhookPayload(input: GetLastWorkflowWebhookPayloadInput, fragments: DocumentNode[]= [WorkflowWebhookLastPayloadDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getLastWorkflowWebhookPayload($input: GetLastWorkflowWebhookPayloadInput!) {
        getLastWorkflowWebhookPayload (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkflowCenterWebhooks(input: GetWorkflowCenterWebhooksInput, fragments: DocumentNode[]= [WorkflowCenterWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkflowCenterWebhooks($input: GetWorkflowCenterWebhooksInput!) {
        getWorkflowCenterWebhooks (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserWorkflowCenterWorkspaceOverview(fragments: DocumentNode[]= [WorkflowCenterWorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserWorkflowCenterWorkspaceOverview {
        getActiveUserWorkflowCenterWorkspaceOverview  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function searchWorkflowPreviews(input?: SearchWorkflowPreviewsInput, fragments: DocumentNode[]= [WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchWorkflowPreviews($input: SearchWorkflowPreviewsInput) {
        searchWorkflowPreviews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getConfiguredWorkflowPreviewsByBlabDefId(input?: GetConfiguredWorkflowPreviewsByBlabDefIdInput, fragments: DocumentNode[]= [WorkflowCenterWorkflowPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getConfiguredWorkflowPreviewsByBlabDefId($input: GetConfiguredWorkflowPreviewsByBlabDefIdInput) {
        getConfiguredWorkflowPreviewsByBlabDefId (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceBySlug(wsSlug: string, fragments: DocumentNode[]= [WorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceBySlug($wsSlug: String!) {
        getWorkspaceBySlug (wsSlug: $wsSlug) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { wsSlug }
  }
}

export function getWorkspaceById(id: number, fragments: DocumentNode[]= [WorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceById($id: Int!) {
        getWorkspaceById (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { id }
  }
}

export function getWorkspaceActivityReplies(first: number, input?: GetWorkspaceActivityRepliesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceActivityReplies($first: Int!, $input: GetWorkspaceActivityRepliesInput, $after: String) {
        getWorkspaceActivityReplies (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getWorkspaceBlabDefs(first: number, input?: GetWorkspaceBlabDefsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceBlabDefs($first: Int!, $input: GetWorkspaceBlabDefsInput, $after: String) {
        getWorkspaceBlabDefs (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getWorkspaceMemberships(first: number, input?: GetWorkspaceMembershipsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceMemberships($first: Int!, $input: GetWorkspaceMembershipsInput, $after: String) {
        getWorkspaceMemberships (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function searchWorkspaceMembershipCandidates(input: SearchWorkspaceMembershipCandidatesInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query searchWorkspaceMembershipCandidates($input: SearchWorkspaceMembershipCandidatesInput!) {
        searchWorkspaceMembershipCandidates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceSettings(input: GetWorkspaceSettingsInput, fragments: DocumentNode[]= [WorkspaceSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceSettings($input: GetWorkspaceSettingsInput!) {
        getWorkspaceSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceShareSettings(input: GetWorkspaceShareSettingsInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceShareSettings($input: GetWorkspaceShareSettingsInput!) {
        getWorkspaceShareSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getSharedWorkspace(input: GetSharedWorkspaceInput, fragments: DocumentNode[]= [SharedWorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getSharedWorkspace($input: GetSharedWorkspaceInput!) {
        getSharedWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceTemplates(input: GetWorkspaceTemplatesInput, fragments: DocumentNode[]= [WorkspaceTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceTemplates($input: GetWorkspaceTemplatesInput!) {
        getWorkspaceTemplates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceTemplate(input: GetWorkspaceTemplateInput, fragments: DocumentNode[]= [WorkspaceTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceTemplate($input: GetWorkspaceTemplateInput!) {
        getWorkspaceTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getWorkspaceTemplatePublishInfo(input: GetWorkspaceTemplatePublishInfoInput, fragments: DocumentNode[]= [WorkspaceTemplatePublishInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getWorkspaceTemplatePublishInfo($input: GetWorkspaceTemplatePublishInfoInput!) {
        getWorkspaceTemplatePublishInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getActiveUserTeamUserProfiles(fragments: DocumentNode[]= [UserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getActiveUserTeamUserProfiles {
        getActiveUserTeamUserProfiles  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function getFlimDefs(blabDefId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getFlimDefs($blabDefId: Int!) {
        getFlimDefs (blabDefId: $blabDefId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabDefId }
  }
}

export function getFlimVals(blabItemId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getFlimVals($blabItemId: Int!) {
        getFlimVals (blabItemId: $blabItemId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabItemId }
  }
}

export function getChatActivities(first: number, input?: GetChatActivitiesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getChatActivities($first: Int!, $input: GetChatActivitiesInput, $after: String) {
        getChatActivities (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getDialog(input: GetDialogInput, fragments: DocumentNode[]= [ChatDialogDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getDialog($input: GetDialogInput!) {
        getDialog (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChatGroup(input: GetChatGroupInput, fragments: DocumentNode[]= [ChatGroupDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query getChatGroup($input: GetChatGroupInput!) {
        getChatGroup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getChatGroupUsers(first: number, input?: GetChatGroupUsersInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getChatGroupUsers($first: Int!, $input: GetChatGroupUsersInput, $after: String) {
        getChatGroupUsers (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getConversation(input: GetConversationInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getConversation($input: GetConversationInput!) {
        getConversation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getConversations(first: number, input?: GetConversationsInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getConversations($first: Int!, $input: GetConversationsInput, $after: String) {
        getConversations (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getFocusEntries(first: number, input?: GetFocusEntriesInput, after?: string, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getFocusEntries($first: Int!, $input: GetFocusEntriesInput, $after: String) {
        getFocusEntries (first: $first, input: $input, after: $after) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { first, input, after }
  }
}

export function getBlabDefWithIncomingAndOutgoingBlabDefs(input: GetAllIncomingAndOutgoingBlabDefsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getBlabDefWithIncomingAndOutgoingBlabDefs($input: GetAllIncomingAndOutgoingBlabDefsInput!) {
        getBlabDefWithIncomingAndOutgoingBlabDefs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function getCalculationScriptResultPreview(input?: GetCalculationScriptResultPreviewInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      query getCalculationScriptResultPreview($input: GetCalculationScriptResultPreviewInput) {
        getCalculationScriptResultPreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchBlabItemsForRelationFlimFilter(input: SearchBlabItemsForRelationFlimFilterInput, fragments: DocumentNode[]= [BlabItemSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchBlabItemsForRelationFlimFilter($input: SearchBlabItemsForRelationFlimFilterInput!) {
        searchBlabItemsForRelationFlimFilter (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchUsersForUserFlimFilter(input: SearchUsersForUserFlimFilterInput, fragments: DocumentNode[]= [UserSearchResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchUsersForUserFlimFilter($input: SearchUsersForUserFlimFilterInput!) {
        searchUsersForUserFlimFilter (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function searchUserFlimValUsers(input: SearchUserFlimValUsersInput, fragments: DocumentNode[]= [UserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      query searchUserFlimValUsers($input: SearchUserFlimValUsersInput!) {
        searchUserFlimValUsers (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

  