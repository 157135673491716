import { InjectionToken } from '@angular/core';
import { clientLanguagesDict } from '@t5s/client/i18n/legacy';
import { Language } from '@t5s/shared/gql';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { Observable } from 'rxjs';

type ClientI18nCommonDict = typeof clientLanguagesDict;

export interface I18n<T extends ClientI18nCommonDict = ClientI18nCommonDict> extends SharedI18n {
  languagesDict?: T;
  i18n: SharedI18n;
}

export class I18nObservable<T extends ClientI18nCommonDict = ClientI18nCommonDict> extends Observable<I18n<T>> {}

export const RUNTIME_LANGUAGE = new InjectionToken<Observable<Language>>('Current runtime user language');
export const LANGUAGES_DICT = new InjectionToken<Observable<ClientI18nCommonDict>>(
  'Current application languages dict',
);

export const RUNTIME_I18N = new InjectionToken<Observable<I18n>>('Current i18n user configuration');
