import { Inject, Injectable } from '@angular/core';
import { LiveSubscriptionContextObservable } from '@t5s/client/provider-token/live-subscription-context';
import {
  LiveSubscriptionContext,
  LiveSubscriptionContextMissingException,
} from '@t5s/client/value-object/live-subscription-context';
import { Middleware, SubscriptionClient } from 'subscriptions-transport-ws';
import { GRAPHQL_ENDPOINT } from '../injection-tokens';
import { createJitteredSubscriptionClient } from './create-jittered-subscription-client.util';

@Injectable()
export class ApolloSubscriptionService {
  constructor(@Inject(GRAPHQL_ENDPOINT) gqlUri: string, context$: LiveSubscriptionContextObservable) {
    const wsUri = this.getWsUriFromGqlUri(gqlUri);

    // eslint-disable-next-line no-console
    console.log(`ApolloSubscriptionService: using websocket endpoint ${wsUri}`);

    this.client = createJitteredSubscriptionClient({ uri: wsUri });
    this.client.use([this.contextMiddleware]);

    context$.subscribe((context) => {
      this.context = context;
    });
  }

  readonly client: SubscriptionClient;
  private context: LiveSubscriptionContext | undefined = undefined;

  private readonly contextMiddleware: Middleware = {
    applyMiddleware: (options, next) => {
      // currently, all live subscriptions are authenticated. Thereby, without proper context the subscription will fail.
      if (!this.context) {
        throw new LiveSubscriptionContextMissingException(
          'Unable to set live subscription context. Ensure that subscriptions are only initiated once context is available.',
        );
      }

      options.context = this.context;

      return next();
    },
  };

  private getWsUriFromGqlUri(gqlUri: string): string {
    const uri = gqlUri.replace('http', 'ws').replace('4200', '3000').replace('4201', '3000');

    // in PRD use the dedicated websocket endpoint
    if (gqlUri.includes('tapeapp.com')) {
      return uri.replace('/graphql', '/graphql-ws');
    }

    return uri;
  }
}
