import { NgModule } from '@angular/core';
import { LanguageService } from '@t5s/client/i18n/legacy';
import { WebClientLanguageService } from './web-client-language.service';

/** @deprecated */
@NgModule({
  providers: [
    {
      provide: LanguageService,
      useClass: WebClientLanguageService,
    },
  ],
})
export class WebClientI18nModule {}
