import gql from 'graphql-tag';
import { GetBlabViewsPaginatedResultDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabViewPreviewDtoFragment } from './blab-view-preview.fragment';

export const GetBlabViewsPaginatedResultDtoFragment = gql`
  fragment GetBlabViewsPaginatedResultDto on GetBlabViewsPaginatedResultDto {
    ...GetBlabViewsPaginatedResultDtoNoNesting
    blabViews {
      ...BlabViewPreviewDto
    }
  }
  ${GetBlabViewsPaginatedResultDtoNoNestingFragment}
  ${BlabViewPreviewDtoFragment}
`;
